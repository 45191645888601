import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { Link, useNavigate } from 'react-router-dom';
import { addWebsiteEnv, get_website_data,updateWebsiteEnv } from '../../../../apis/generalSetting/WebsiteEnv';
const AddWebsiteEnv = () => {
    const [id,setID]=useState('')
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch
    } = useForm();
    const navigate = useNavigate();
    const onSubmit = async (data) => {
        if(!id){
            let result = await addWebsiteEnv(data);
            if (result.status) {
                return navigate('/admin/administrator-setting/websiteEnv/all');
            }
        }else{
            console.log("****",data);
            
            let result = await updateWebsiteEnv(id,data);
            if (result.status) {
                return navigate('/admin/administrator-setting/websiteEnv/all');
            } 
        }
       
    }
    const today = new Date().toISOString().split('T')[0];
    const s_d_term1 = watch('s_d_term1');
    const e_d_term1 = watch('e_d_term1');
    const s_d_term2 = watch('s_d_term2');
    const e_d_term2 = watch('e_d_term2');
    const s_d_term3 = watch('s_d_term3');
    const e_d_term3 = watch('e_d_term3');
    const s_d_term4 = watch('s_d_term4');
    const e_d_term4 = watch('e_d_term4');

    useEffect(() => {
        if (s_d_term1 && e_d_term1) {
            const startDate = new Date(s_d_term1);
            const endDate = new Date(e_d_term1);
            const diffTime = Math.abs(endDate - startDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            const diffWeeks = Math.floor(diffDays / 7);
            setValue('m_w_term1', diffWeeks.toString());
        }
        if (s_d_term2 && e_d_term2) {
            const startDate = new Date(s_d_term2);
            const endDate = new Date(e_d_term2);
            const diffTime = Math.abs(endDate - startDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            const diffWeeks = Math.floor(diffDays / 7);
            setValue('m_w_term2', diffWeeks.toString());
        }
        if (s_d_term3 && e_d_term3) {
            const startDate = new Date(s_d_term3);
            const endDate = new Date(e_d_term3);
            const diffTime = Math.abs(endDate - startDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            const diffWeeks = Math.floor(diffDays / 7);
            setValue('m_w_term3', diffWeeks.toString());
        }
        if (s_d_term4 && e_d_term4) {
            const startDate = new Date(s_d_term4);
            const endDate = new Date(e_d_term4);
            const diffTime = Math.abs(endDate - startDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            const diffWeeks = Math.floor(diffDays / 7);
            setValue('m_w_term4', diffWeeks.toString());
        }
    }, [s_d_term1, e_d_term1,s_d_term2, e_d_term2,s_d_term3, e_d_term3,s_d_term4, e_d_term4, setValue]);

    useEffect(() => {
        async function get_data() {
            const envView = await get_website_data();
            const data=(JSON.parse(envView?.data[0]?.env_data));
            console.log('data',data);
            setValue('s_d_term1', data?.s_d_term1);
            setValue('s_d_term2', data?.s_d_term2);
            setValue('s_d_term3', data?.s_d_term3);
            setValue('s_d_term4', data?.s_d_term4);
            setValue('e_d_term1', data?.e_d_term1);
            setValue('e_d_term2', data?.e_d_term2);
            setValue('e_d_term3', data?.e_d_term3);
            setValue('e_d_term4', data?.e_d_term4);
            setValue('m_w_term1', data?.m_w_term1);
            setValue('m_w_term2', data?.m_w_term2);
            setValue('m_w_term3', data?.m_w_term3);
            setValue('m_w_term4', data?.m_w_term4);
            setValue('c_term', data?.c_term);
            setValue('c_year', data?.c_year);
            setValue('ut_s_week', data?.ut_s_week);
            setValue('e_d_period', data?.e_d_period);
            setValue('s_date', data?.s_date);
            setValue('w_date', data?.w_date);
            setValue('m_date', data?.m_date);
            setValue('g_date', data?.g_date);
            setValue('r_date', data?.r_date);
            setValue('t_period', data?.t_period);
            setValue('r_period', data?.r_period);
            setValue('ot_period', data?.ot_period);
            setValue('h_period', data?.h_period);
            setValue('ct_branches', data?.ct_branches);
            setID(envView?.data[0]?.id)
        }
        get_data();
    }, []);

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Website Env</h4>
                    <Link className='edit-btn' to={`/admin/administrator-setting/websiteEnv/all`} onClick={(e) => {
    if (window.confirm("Are you sure you want to leave this page?")) {
    } else {
      e.preventDefault(); 
    }
  }}><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
                </div>
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                            <div className="form-group">
                                    <labell>Start Date Term(1)</labell>
                                    <input type="date" 
                                    className="form-control"
                                    pattern="dd-mm-yyyy"
                                        placeholder='Enter Start Date'
                                        min={today}
                                        {...register('s_d_term1', {
                                            required: "Start date is required!",
                                              validate: (value) => {
                                          const selectedDate = new Date(value);
                                          const todayDate = new Date();
                                          return selectedDate > todayDate || "Please select a future date.";
                                      }
                                             })}
                                    />
                                </div>
                                {errors.s_d_term1 && errors.s_d_term1.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                            <div className="form-group">
                                    <labell>End Date Term(1)</labell>
                                    <input type="date" 
                                    className="form-control"
                                    pattern="dd-mm-yyyy"
                                        placeholder='Enter End Date'
                                        min={today}
                                        {...register('e_d_term1', {
                                            required: "End date is required!",
                                              validate: (value) => {
                                          const selectedDate = new Date(value);
                                          const todayDate = new Date();
                                          return selectedDate > todayDate || "Please select a future date.";
                                      }
                                             })}
                                    />
                                </div>
                                {errors.s_d_term1 && errors.s_d_term1.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <labell>Start Date Term(2)</labell>
                                    <input type="date" className="form-control"
                                        placeholder='Enter Start Date'
                                         pattern="dd-mm-yyyy"
                                         min={today}
                                        {...register('s_d_term2', {
                                            required: "Start date is required!",
                                            validate: (value) => {
                                        const selectedDate = new Date(value);
                                        const todayDate = new Date();
                                        return selectedDate > todayDate || "Please select a future date.";
                                    }
                                            })}
                                    />
                                </div>
                                {errors.s_d_term2 && errors.s_d_term2.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <labell>End Date Term(2)</labell>
                                    <input type="date" className="form-control"
                                        placeholder='Enter End Date'
                                         pattern="dd-mm-yyyy"
                                         min={today}
                                        {...register('e_d_term2', {
                                            required: "End date is required!",
                                            validate: (value) => {
                                        const selectedDate = new Date(value);
                                        const todayDate = new Date();
                                        return selectedDate > todayDate || "Please select a future date.";
                                    }
                                            })}
                                    />
                                </div>
                                {errors.s_d_term2 && errors.s_d_term2.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <labell>Start Date Term(3)</labell>
                                    <input type="date" className="form-control"
                                        placeholder='Enter Start Date'
                                        pattern="dd-mm-yyyy"
                                        min={today}
                                        {...register('s_d_term3', { 
                                             required: "Start date is required!",
                                              validate: (value) => {
                                          const selectedDate = new Date(value);
                                          const todayDate = new Date();
                                          return selectedDate > todayDate || "Please select a future date.";
                                      }
                                        })}
                                    />
                                </div>
                                {errors.s_d_term3 && errors.s_d_term3.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <labell>End Date Term(3)</labell>
                                    <input type="date" className="form-control"
                                        placeholder='Enter End Date'
                                        pattern="dd-mm-yyyy"
                                        min={today}
                                        {...register('e_d_term3', { 
                                             required: "End date is required!",
                                              validate: (value) => {
                                          const selectedDate = new Date(value);
                                          const todayDate = new Date();
                                          return selectedDate > todayDate || "Please select a future date.";
                                      }
                                        })}
                                    />
                                </div>
                                {errors.s_d_term3 && errors.s_d_term3.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <labell>Start Date Term(4)</labell>
                                    <input type="date" className="form-control"
                                        placeholder='Enter Start Date'
                                        pattern="dd-mm-yyyy"
                                        min={today}
                                        {...register('s_d_term4', { 
                                            required: "Start date is required!",
                                              validate: (value) => {
                                          const selectedDate = new Date(value);
                                          const todayDate = new Date();
                                          return selectedDate > todayDate || "Please select a future date.";
                                      }
                                         })}
                                    />
                                </div>
                                {errors.s_d_term4 && errors.s_d_term4.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            
                            <div className="col-md-6">
                                <div className="form-group">
                                    <labell>End Date Term(4)</labell>
                                    <input type="date" className="form-control"
                                        placeholder='Enter End Date'
                                        pattern="dd-mm-yyyy"
                                        min={today}
                                        {...register('e_d_term4', { 
                                            required: "End date is required!",
                                              validate: (value) => {
                                          const selectedDate = new Date(value);
                                          const todayDate = new Date();
                                          return selectedDate > todayDate || "Please select a future date.";
                                      }
                                         })}
                                    />
                                </div>
                                {errors.s_d_term4 && errors.s_d_term4.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <labell>Max Week Term(1)</labell>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Max Week'
                                        {...register('m_w_term1', { required: true })}
                                    />
                                </div>
                                {errors.m_w_term1 && errors.m_w_term1.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <labell>Max Week Term(2)</labell>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Max Week'
                                        {...register('m_w_term2', { required: true })}
                                    />
                                </div>
                                {errors.m_w_term2 && errors.m_w_term2.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <labell>Max Week Term(3)</labell>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Max Week'
                                        {...register('m_w_term3', { required: true })}
                                    />
                                </div>
                                {errors.m_w_term3 && errors.m_w_term3.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <labell>Max Week Term(4)</labell>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Max Week'
                                        {...register('m_w_term4', { required: true })}
                                    />
                                </div>
                                {errors.m_w_term4 && errors.m_w_term4.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <labell>Current Term</labell>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Current Term'
                                        {...register('c_term', { required: true })}
                                    />
                                </div>
                                {errors.c_term && errors.c_term.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <labell>Current Year</labell>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Current Year'
                                        {...register('c_year', { required: true })}
                                    />
                                </div>
                                {errors.c_year && errors.c_year.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <labell>Unit Test Start Week</labell>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Test Start Week '
                                        {...register('ut_s_week', { required: true })}
                                    />
                                </div>
                                {errors.ut_s_week && errors.ut_s_week.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Default Period 4 PA</label>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Default Period'
                                        {...register('e_d_period')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <labell>Sdate E</labell>
                                    <input type="date" className="form-control"
                                        placeholder='Enter Sdate E'
                                        pattern="dd-mm-yyyy"
                                        min={today}
                                        {...register('s_date', { 
                                            required: "Sdate is required!",
                                            validate: (value) => {
                                        const selectedDate = new Date(value);
                                        const todayDate = new Date();
                                        return selectedDate > todayDate || "Please select a future date.";
                                    } 
                                        })}
                                    />
                                </div>
                                {errors.s_date && errors.s_date.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <labell>Sdate W</labell>
                                    <input type="date" className="form-control"
                                        placeholder='Enter Sdate W'
                                        pattern="dd-mm-yyyy"
                                        min={today}
                                        {...register('w_date', { 
                                            required: "Sdate is required!",
                                            validate: (value) => {
                                        const selectedDate = new Date(value);
                                        const todayDate = new Date();
                                        return selectedDate > todayDate || "Please select a future date.";
                                    } 
                                        })}
                                    />
                                </div>
                                {errors.w_date && errors.w_date.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <labell>Sdate M</labell>
                                    <input type="date" className="form-control"
                                        placeholder='Enter Sdate M'
                                        pattern="dd-mm-yyyy"
                                        min={today}
                                        {...register('m_date', { 
                                            required: "Sdate is required!",
                                            validate: (value) => {
                                        const selectedDate = new Date(value);
                                        const todayDate = new Date();
                                        return selectedDate > todayDate || "Please select a future date.";
                                    } 
                                         })}
                                    />
                                </div>
                                {errors.m_date && errors.m_date.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <labell>Sdate G</labell>
                                    <input type="date" className="form-control"
                                        placeholder='Enter Sdate G'
                                        pattern="dd-mm-yyyy"
                                        min={today}
                                        {...register('g_date', { 
                                            required: "Sdate is required!",
                                            validate: (value) => {
                                        const selectedDate = new Date(value);
                                        const todayDate = new Date();
                                        return selectedDate > todayDate || "Please select a future date.";
                                    } 
                                         })}
                                    />
                                </div>
                                {errors.g_date && errors.g_date.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <labell>Review Date</labell>
                                    <input type="date" className="form-control"
                                        placeholder='Enter Review Date'
                                        pattern="dd-mm-yyyy"
                                        min={today}
                                        {...register('r_date', { 
                                            required: "Sdate is required!",
                                            validate: (value) => {
                                        const selectedDate = new Date(value);
                                        const todayDate = new Date();
                                        return selectedDate > todayDate || "Please select a future date.";
                                    } 
                                         })}
                                    />
                                </div>
                                {errors.r_date && errors.r_date.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <labell>Test Period</labell>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Test Period'
                                        {...register('t_period', { required: true })}
                                    />
                                </div>
                                {errors.t_period && errors.t_period.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <labell>Test Review Period</labell>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Test Review Period'
                                        {...register('r_period', { required: true })}
                                    />
                                </div>
                                {errors.r_period && errors.r_period.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <labell>Off Test Review Period</labell>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Off Test Review Period'
                                        {...register('ot_period', { required: true })}
                                    />
                                </div>
                                {errors.ot_period && errors.ot_period.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <labell>Holi Off Test Review Period</labell>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Holi Off Test Review Period'
                                        {...register('h_period', { required: true })}
                                    />
                                </div>
                                {errors.h_period && errors.h_period.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Classtest Branches</label>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Class Test Branches'
                                        {...register('ct_branches')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type='submit' className="btn btn-primary">Save</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    )
}
export default AddWebsiteEnv;

