// import React, { useEffect, useState } from 'react';
// import { get_question } from '../../../../apis/testManagement/Question.jsx';
// import { subject_all } from '../../../../apis/ClassManagement/Subjects.jsx';
// import AddLecture from '../Lecture/Add.jsx';
// import { get_text_books } from '../../../../apis/TextBook.jsx';
// const EditLecture = ({ setLecture, lecture, setLectureData, lectureData }) => {
//     const [subjects, setLectureject] = useState([]);
//     const [question, setQuestion] = useState([]);
//     const [textBook, setTextBook] = useState([]);
//     const handleLectureDataChange = (index, fieldname, value) => {
//         if (fieldname == 'subject_id') {
//             localStorage.setItem('courseSubjectID', value)
//         }
//         const updatedData = [...lectureData];
//         updatedData[index] = { ...updatedData[index], [fieldname]: value };
//         setLectureData(updatedData);
//     };
//     //lecture handle change for image
//     const handleLectureImgChange = (e, index) => {
//         const { name, files } = e.target;
//         const list = [...lectureData];
//         list[index][name] = files[0];
//         setLectureData(list);
//     }

//     useEffect(() => {
//         const fetchData = async () => {
//             const questionList = await get_question();
//             const result = await subject_all();
//             const textBookList = await get_text_books();
//             setQuestion(questionList?.data);
//             setLectureject(result?.data);
//             setTextBook(textBookList?.data);
//         };
//         fetchData();
//     }, []);
//     return (
//         <>

//             {
//                 lectureData && lectureData.length > 0 ?

//                     lectureData && lectureData?.map((ele, index, data) => {
//                         return (
//                             <>
//                                 <div className="tab-pane fade show active" id="pills-home" role="tabpanel1" aria-labelledby="pills-home-tab">
//                                     <div className="modal-body">
//                                         <div className="row">
//                                             <div className="col-md-6" key={index}>
//                                                 <div className="form-group">
//                                                     <label>Upload Document {index + 1}</label>
//                                                     <input type="file" className="form-control"
//                                                         name='u_document'
//                                                         onChange={(e) => handleLectureImgChange(e, index)}
//                                                         accept=".pdf" />

//                                                 </div>
//                                             </div>
//                                             <div className="col-md-6">
//                                                 <div className="form-group">
//                                                     <label>Embedded Link</label>
//                                                     <input type="url" className="form-control"
//                                                         placeholder='Enter Link'
//                                                         name='link'
//                                                         value={ele?.link}
//                                                         onChange={(e) => handleLectureDataChange(index, 'link', e.target.value)}
//                                                     />
//                                                 </div>
//                                             </div>
//                                             <div className="col-md-6">
//                                                 <div className="form-group">
//                                                     <label>Upload Image</label>
//                                                     <input type="file" className="form-control"
//                                                         name='u_image'
//                                                         onChange={(e) => handleLectureImgChange(e, index)} />

//                                                 </div>
//                                             </div>
//                                             <div className="col-md-6">
//                                                 <div className="form-group">
//                                                     <label>Upload Audio</label>
//                                                     <input type="file" className="form-control"
//                                                         name='u_audio'
//                                                         accept='audio/*'
//                                                         onChange={(e) => handleLectureImgChange(e, index)} />

//                                                 </div>
//                                             </div>
//                                             <div className="col-md-6" >
//                                                 <div className="form-group">
//                                                     <label>Unit </label>
//                                                     <select className="form-control form-select"
//                                                         value={ele?.unit} name='unit'
//                                                         onChange={(e) => handleLectureDataChange(index, 'unit', e.target.value)} >
//                                                         <option value="">Choose Unit</option>
//                                                         <option value="1">1</option>
//                                                         <option value="2">2</option>
//                                                         <option value="3">3</option>
//                                                         <option value="4">4</option>
//                                                         <option value="5">5</option>
//                                                         <option value="6">6</option>
//                                                         <option value="7">7</option>
//                                                         <option value="8">8</option>
//                                                         <option value="9">9</option>
//                                                         <option value="10">10</option>
//                                                         <option value="11">11</option>
//                                                         <option value="12">12</option>
//                                                         <option value="13">13</option>
//                                                         <option value="14">14</option>
//                                                         <option value="15">15</option>
//                                                     </select>
//                                                     {/* <input type="text" className="form-control"
//                                                         placeholder='Enter Unit'
//                                                         value={ele?.unit}
//                                                         name='unit'
//                                                         onChange={(e) => handleLectureDataChange(index, 'unit', e.target.value)}
//                                                     /> */}
//                                                 </div>
//                                             </div>
//                                             {/* <div className="col-md-6">
//                                                 <div className="form-group">
//                                                     <label>Week</label>
//                                                     <input type="text" className="form-control"
//                                                         placeholder='Enter Week'
//                                                         value={ele?.week}
//                                                         name='week'
//                                                         onChange={(e) => handleLectureDataChange(index, 'week', e.target.value)}
//                                                     />
//                                                 </div>
//                                             </div> */}
//                                             <div className="col-md-6" >
//                                                 <div className="form-group">
//                                                     <label>Questionaire</label>
//                                                     <select className="form-control form-select"
//                                                         value={ele?.q_id}
//                                                         name='q_id'
//                                                         onChange={(e) => handleLectureDataChange(index, 'q_id', e.target.value)} >
//                                                         <option value="">Choose Question</option>
//                                                         {
//                                                             question && question?.map((elem) => {
//                                                                 return (
//                                                                     <option value={elem?.id}>{elem?.question_name}</option>
//                                                                 )
//                                                             })
//                                                         }
//                                                     </select>
//                                                 </div>
//                                             </div>
//                                             <div className="col-md-6">
//                                                 <div className="form-group">
//                                                     <label>Subject</label>
//                                                     <select className="form-control form-select"
//                                                         name='subject_id'
//                                                         value={ele?.subject_id}
//                                                         onChange={(e) => handleLectureDataChange(index, 'subject_id', e.target.value)}>
//                                                         <option value="">Choose Subject</option>
//                                                         {
//                                                             subjects && subjects?.map((elem) => {
//                                                                 return <option value={elem?.id}>{elem?.subject}</option>
//                                                             })
//                                                         }
//                                                     </select>
//                                                 </div>
//                                             </div>
//                                             <div className="col-md-6">
//                                                 <div className="form-group">
//                                                     <label>Text Book</label>
//                                                     <select className="form-control form-select"
//                                                         name='text_book_id'
//                                                         value={ele.text_book_id}
//                                                         onChange={(e) => handleLectureDataChange(index, 'text_book_id', e.target.value)}>
//                                                         <option value="">Choose text book</option>
//                                                         {
//                                                             textBook && textBook?.map((elem) => {
//                                                                 return <option value={elem?.id}>{elem?.name}</option>
//                                                             })
//                                                         }
//                                                     </select>
//                                                 </div>
//                                             </div>
//                                             <div className="col-md-12">
//                                                 <div className="form-group">
//                                                     <label>Description</label>
//                                                     <textarea type="text" className="form-control"
//                                                         placeholder='Enter Description'
//                                                         value={ele?.description}
//                                                         name='description'
//                                                         onChange={(e) => handleLectureDataChange(index, 'description', e.target.value)}
//                                                     />
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     {/* <div className="col-md-2">
//                                     <div className='add_btn'>
//                                         {
//                                             lecture.length !== 1 && (
//                                                 <button className='btn-warning' onClick={() => handleLectureRemoveClick(i)}>Remove</button>
//                                             )
//                                         }
//                                         {
//                                             lecture.length - 1 === i && (
//                                                 <button className='btn-success' onClick={() => handleLectureAddClick()}>Add</button>
//                                             )
//                                         }
//                                     </div>
//                                 </div> */}
//                                     <hr />
//                                 </div>
//                             </>
//                         )
//                     })
//                     :
//                     <AddLecture setLecture={setLecture} lecture={lecture} />
//             }

//         </>
//     )
// }
// export default EditLecture;


import React, { useEffect, useState } from 'react';
import { get_question_by_type } from '../../../../apis/testManagement/Question.jsx';
import { subject_all } from '../../../../apis/ClassManagement/Subjects.jsx';
import { get_text_books, get_text_books_subject_id } from '../../../../apis/TextBook.jsx';

const EditLecture = ({subjectIds, subjectId, setLecture, lecture , selectedSubjectId , singleTypeFormdata,gradeId}) => {
  const [forms, setForms] = useState([]);
  const [question, setQuestion] = useState([]);
  const [textBook, setTextBook] = useState([]);
  const [existingSubject, setExistingSubject] = useState({});

  useEffect(() => {
  
    if((selectedSubjectId) && singleTypeFormdata ){
        console.log("singleTypeFormdata", singleTypeFormdata[`subject_id_${selectedSubjectId}`])
            setForms(singleTypeFormdata[`subject_id_${selectedSubjectId}`]);
  
      }
   
  }, [selectedSubjectId]);

console.log("selectedSubjectId---->", selectedSubjectId, forms)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          questionnaire_type: 'Course',
          grade_id: gradeId,
      };
        const questionList = await get_question_by_type(payload);
       // const textBookList = await get_text_books();
        const textBookList = await get_text_books_subject_id(subjectIds);
        setQuestion(questionList?.data || []);
        setTextBook(textBookList?.data || []);

        if (subjectId) {
          const subjectData = await subject_all(subjectId);
          setExistingSubject(subjectData?.data || {});
          const existingForms = subjectData?.data?.units?.map((unit) => ({
            unit_id: unit.unit_id,
            week: unit.week,
            q_id: unit.q_id,
            u_image: unit.u_image,
            u_audio: unit.u_audio,
            description: unit.description,
            u_document: unit.u_document,
            link: unit.link,
            text_book_id: unit.text_book_id
          }));
         
          setForms(existingForms);
          setLecture(existingForms); // Set existing forms to the parent component
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [subjectId, setLecture,subjectIds]);
  const handleLectureInputChange = (e, i) => {
    const { name, value } = e.target;
    const updatedForms = [...forms];
    updatedForms[i][name] = value;
    setForms(updatedForms);
    setLecture(updatedForms);
  };

  const handleLectureImgChange = (e, index) => {
    const { name, files } = e.target;
    const updatedForms = [...forms];
    updatedForms[index][name] = files[0] || null; // Handle empty file input
    setForms(updatedForms);
    setLecture(updatedForms);
  };

  return (
    <div className="accordion" id="accordionExample">
      {forms?.map((form, i) => (
        <div key={i} className="accordion-item">
          <h2 className="accordion-header" id={`heading-${i}`}>
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse-${i}`}
              aria-expanded={i === 0 ? true : false}
              aria-controls={`collapse-${i}`}
            >
              Unit {i + 1}
            </button>
          </h2>
          <div
            id={`collapse-${i}`}
            className={`accordion-collapse collapse ${i === 0 ? 'show' : ''}`}
            aria-labelledby={`heading-${i}`}
            data-bs-parent="#accordionExample"
          >
            <div className="modal-body">
              <div className="row">
                {/* Hidden unit_id field */}
                <input type="hidden" name="unit_id" value={form.unit_id || ""} />
                {/* Document Upload */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Upload Document {i + 1}</label>
                    <input
                      type="file"
                      className="form-control"
                      name="u_document"
                      onChange={(e) => handleLectureImgChange(e, i)}
                      accept=".pdf"
                    />
                  </div>
                </div>
                {/* Embedded Link */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Embedded Video Link</label>
                    <input
                      type="url"
                      className="form-control"
                      placeholder="Enter Link"
                      value={form.link || ""}
                      name="link"
                      onChange={(e) => handleLectureInputChange(e, i)}
                    />
                  </div>
                </div>
                {/* Image Upload */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Upload Image</label>
                    <input
                      type="file"
                      className="form-control"
                      name="u_image"
                      onChange={(e) => handleLectureImgChange(e, i)}
                    />
                  </div>
                </div>
                {/* Audio Upload */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Upload Audio</label>
                    <input
                      type="file"
                      className="form-control"
                      name="u_audio"
                      onChange={(e) => handleLectureImgChange(e, i)}
                      accept="audio/*"
                    />
                  </div>
                </div>
                {/* Questionnaire */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Questionnaire</label>
                    <select
                      className="form-control form-select"
                      value={form.q_id || ""}
                      name="q_id"
                      onChange={(e) => handleLectureInputChange(e, i)}
                    >
                      <option value="">Choose Question</option>
                      {question?.map((elem) => (
                        <option key={elem?.id} value={elem?.id}>
                          {elem?.question_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* Text Book */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Text Book</label>
                    <select
                      className="form-control form-select"
                      name="text_book_id"
                      value={form.text_book_id || ""}
                      onChange={(e) => handleLectureInputChange(e, i)}
                    >
                      <option value="">Choose text book</option>
                      {textBook?.map((elem) => (
                        <option key={elem?.id} value={elem?.id}>
                          {elem?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* Description */}
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      className="form-control"
                      placeholder="Enter Description"
                      value={form.description || ""}
                      name="description"
                      onChange={(e) => handleLectureInputChange(e, i)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      ))}
    </div>
  );
};

export default EditLecture;


