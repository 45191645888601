import React, { useRef } from "react";
import { Form, Button } from "semantic-ui-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CustomValidations } from "./customValidation.jsx";
import {
  add_question,
  get_add_question,
  question_update,
} from "../../../../apis/testManagement/Question.jsx";
import {
  get_SubCategory_by_id,
  get_Category,
} from "../../../../apis/Catergory";
// import DataLoader from '../../../../Utils/DataLoader';
import CustomTable from "../../CustomTable";
export const columns = [
  {
    name: "No.",
    selector: "no",
    sortable: true,
  },
  {
    name: "Test Type",
    selector: "test_type",
    sortable: true,
  },
  {
    name: "Questionnaire",
    selector: "question",
    sortable: true,
  },
  {
    name: "Action",
    selector: "action",
    sortable: true,
  },
];
const QuestionaireAdd = () => {
  const paramsData = useParams();
  const inputfile = useRef(null);
  const [grade, setGrade] = useState([]);
  const [show, setShow] = useState(false);
  const [category, setCategory] = useState([]);
  const [Subcategory, setSubCategory] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showQuestionList, setShowQuestionList] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputList, setInputList] = useState({
    test_type: "",
    question: "",
    answer: "",
    image: "",
    upload_data: "",
    passage: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    option5: "",
    category_id: "",
    sub_category_id: "",
    unique_id: "",
  });
  const [formVal, setFormVal] = useState({
    test_type: '',
    category_id: '',
    sub_category_id: '',
    question: '',
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    option5: '',
    answer: ''
});
  const [questionId, setQuestionID] = useState("");
  const questionData = JSON.parse(localStorage.getItem("questionData"));

useEffect(()=>{
    if(show){
        setFormVal({answer:''})
    }
},[show])
  const onSubmit = async (e) => {
    if (isSubmitting) return
    setIsSubmitting(true);
    // Collect the data you want to validate
    const dataToValidate = {
        test_type: inputList.test_type,
        category_id: inputList.category_id,
        sub_category_id: inputList.sub_category_id,
        question: inputList.question,
        option1: inputList.option1,
        option2: inputList.option2,
        option3: inputList.option3,
        option4: inputList.option4,
        option5: inputList.option5,
        answer: inputList.answer,
    };

    // Check validation
    const validationErrors = CustomValidations(1, dataToValidate,show);

    if (Object.keys(validationErrors).length > 0) {
        setFormVal(validationErrors);
         setIsSubmitting(false);
        return;
    }

    // If validation passes, continue with form submission
    const formData = new FormData();
    formData.append("questionnaire_type", questionData.questionnaire_type);
    formData.append("time", questionData.time);
    formData.append("question_name", questionData.question_name);
    formData.append("term_id", paramsData.term_id);
    formData.append("subject_id", paramsData.subject_id);
    formData.append("grade_id", paramsData.grade_id);
    formData.append("course_type_id", paramsData.course_type_id);
    formData.append("category_id", inputList.category_id);
    formData.append("sub_category_id", inputList.sub_category_id);
    formData.append("test_type", inputList.test_type);
    formData.append("question", inputList.question);
    formData.append("answer", inputList.answer);
    formData.append("image", inputList.image);
    formData.append("upload_data", inputList.upload_data);
    formData.append("passage", inputList.passage);
    formData.append("option1", inputList.option1);
    formData.append("option2", inputList.option2);
    formData.append("option3", inputList.option3);
    formData.append("option4", inputList.option4);
    formData.append("option5", inputList.option5);

    let result;
    if (questionId) {
        formData.append("unique_id", inputList.unique_id);
        console.log('hit',formData);
        result = await question_update(questionId, formData);
        if (result.status) {        
            setShowForm(false);
            get_data();
        }
    } else {
        formData.append("unique_id", Date.now());
        result = await add_question(formData);
        if (result.status) {
            setShowForm(false);
            setShowQuestionList(true);
            get_data();
        }
    }

    // Reset form fields if submission was successful
    if (result && result.status) {
        document.getElementById("myform").reset();
        setInputList({
            test_type: "",
            question: "",
            answer: "",
            image: "",
            upload_data: "",
            passage: "",
            option1: "",
            option2: "",
            option3: "",
            option4: "",
            option5: "",
            category_id: "",
        });
    }
    setIsSubmitting(false);
};

//   const checkvalidation = (data) => {
//     const currentStep = step;
//     const validationErrors = CustomValidations(currentStep, data);
//     if (Object.values(validationErrors).every((error) => !error)) {
//         console.log('working');
//     } else {
//         setFormVal(validationErrors);
//     }
// }


  useEffect(() => {
    const fetchData = async () => {
      const category = await get_Category();
      setCategory(category?.data);
    };
    get_data();
    fetchData();
  }, []);

  // handle change
  const handleInputChange = (e) => {
    console.log('dfd',e.target.value)
    const { name, value } = e.target;
    const list = { ...inputList };
    list[name] = value;
    setInputList(list);
    setFormVal({
        ...formVal,
        [name]: '',
    })
  };

  
  // handle change for image
  const handleImgChange = (e, index) => {
    const { name, files } = e.target;
    const list = { ...inputList };
    list[name] = files[0];
    setInputList(list);
  };
  const handleHideShow = (e) => {
    setShow(e == "subjective");
  };
  const [showResults, setShowResults] = useState(false);

  async function get_data() {
    const form = new FormData();
    // form.append("course_type_id", `${paramsData.course_type_id}`);
    form.append("questionnaire_type", `${questionData.questionnaire_type}`);
    form.append("subject_id", `${paramsData.subject_id}`);
    form.append("grade_id", `${paramsData.grade_id}`);

    const results = await get_add_question(form);
    const result =
      results?.data?.question_data.length > 0
        ? JSON.parse(results?.data?.question_data)
        : [];
    // const result = JSON.parse(results?.data[0]?.question_data);
    // setShowQuestionList(true)

    const question_id = results?.data?.id;
    const arrayData = result?.map((elem, index) => {
      const {
        unique_id,
        test_type,
        question,
        answer,
        passage,
        option1,
        option2,
        option3,
        option4,
        option5,
        category_id,
        sub_category_id,
      } = elem;
      console.log("category_id", typeof category_id);
      //   console.log('sub_category_id',sub_category_id);
      return {
        no: `#${index + 1}`,
        test_type: test_type,
        question: question,
        unique_id: unique_id,
        answer: answer,
        passage: passage,
        action: (
          <>
            <Link
              className="edit-btn"
              onClick={() => (
                scrollToTop(),
                setInputList({
                  unique_id: unique_id,
                  test_type: test_type,
                  question: question,
                  answer: answer,
                  passage: passage,
                  option1: option1,
                  option2: option2,
                  option3: option3,
                  option4: option4,
                  option5: option5,
                  sub_category_id: parseInt(sub_category_id),
                  category_id: parseInt(category_id),
                }),
                handleHideShow(test_type),
                setQuestionID(question_id),
                get_SubCategory_by_id(category_id),
                setShowForm(true)
              )}
            >
              <i class="fa fa-pencil" aria-hidden="true"></i>
            </Link>
            <Link
              className="edit-btn"
              to={`/admin/test-management/questionaire-allDetail/${question_id}`}
            >
              <i class="fa fa-eye" aria-hidden="true"></i>
            </Link>
          </>
        ),
      };
    });

    if (arrayData?.length) {
      setShowQuestionList(true);
    }
    setGrade(arrayData, setShowResults(true));
  }
  const get_subCategory_data = async (e) => {
    const Subcategory = await get_SubCategory_by_id(e);
    setSubCategory(Subcategory?.data);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  console.log('inputlist',inputList)
  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Add Questionnaire</h4>
          <Link
            className="edit-btn"
            to={`/admin/test-management/questionaire-all`}
            onClick={(e) => {
              if (window.confirm("Are you sure you want to leave this page?")) {
              } else {
                e.preventDefault(); 
              }
            }}
          >
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
          </Link>
        </div>
        <b className="text-center">Selected Questionnaire Detail</b>
        <div className="modal-body">
          <div className="form-horizontal">
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label>Grade</label>
                  <p>{questionData?.grade ? questionData?.grade : "-"}</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Term</label>
                  <p>{questionData?.term ? questionData?.term : "-"}</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Course Type</label>
                  <p>
                    {questionData?.course_type
                      ? questionData?.course_type
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Subject</label>
                  <p>{questionData?.subject ? questionData?.subject : "-"}</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Time </label>
                  <p>{questionData?.time ? questionData?.time : "-"} mins</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Question Name</label>
                  <p>
                    {questionData?.question_name
                      ? questionData?.question_name
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Questionnaire Type</label>
                  <p>
                    {questionData?.questionnaire_type
                      ? questionData?.questionnaire_type
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
            {!showForm ? (
              <div className="modal-footer">
                <Button
                  type="button"
                  onClick={() => setShowForm(true)}
                  className="btn btn-primary"
                >
                  Add Question
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
          <hr />
          {showForm ? (
            <Form
              className="form-horizontal"
              id="myform"
              encType="multipart/form-data"
            >
              <div className="row">
                <div className="wrapper">
                  {/* <div className="custom-popup__title">Popup Header</div> */}
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Question Type*</label>
                          <select
                            className="form-select form-control"
                            value={inputList.test_type}
                            name="test_type"
                            onChange={(e) => (
                              handleInputChange(e),
                              handleHideShow(e.target.value)
                            )}
                            
                          >
                            <option value="">Choose Question Type</option>
                            <option value="objective">Multiple-choice</option>
                            <option value="subjective">Open-ended</option>
                          </select>
                          {formVal.test_type && <p className="validation-error">{formVal.test_type}</p>}   
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Category *</label>
                          <select
                            required
                            className="form-control form-select"
                            name="category_id"
                            value={inputList?.category_id}
                            onChange={(e) => (
                              handleInputChange(e),
                              get_subCategory_data(e.target.value)
                            )}
                          >
                            <option value="">Choose Category</option>
                            {category &&
                              category?.map((elem) => {
                                return (
                                  <option value={elem?.id}>{elem?.name}</option>
                                );
                              })}
                          </select>
                          {formVal.category_id && <p className="validation-error">{formVal.category_id}</p>}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Sub Category *</label>
                          <select
                            required
                            className="form-control form-select"
                            name="sub_category_id"
                            value={inputList?.sub_category_id}
                            onChange={(e) => handleInputChange(e)}
                          >
                            <option value="">Choose Sub Category</option>
                            {Subcategory &&
                              Subcategory?.map((elem) => {
                                console.log("sub", Subcategory);
                                return (
                                  <option value={elem?.id}>{elem?.name}</option>
                                );
                              })}
                          </select>
                          {formVal.sub_category_id && <p className="validation-error">{formVal.sub_category_id}</p>}
                        </div>
                      </div>

                      {show ? (
                        <>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>document upload</label>
                              <input
                                type="file"
                                ref={inputfile}
                                className="form-control"
                                name="upload_data"
                                onChange={(e) => handleImgChange(e)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Question *</label>
                              <textarea
                                type="text"
                                required
                                className="form-control"
                                name="question"
                                placeholder="Enter Question"
                                value={inputList.question}
                                onChange={(e) => handleInputChange(e)}
                              />
                               {formVal.question && <p className="validation-error">{formVal.question}</p>}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Answer </label>
                              <input
                                type="text"
                                placeholder="Enter Answer"
                                className="form-control"
                                name="answer"
                                value={inputList.answer}
                                onChange={(e) => handleInputChange(e)}
                              />
                              {formVal.answer && <p className="validation-error">{formVal.answer}</p>}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>document upload</label>
                              <input
                                ref={inputfile}
                                type="file"
                                className="form-control"
                                name="upload_data"
                                onChange={(e) => handleImgChange(e)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Image File</label>
                              <input
                                ref={inputfile}
                                type="file"
                                className="form-control"
                                name="image"
                                accept="image/png,image/jpeg,image/jpg"
                                onChange={(e) => handleImgChange(e)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group text_anitem">
                              <label>Passage</label>
                              <textarea
                                className="form-control"
                                name="passage"
                                placeholder="Enter Passage"
                                value={inputList.passage}
                                onChange={(e) => handleInputChange(e)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Question *</label>
                              <textarea
                                type="text"
                                className="form-control"
                                name="question"
                                placeholder="Enter Question"
                                value={inputList.question}
                                onChange={(e) => handleInputChange(e)}
                              />
                              {formVal.question && <p className="validation-error">{formVal.question}</p>}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-12 option-sec">
                                <ul>
                                  <li>
                                    <div className="form-group">
                                      <label>Option 1 *</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="option1"
                                        value={inputList.option1}
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      {formVal.option1 && <p className="validation-error">{formVal.option1}</p>}
                                    </div>
                                  </li>
                                  <li>
                                    <div className="form-group">
                                      <label>Option 2 *</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="option2"
                                        value={inputList.option2}
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                           {formVal.option2 && <p className="validation-error">{formVal.option2}</p>}
                                    </div>
                                  </li>
                                  <li>
                                    {" "}
                                    <div className="form-group">
                                      <label>Option 3 *</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="option3"
                                        value={inputList.option3}
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      {formVal.option3 && <p className="validation-error">{formVal.option3}</p>}
                                    </div>
                                  </li>
                                  <li>
                                    {" "}
                                    <div className="form-group">
                                      <label>Option 4 </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="option4"
                                        value={inputList.option4}
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      {formVal.option4 && <p className="validation-error">{formVal.option4}</p>}
                                    </div>
                                  </li>
                                  <li>
                                    {" "}
                                    <div className="form-group">
                                      <label>Option 5 </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="option5"
                                        value={inputList.option5}
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                      {formVal.option5 && <p className="validation-error">{formVal.option5}</p>}
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Answer *</label>
                              {/* <input
                                type="text"
                                placeholder="Enter Answer"
                                className="form-control"
                                name="answer"
                                value={inputList.answer}
                                onChange={(e) => handleInputChange(e)}
                              /> */}
                              <select
                            required
                            className="form-control form-select"
                            name="answer"
                            value={inputList.answer}
                            onChange={(e) => handleInputChange(e)}
                          >
                            <option value="">Choose Correct Option</option>
                            <option value="1">Option 1</option>
                            <option value="2">Option 2</option>
                            <option value="3">Option 3</option>
                            <option value="4">Option 4</option>
                            <option value="5">Option 5</option>
                          </select>
                              {formVal.answer && <p className="validation-error">{formVal.answer}</p>}
                            </div>
                          </div>
                        </>
                      )}
                      <div className="modal-footer">
                        <Button
                          type="button"
                          onClick={() => onSubmit()}
                          className="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          ) : (
            ""
          )}
          <div>
            {showQuestionList ? (
              showResults ? (
                <CustomTable columns={columns} data={grade} />
              ) : (
                ""
              )
            ) : (
              " "
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default QuestionaireAdd;
