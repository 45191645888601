import React from 'react';
import { BrowserRouter, Routes, Route, Link, Navigate } from 'react-router-dom';
import { RequireAuthAdmin, AdminLogout, ProtectedRoute, AfterLogin } from '../Utils/Utils';
import {
  AdminLogin, Layout, Dashboard, Events, AddEvent, EditEvent,AddSubject, EditSubject, AllSubjects, AddClassList, EditClassList,
  AllClassList, AddClassDeatils, EditClassDetails, AllClassDetails, Members, CseStudent, SttStudent, AddMembers, NoOfStudent, WemgTypeOne, WemgTypeTwo, LeftFloatingMenu, EnvironmentVariable, CseBrances, Estudy,
  FormativeAttendance, EassyWriting, UploadOmrSacn, AddBrance, EditBrance, Allitems, AddItem, EditItem, AllWritingComments, AddWritingComment, EditWritingComment, AllTestType, AddTestType, EditTestType, AddTestDetail,
  AllTestDetail, EditTestDetail, AddNaplanCriteria, AllnaplanCriteria, EditNaplanCriteria, EditCategory, AddCategory, AllCategory, GoogleClass, EditGoogleClass, AddTeacher, AllTeachers, EditTeacher, AddEassyWriting, EditEassyWriting,
  EditMember, Consultancy, GoogleClassList, AddGrades, AllGrade, EditGrade, UploadFile, ClassList, ClassView, AddQuestionaire, AllQuestionaire, EditQuestionaire, AllQuestionaireDetail
  , AddStudyMaterial, EditStudyMaterial, AddTerms, AllTerms, EditTerms, AddCourse, AllCourses, EditCourse, AddWebsiteEnv, Websit_env_view, AddSubUsers, SubUser_List, Users, Addassesment, AllassesmentList, EditAssesment, AllFreeAssesmentList,AddFreeAssesment,EditFreeAssesment, Student_enroll_view,
  EnrolledMembers, AllLearningDetail, AddDisount, EditDiscount, AllDiscount, AddTextBook,EditTextbooks,AllTextBooks,ViewAttendance,Attendance,AttendanceView,AddTrialTest,EditTrialTest,AllTrialTest
  ,Add_category,Edit_category,All_category,AddSubCategory,AllSubCategory,EditSubCategory,QuestionaireAdd,TeacherByBranchId,EditSubUsers
} from '../componrnt/pages/Index';
import { AddDays } from '../componrnt/pages/Day/Add';
import AllDays from '../componrnt/pages/Day/All';
import EditDays from '../componrnt/pages/Day/Edit';
import Home from '../componrnt/pages/Home/Home';
import EventView from '../componrnt/pages/Events/EventView';
function CreateRoute() {

  const userData = localStorage.getItem('userData');
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/admin"
          element={<AfterLogin><AdminLogin /></AfterLogin>}
        />
        <Route element={<RequireAuthAdmin />}>
          <Route path="/admin" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
            <Route path="dashboard" element={<Dashboard />} />
            {/* marketing and supporting */}
            <Route path="marketing-support/e-study" element={<Estudy />} />
            <Route path="marketing-support/formative-attendace" element={<FormativeAttendance />} />
            <Route path="marketing-support/easy-writing" element={<EassyWriting />} />
            <Route path="marketing-support/easy-writing-add" element={<AddEassyWriting />} />
            <Route path="marketing-support/easy-writing-edit/:id" element={<EditEassyWriting />} />
            <Route path="marketing-support/upload-omr-scan" element={<UploadOmrSacn />} />

            {/* events section */}
            <Route path="administrator-setting/events" element={<Events />} />
            <Route path="administrator-setting/events/add" element={<AddEvent />} />
            <Route path="administrator-setting/events/edit/:id" element={<EditEvent />} />
            <Route path="administrator-setting/events/EventView/:id" element={<EventView />} />

            {/* class management subject, class list, class details */}
            <Route path="general-setting/subjects" element={<AllSubjects />} />
            <Route path="general-setting/subject/add" element={<AddSubject />} />
            <Route path="general-setting/subject/edit/:id" element={<EditSubject />} />

            <Route path="class-lists" element={<AllClassList />} />
            <Route path="class-list/add" element={<AddClassList />} />
            <Route path="class-list/edit/:id" element={<EditClassList />} />
            <Route path="class-list/google-class" element={<GoogleClass />} />
            <Route path="class-list/google-class-list" element={<GoogleClassList />} />
            <Route path="class-list/google-class/edit/:id" element={<EditGoogleClass />} />
            <Route path="class-list/View/:id" element={<ClassView />} />
            <Route path="class-list/StudyMaterial/add" element={<AddStudyMaterial />} />
            <Route path="class-list/StudyMaterial/edit" element={<EditStudyMaterial />} />

            <Route path="class-details" element={<AllClassDetails />} />
            <Route path="class-detail/add" element={<AddClassDeatils />} />
            <Route path="class-detail/edit/:id" element={<EditClassDetails />} />
            {/* student management */}
            <Route path="students/members" element={<Members />} />
            <Route path="students/enrolled" element={<EnrolledMembers />} />
            <Route path="students/add-member" element={<AddMembers />} />
            <Route path="students/member-edit/:id" element={<EditMember />} />
            <Route path="students/cse-student" element={<CseStudent />} />
            <Route path="students/stt-student" element={<SttStudent />} />
            <Route path="students/consultancy" element={<Consultancy />} />
            <Route path="students/student_enroll_view/:id" element={<Student_enroll_view />} />

            {/* audit apis */}
            <Route path="audit/no-of-student" element={<NoOfStudent />} />
            <Route path="audit/wemg-type-one" element={<WemgTypeOne />} />
            <Route path="audit/wemg-type-two" element={<WemgTypeTwo />} />

            {/* reports */}

            {/* Test managment */}
            {/*Test Item */}
            <Route path="test-management/items" element={<Allitems />} />
            <Route path="test-management/add" element={<AddItem />} />
            <Route path="test-management/edit/:id" element={<EditItem />} />
            {/*writing comment */}
            <Route path="test-management/writing-comments" element={<AllWritingComments />} />
            <Route path="test-management/writing-add" element={<AddWritingComment />} />
            <Route path="test-management/writing-edit/:id" element={<EditWritingComment />} />
            {/*test type */}
            <Route path="test-management/test-type" element={<AllTestType />} />
            <Route path="test-management/test-add" element={<AddTestType />} />
            <Route path="test-management/test-edit/:id" element={<EditTestType />} />
            {/*test detail */}
            <Route path="test-management/testDetail-add" element={<AddTestDetail />} />
            <Route path="test-management/testDetail" element={<AllTestDetail />} />
            <Route path="test-management/testDetail-edit/:id" element={<EditTestDetail />} />
            {/*NaplanCriteria */}
            <Route path="test-management/naplanCriteria-add" element={<AddNaplanCriteria />} />
            <Route path="test-management/naplanCriteria" element={<AllnaplanCriteria />} />
            <Route path="test-management/naplanCriteria-edit/:id" element={<EditNaplanCriteria />} />
            {/* Questionaire */}
            <Route path="test-management/questionaire-add" element={<AddQuestionaire />} />
            <Route path="test-management/questionaire-all" element={<AllQuestionaire />} />
            <Route path="test-management/questionaire-edit/:id" element={<EditQuestionaire />} />
            <Route path="test-management/questionaire-allDetail/:id" element={<AllQuestionaireDetail />} />

            <Route path="/admin/test-management/questionaire-add/add/:grade_id/:subject_id" element={<QuestionaireAdd />} />
            {/* general setting */}
            <Route path="general-setting/left-floating-menu" element={<LeftFloatingMenu />} />
            <Route path="general-setting/environment-variable" element={<EnvironmentVariable />} />
            <Route path="administrator-setting/cse-brances" element={<CseBrances />} />
            <Route path="administrator-setting/add" element={<AddBrance />} />
            <Route path="administrator-setting/edit/:id" element={<EditBrance />} />

            {/* Teachers management */}
            <Route path="teachers-management/all" element={<AllTeachers />} />
            <Route path="teachers-management/add" element={<AddTeacher />} />
            <Route path="teachers-management/edit/:id" element={<EditTeacher />} />
            <Route path="teachers-management/add" element={<AddTeacher />} />
            <Route path="teachers-management/teacher-by-branch" element={<TeacherByBranchId />} />

            {/* Category */}
            <Route path="general-setting/category/add" element={<AddCategory />} />
            <Route path="general-setting/category/edit/:id" element={<EditCategory />} />
            <Route path="general-setting/category/all" element={<AllCategory />} />

            {/* grades, terms, course */}
            <Route path="general-setting/grade/add" element={<AddGrades />} />
            <Route path="general-setting/grade/edit/:id" element={<EditGrade />} />
            <Route path="general-setting/grades/all" element={<AllGrade />} />
            <Route path="upload/file" element={<UploadFile />} />
            <Route path="class-list/class-List" element={<ClassList />} />

            <Route path="general-setting/term/add" element={<AddTerms />} />
            <Route path="general-setting/term/all" element={<AllTerms />} />
            <Route path="general-setting/term/edit/:id" element={<EditTerms />} />

            <Route path="general-setting/day/add" element={<AddDays />} />
            <Route path="general-setting/day/all" element={<AllDays />} />
            <Route path="general-setting/day/edit/:id" element={<EditDays />} />

            <Route path="course-managment/course/add" element={<AddCourse />} />
            <Route path="course-managment/course/add/term" element={<AddCourse />} />
            <Route path="course-managment/course/all" element={<AllCourses />} />
            <Route path="course-managment/course/edits/:id" element={<EditCourse />} />
            <Route path="course-managment/course/edit/:course_id/:id/:course_type_id/:grade_id" element={<EditCourse />} />

            {/* website Env*/}
            <Route path="administrator-setting/websiteEnv/add" element={<AddWebsiteEnv />} />
            <Route path="administrator-setting/websiteEnv/all" element={<Websit_env_view />} />
            {/* sub-users*/}
            <Route path="administrator-setting/sub-users/add" element={<AddSubUsers />} />
            <Route path="administrator-setting/sub-users/edit/:id" element={<EditSubUsers />} />
            <Route path="administrator-setting/sub-users/all" element={<SubUser_List />} />
            {/* All users*/}
            <Route path="administrator-setting/users" element={<Users />} />
            <Route path="assesment/add" element={<Addassesment />} />
            <Route path="assesment/all" element={<AllassesmentList />} />
            <Route path="assesment/edit" element={<EditAssesment />} />
            <Route path='course-managment/assesment/all-free' element={<AllFreeAssesmentList />} />

            <Route path="viewAttendance/:id" element={<ViewAttendance />} />

             {/* Free Assessment */}
            <Route path="course-managment/free-assesment/add" element={<AddFreeAssesment />} />
            <Route path="course-managment/free-assesment/edit" element={<EditFreeAssesment />} />
            {/* Steps of learning */}
            <Route path='steps-of-learning' element={<AllLearningDetail />} />

            {/* Discounte */}
            <Route path="general-setting/discount/add" element={<AddDisount />} />
            <Route path="general-setting/discount/edit/:id" element={<EditDiscount />} />
            <Route path="general-setting/discount/all" element={<AllDiscount />} />
              {/* Text Book */}
              <Route path="course-managment/text-book/add" element={<AddTextBook />} />
            <Route path="course-managment/text-book/edit/:id" element={<EditTextbooks />} />
            <Route path="course-managment/text-book/all" element={<AllTextBooks />} />


               {/* Attendance */}
               <Route path="students/attendance" element={<Attendance />} />
               <Route path="students/attendance/view/:id" element={<AttendanceView />} />

               {/* Selective Trial Test apis */}
            <Route path="course-managment/selective-trial-test/add" element={<AddTrialTest />} />
            <Route path="course-managment/selective-trial-test/edit/:id" element={<EditTrialTest />} />
            <Route path="course-managment/selective-trial-test/all" element={<AllTrialTest />} />

            {/* Category and SubCategory */}
            <Route path="general-setting/Categories/add" element={<Add_category />} />
            <Route path="general-setting/Categories/edit/:id" element={<Edit_category />} />
            <Route path="general-setting/Categories/all" element={<All_category />} />

            <Route path="general-setting/sub-category/add" element={<AddSubCategory />} />
            <Route path="general-setting/sub-category/edit/:id" element={<EditSubCategory />} />
            <Route path="general-setting/sub-category/all" element={<AllSubCategory />} />
          </Route>
          <Route path='logoutAdmin' element={<AdminLogout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default CreateRoute;