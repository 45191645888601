import axios from "axios";
import { json } from "react-router-dom";
import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";

// add grade
export async function course_add(data) {
  
    
    try {
// Send a POST request
      let responseData=   await axios({
          method: 'post',
          url: HTTPURL + 'api/course-add',
          data: data
        });

       // const response = await fetch(HTTPURL + 'api/course-add', requestOptions);
       // const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

//add course lecture
export async function course_lecture(data, id,term_id) {
    
    var requestOptions = {
        method: 'POST',
        body: data, 
        //headers: { 'Content-Type': 'application/json' }, 
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${HTTPURL}api/course-lecture?course_id=${id}&term_id=${term_id}`, requestOptions);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

//update course lecture
export async function update_course_lecture(course_id,term_id,data) {
    
    var requestOptions = {
        method: 'POST',
        body: data, 
        //headers: { 'Content-Type': 'application/json' }, 
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${HTTPURL}api/update-course-lecture?course_id=${course_id}&term_id=${term_id}`, requestOptions);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


//add course self study
export async function course_self_study(data, id,term_id) {
    console.log("data*******",data,id);
    
    var requestOptions = {
        method: 'POST',
        body: data, 
        //headers: { 'Content-Type': 'application/json' }, 
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${HTTPURL}api/course-self-study?course_id=${id}&term_id=${term_id}`, requestOptions);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

//update course self study
export async function update_course_self_study(course_id,term_id,data) {
    
    var requestOptions = {
        method: 'POST',
        body: data, 
        //headers: { 'Content-Type': 'application/json' }, 
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${HTTPURL}api/update-course-self-study?course_id=${course_id}&term_id=${term_id}`, requestOptions);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


//add course homework
export async function course_homework(data, id,term_id) {
    console.log("data*******",data,id);
    
    var requestOptions = {
        method: 'POST',
        body: data, 
        //headers: { 'Content-Type': 'application/json' }, 
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${HTTPURL}api/course-homework?course_id=${id}&term_id=${term_id}`, requestOptions);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

//update course homework
export async function update_course_homework(course_id,term_id,data) {
    
    var requestOptions = {
        method: 'POST',
        body: data, 
        //headers: { 'Content-Type': 'application/json' }, 
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${HTTPURL}api/update-course-homework?course_id=${course_id}&term_id=${term_id}`, requestOptions);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

//add course etutoring
export async function course_etutoring(data, id,term_id) {
    console.log("data*******",data,id);
    
    var requestOptions = {
        method: 'POST',
        body: data, 
        //headers: { 'Content-Type': 'application/json' }, 
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${HTTPURL}api/course-etutoring?course_id=${id}&term_id=${term_id}`, requestOptions);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

//update course etutoring
export async function update_course_etutoring(course_id,term_id,data) {
   // console.log("data*******",data,id);
    
    var requestOptions = {
        method: 'POST',
        body: data, 
        //headers: { 'Content-Type': 'application/json' }, 
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${HTTPURL}api/update-course-etutoring?course_id=${course_id}&term_id=${term_id}`, requestOptions);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


//add course consulting
export async function course_consulting(data, id, term_id) {
    console.log("data*******",data,id);
    
    var requestOptions = {
        method: 'POST',
        body: data, 
        //headers: { 'Content-Type': 'application/json' }, 
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${HTTPURL}api/course-consulting?course_id=${id}&term_id=${term_id}`, requestOptions);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


//update course consulting
export async function update_course_consulting(course_id,term_id,data) {
   // console.log("data*******",data,id);
    
    var requestOptions = {
        method: 'POST',
        body: data, 
        //headers: { 'Content-Type': 'application/json' }, 
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${HTTPURL}api/update-course-consulting?course_id=${course_id}&term_id=${term_id}`, requestOptions);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



//add course  evaluation
export async function course_evaluation(data, id,term_id) {
    console.log("data*******",data,id);
    
    var requestOptions = {
        method: 'POST',
        body: data, 
        //headers: { 'Content-Type': 'application/json' }, 
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${HTTPURL}api/course-evaluation?course_id=${id}&term_id=${term_id}`, requestOptions);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

//update course  evaluation
export async function update_course_evaluation(course_id,term_id,data) {
    //console.log("data*******",data,id);
    
    var requestOptions = {
        method: 'POST',
        body: data, 
        //headers: { 'Content-Type': 'application/json' }, 
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${HTTPURL}api/update-course-evaluation?course_id=${course_id}&term_id=${term_id}`, requestOptions);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all course
export async function course_all() {
    try {
        const response = await fetch(HTTPURL + 'api/courses-all');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



export async function courses_all_data() {
    try {
        const response = await fetch(HTTPURL + 'api/all-courses-data');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// grade edit 
export async function course_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/course-edit?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
// grade update 
export async function course_update(course_id, data) {
    var requestOptions = {
        method: 'POST',
        body: data,
        redirect: 'follow'
    };
    try {
        const response = await fetch(HTTPURL + `api/course-update/?id=${course_id}`,requestOptions);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// branch delete
export async function course_destroy(id) {
    try {
        const response = await fetch(HTTPURL + `api/course-destroy?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


export async function get_course_grade_id(id) {
    try {
        const response = await fetch(HTTPURL + `api/get-course-by-grade/?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get  course by grade id
export async function select_course_all_by_gradeID(id) {
    try {
        const response = await fetch(HTTPURL + `api/select-courses-all?grade_id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}