import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../../Utils/DataLoader.jsx';
import {student_enroll_list} from '../../../api/Student_Enroll.jsx';
import CustomTable from '../../pages/CustomTable';

export const columns = [
  
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'First Name',
        selector: 'first_name',
        sortable: true,
    },
    {
        name: 'Grade',
        selector: 'grade',
        sortable: true,
    },
    {
        name: 'Subject',
        selector: 'subject',
        sortable: true,
    },
    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    }
];
// const branch_id=localStorage.getItem('b_id')
// console.log('branch_id',branch_id);
const Student_enroll_list = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);

    useEffect(() => 
    {
      const branch_id=localStorage.getItem('b_id')
        async function get_data() {
            const result = await student_enroll_list(branch_id);
            const arrayData = result?.data?.map((elem, index) => {
              const {id,first_name,subject,grade} = elem;
              return {
                no: `#${index + 1}`,
                first_name:first_name,
                grade:grade,
                subject: subject?subject:'-',
                action:<div data-tag="allowRowEvents">
                <Link className='edit-btn' to={`/branch/student_enroll-view/${id}`}><i class="fa fa-eye" aria-hidden="true"></i></Link>
                </div>
              };
            });
            setData(arrayData, setShowResults(true));
          }
        //   const destroy = (id) => {
        //     const result = window.confirm("Are you sure you want to delete  subject?");
        //     if (result) {
        //       subject_destroy(id);
        //     }
        //     get_data();
        //   }
          get_data();         
    }, []);

    return (
      <div className="right-nav" id="dash-event">
        <div className='row'>
                <div className='col-md-4'>
                    <h4 className='modal-title'>Student Enrolled List</h4>
                </div>
                {/* <div className='col-md-8'>
                    <Link className='ui button float-end' to="/branch/subject/add">Add Subject</Link>
                </div> */}
            </div>
      {
          showResults ?
          
          <CustomTable
              columns={columns}
              data={data}
          />
        : 
        <DataLoader />
      }
    </div>
    )
}

export default Student_enroll_list;