import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { Link, useNavigate,useParams } from 'react-router-dom';
import { sub_users_edit,sub_users_update } from '../../../../apis/generalSetting/SubUsers';
const EditSubUsers = () => {
    const [payment, setPayment] = useState(false)
    const [branch, setBranch] = useState(false)
    const [classes, setClasses] = useState(false)
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset
    } = useForm();

const id=useParams();
    const navigate = useNavigate();
    const handleChange = (e) => {
        e.target.checked ? setPayment(true) : setPayment(false);
    };
    const handleChange1 = (e) => {
        e.target.checked ? setBranch(true) : setBranch(false);
    };
    const handleChange2 = (e) => {
        e.target.checked ? setClasses(true) : setClasses(false);
    };
    const onSubmit = async (data) => {
        data.payment = payment ? '1' : '0'
        data.branch = branch ? '1' : '0'
        data.classes = classes ? '1' : '0'
        let result = await sub_users_update(id,data);
        if (result.status) {
            return navigate('/admin/administrator-setting/sub-users/all');
        }
        reset()
    }


    const fetchData = async () => 
    {
        let res = await sub_users_edit(id?.id);
        console.log('res',res?.data[0]);
        setValue('first_name',res?.data[0]?.first_name);
        setValue('last_name',res?.data[0]?.last_name);
        setValue('mobile',res?.data[0]?.mobile);
        setValue('status',res?.data[0]?.status.toString());
        setValue('email',res?.data[0]?.email);
        setPayment(res?.data[0]?.payment ? true : false)
        setBranch(res?.data[0]?.branch ? true : false)
        setClasses(res?.data[0]?.c_name ? true : false)
    }

    useEffect(() => {fetchData();},[]);
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className='row'>
                    <div className='col-md-12'>
                    <div className='sub-users'>
                        <h4 className="modal-title">Edit Sub  Users</h4>
                        <Link className='edit-btn' to={`/admin/administrator-setting/sub-users/all`} onClick={(e) => {
    if (window.confirm("Are you sure you want to leave this page?")) {
    } else {
      e.preventDefault(); 
    }
  }}><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
  </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='permission-sec-n'>
                             <h4 className="modal-title">Permissions</h4>
                             <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label>First Name</label>
                                            <input type="text"
                                                placeholder='Enter User First Name'
                                                className="form-control" {...register('first_name',{ required: true })}
                                            />
                                        </div>
                                        {errors.first_name && errors.first_name.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label>Last Name</label>
                                            <input type="text"
                                                placeholder='Enter User Last Name'
                                                className="form-control" {...register('last_name',{ required: true })}
                                            />
                                        </div>
                                        {errors.last_name && errors.last_name.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="email"
                                                placeholder='Enter User Email'
                                                className="form-control" {...register('email',{ required: true })}
                                            disabled/>
                                        </div>
                                    </div>
                                    {/* <div className='col-md-6'>
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input type="text"
                                                placeholder='Enter User Password'
                                                className="form-control" {...register('password')}
                                            />
                                        </div>
                                    </div> */}
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label>Mobile No.</label>
                                            <input type="text"
                                                placeholder='Enter User Mobile No.'
                                                className="form-control" {...register('mobile',{ required: true })}
                                            />
                                        </div>
                                        {errors.mobile && errors.mobile.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>status</label>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" {...register('status', { required: true })} id="inlineRadio1" value={1} />
                                                <label class="form-check-label" for="inlineRadio1">Active</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" {...register('status', { required: true })} id="inlineRadio2" value={0}/>
                                                <label class="form-check-label" for="inlineRadio2">Inactive</label>
                                            </div>
                                        </div>
                                        {errors.status && errors.status.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 checkbox-n'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <input class="form-check-input"
                                            type="checkbox"
                                            value={payment}
                                            onChange={handleChange}
                                            checked={payment}
                                        /> <span className="modal-title">Payment</span>
                                    </div>
                                    <div className='col-md-12'>
                                        <input class="form-check-input"
                                            type="checkbox"
                                            value={classes}
                                            onChange={handleChange1}
                                            checked={branch}
                                        /> <span className="modal-title">Branch</span>
                                    </div>
                                    <div className='col-md-12'>
                                        <input class="form-check-input"
                                            type="checkbox"
                                            value={payment}
                                            checked={classes}
                                            onChange={handleChange2}
                                        /> <span className="modal-title">Classes</span>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="modal-footer">
                            <button type='submit' className="btn btn-primary">Save</button>
                        </div>
                    </form>
                </div>
                        </div>
                    </div>
                </div>
                

            </div>
        </div>
    )
}
export default EditSubUsers;

