import React, { useEffect, useRef, useState } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { member_edit, member_update } from '../../../../apis/studentManagement/StudentManagement.jsx';
import { branch_all } from '../../../../apis/Branches.jsx';
import { grade_all } from '../../../../apis/Grade';
import { get_days } from '../../../../apis/Common';
import { course_all } from '../../../../apis/Course.jsx';
const EditMember = () => {
    const [course, setCourse] = useState([]);
    const [gradelist, setGradeList] = useState([]);
    const [branchlist, setBranchList] = useState([]);
    const [days, setDays] = useState([]);
    // const [countries, setCountries] = useState([]);
    const [show, setShow] = useState(false);
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        watch
    } = useForm();

    const navigate = useNavigate();
    const password = useRef({});
    const classroom_gmail = useRef({});
    const p_email = useRef({});
    const id = useParams();

    password.current = watch("password", "");
    classroom_gmail.current = watch("classroom_gmail", "");
    p_email.current = watch("p_email", "");

    const onSubmit = async (data) => {
        console.log('data', data);
        data.pc_email = ''
        let result = await member_update(id, data);
        if (result.status) {
            return navigate('/branch/free_student_list');
        }
    }

 

    useEffect(() => {
        const fetchEditMember = async () => {
            let res = await member_edit(id);
            setValue('first_name', res?.data?.first_name);
            setValue('last_name', res?.data?.last_name);
            setValue('gender', res?.data?.gender);
            const dateComponents = res?.data?.date_of_birth?.split('-');
            console.log('dateComponents', dateComponents);
            const year = parseInt(dateComponents[0]);
            const month = parseInt(dateComponents[1]) - 1; // No need to subtract 1 here
            const day = parseInt(dateComponents[2]);
            const utcDate = Date.UTC(year, month, day);
            const formattedDate = new Date(utcDate).toISOString().substring(0, 10);
            console.log('formattedDate', formattedDate);
            setValue('date_of_birth', `${formattedDate}`);
            setValue('school', res?.data?.school);
            setTimeout(() => {
                setValue('grade', res?.data?.grade);
                setValue('branch', res?.data?.branch);
                setValue('day', res?.data?.day);
                setValue('type', res?.data?.type);
                // setValue('country', res?.data?.country);
                setValue('state', res?.data?.state);
                setValue('sibling_grade', res?.data?.sibling_grade);
                setValue('course', res?.data?.course);
            }, 1000);
            setValue('studentID', `CSPRO00${res?.data?.id}`)
            setValue('classroom_gmail', res?.data?.classroom_gmail);
            setValue('c_classroom_gmail', res?.data?.classroom_gmail);
            setValue('cs_education', res?.data?.cs_education);
            setShow(res?.data?.cs_education == '1' ? true : false)
            setValue('student_number', res?.data?.student_number);
            setValue('p_first_name', res?.data?.p_first_name);
            setValue('p_last_name', res?.data?.p_last_name);
            setValue('p_mobile_phone', res?.data?.p_mobile_phone);
            setValue('mother_mobilenumber', res?.data?.mother_mobilenumber);
            setValue('father_mobilenumber', res?.data?.father_mobilenumber);
            setValue('address', res?.data?.address);
            setValue('city', res?.data?.city);
            // setValue('post_code', res?.data?.post_code);
            setValue('p_email', res?.data?.p_email);
            setValue('pc_email', res?.data?.p_email);
            setValue('sibling', res?.data?.sibling);
            setValue('email', res?.data?.email);
            setValue('password', res?.data?.password);
            setValue('confirmPassword', res?.data?.password);
        }
        const fetchData = async () => {
            const branchList = await branch_all();
            const gradeList = await grade_all();
            // const countryList = await get_countries();
            const days = await get_days();
            setBranchList(branchList?.data);
            setGradeList(gradeList?.data);
            // setCountries(countryList?.data);
            const course = await course_all();
            setDays(days?.data);
            setCourse(course?.data)
        };
        fetchData();
        fetchEditMember();
    }, []);
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 8);

    const maxDateStr = maxDate.toISOString().split('T')[0];
    return (
        <>
            <div className="right-nav" id="dash-event">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Edit Account</h4>
                        <Link className='edit-btn' to={`/branch/free_student_list`} onClick={(e) => {
    if (window.confirm("Are you sure you want to leave this page?")) {
    } else {
      e.preventDefault(); 
    }
  }}><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
                    </div>
                    <div className="modal-body">
                        <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                            <div className="col-md-12">
                                {/* <hr className='mt-3' /> */}
                                <div className="form-group">
                                    <label><b>Step1. Student information</b></label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Student Id:</label>
                                        <input type="text" className="form-control" name="venue_name"
                                            {...register('studentID')}
                                            disabled />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>First Name *</label>
                                        <input type="text" className="form-control" name="first_name"
                                            placeholder='Enter First Name'
                                            {...register('first_name', { required: true })}

                                        />
                                        {errors.first_name && errors.first_name.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Last Name *</label>
                                        <input type="text" className="form-control" name="last_name"
                                            placeholder='Enter Last Name'
                                            {...register('last_name', { required: true })}
                                        />
                                        {errors.last_name && errors.last_name.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Gender</label>
                                        <div className='highlight_inner'>
                                            <div className=" checkbox">
                                                <label> <input type="radio" id="event_offline" name="male" value="male"
                                                    {...register('gender')}
                                                /> Male </label>
                                            </div>
                                            <div className=" checkbox">
                                                <label> <input type="radio" id="event_online" name="female" value="female"
                                                    {...register('gender')}
                                                /> Female </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Date of Birth *</label>
                                        <input type="date" className="form-control" name="date_of_birth"
                                            max={maxDateStr}
                                            {...register('date_of_birth', { required: true })}
                                        />
                                        {errors.date_ob && errors.date_ob.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">

                                        <label>School Name</label>
                                        <input type="text" className="form-control" name="schoole_name"
                                            placeholder='Enter School Name'
                                            {...register('school', { required: true })}
                                        />
                                    </div>
                                    {errors.school && errors.school.type === "required" && <span className="validation-error">This is required</span>}

                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">

                                        <label>Grade</label>
                                        <select className="form-select form-control" {...register('grade')}>
                                            <option value="">CHOOSE GRADE</option>
                                            {gradelist?.length > 0 ? (
    gradelist.map((elem) => {
        return (
            <option key={elem?.id} value={elem?.id}>
                {elem.name === 0 ? `Year ${elem.name} (kindy)` : `Year ${elem.name}`}
            </option>
        );
    })
) : (
    <option>No result</option>
)}

                                        </select>
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Classroom Gmail:*</label>
                                        <input type="text" className="form-control"
                                        placeholder='Enter Gmail'
                                        name="venue_name"
                                            {...register("classroom_gmail",
                                                {
                                                    required: true,
                                                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                                })
                                            }
                                        />
                                        {errors.classroom_gmail && errors.classroom_gmail.type === "required" && <span className="validation-error">This is required</span>}
                                        {errors.classroom_gmail && errors.classroom_gmail.type === "pattern" && <span className="validation-error">Must be a valid email</span>}
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Confirm Classroom Gmail:*</label>
                                        <input type="text" className="form-control"
                                          placeholder='Enter Classroom Gmail'
                                        name="venue_name"
                                            {...register("c_classroom_gmail",
                                                {
                                                    required: true,
                                                    validate: value =>
                                                    value === classroom_gmail.current || "This email do not match!",
                                                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                                })
                                            }
                                        />
                                        {errors.c_classroom_gmail && errors.c_classroom_gmail.type === "required" && <span className="validation-error">This is required</span>}
                                        {errors.c_classroom_gmail && errors.c_classroom_gmail.type === "pattern" && <span className="validation-error">Must be a valid email</span>}
                                        {errors.c_classroom_gmail && <p className="validation-error">{errors.c_classroom_gmail.message}</p>}
                                    </div>
                                </div> */}
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Studying at CS Education?</label>
                                        <div className='highlight_inner'>
                                            <div className=" checkbox">
                                                <label> <input type="radio" id="event_offline" value="1"
                                                    {...register('cs_education')}
                                                    onChange={() => setShow(true)}
                                                /> Yes </label>
                                            </div>
                                            <div className=" checkbox">
                                                <label> <input type="radio" id="event_online" value="0"
                                                    {...register('cs_education')}
                                                    onChange={() => setShow(false)}
                                                /> No </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    show ?
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>If yes, Student Number:</label>
                                                <input type="text" className="form-control" name="venue_name"
                                                    placeholder='Enter Student No.'
                                                    {...register('student_number')}
                                                />
                                            </div>
                                        </div> : ""
                                }
                                <div className={show ? `col-md-6` : `col-md-6`}>
                                    <div className="form-group">
                                        <label>Branch</label>
                                        <select className="form-select form-control" name="grade" {...register('branch')}>
                                            <option value="">Choose Branch</option>
                                            {
                                                branchlist?.length > 0 ?
                                                    branchlist && branchlist?.map((elem) => {
                                                        return (
                                                            <option value={elem?.id}>{elem?.branch_name}</option>
                                                        )
                                                    })
                                                    :
                                                    <option>No result</option>
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className={show ? `col-md-4` : `col-md-6`}>
                                    <div className="form-group">
                                        <label>Day </label>
                                        <select className="form-select form-control" {...register('day')}>
                                            <option value="">Choose Day</option>
                                            {
                                                days && days?.map((elem) => {
                                                    return (
                                                        <option value={elem?.id}>{elem?.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className={show ? `col-md-4` : `col-md-6`}>
                                    <div className="form-group">
                                        <label>Type</label>
                                        <select className="form-select form-control" {...register('type')}>
                                            <option value="">Choose Type</option>
                                            <option value="C1">C1</option>
                                            <option value="C2">C2</option>
                                            <option value="C3">C3</option>
                                        </select>
                                    </div>
                                </div>
                                <div className={show ? `col-md-4` : `col-md-6`}>
                                    <div className="form-group">
                                        <label>Course</label>
                                        <select className="form-select form-control"  {...register("course", { required: true })} >
                                            <option value="">Choose Course </option>
                                            {
                                                course && course?.map((elem) => {
                                                    return (
                                                        <option value={elem?.id}>{elem?.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    {errors.course_id && errors.course_id.type === "required" && <span className="validation-error">This is required</span>}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <hr className='mt-3' />
                                    <div className="form-group">
                                        <label><b>Step2. Parent information</b></label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Parent's First Name</label>
                                        <input type="text" className="form-control" name="venue_name"
                                            placeholder='Enter Parent First Name'
                                            {...register('p_first_name')}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Parent's Last  Name</label>
                                        <input type="text" className="form-control" name="venue_name"
                                            placeholder='Enter Parent Last  Name'
                                            {...register('p_last_name')}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Telephone</label>
                                        <input type="text" className="form-control" name="offine_address"
                                            placeholder='Enter Telephone No.'
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            {...register('p_mobile_phone')}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">

                                        <label>Mother's Mobile Phone</label>
                                        <input type="text" className="form-control"
                                            placeholder='Enter Mother Mobile  NO.'
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            name="offine_seats" {...register('mother_mobilenumber')} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">

                                        <label>Father's Mobile Phone</label>
                                        <input type="text" className="form-control"
                                            placeholder='Enter Father Mobile No.'
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            name="online_url"  {...register('father_mobilenumber')} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Address</label>
                                        <input type="text" className="form-control"
                                            placeholder='Enter Address'
                                            name="online_seats"  {...register('address')} />
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Country (*)</label>
                                        <select className="form-select form-control" {...register('country', { required: true })}>
                                            <option value="">Choose Country</option>
                                            {
                                                countries?.length > 0 ?
                                                    countries && countries?.map((elem) => {
                                                        return (
                                                            <option value={elem?.id}>{elem?.name}</option>
                                                        )
                                                    })
                                                    :
                                                    <option>No result</option>
                                            }
                                        </select>
                                        {errors.country && errors.country.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>
                                </div> */}
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>State</label>
                                        <select className="form-select form-control" {...register('state')}>
                                            <option value="">Choose State</option>
                                            <option value="New South Wales">New South Wales</option>
                                            <option value="Australian Capital Territory">Australian Capital Territory</option>
                                            <option value="Northern Territory">Northern Territory</option>
                                            <option value="Queensland">Queensland</option>
                                            <option value="South Australia">South Australia</option>
                                            <option value="Tasmania">Tasmania</option>
                                            <option value="Victoria">Victoria</option>
                                            <option value="Western Australia">Western Australia</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>City *</label>
                                        <input type='text' className="form-control"
                                            placeholder='Enter City'
                                            name="description" {...register('city', { required: true })} />
                                        {errors.city && errors.city.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Post Code:</label>
                                        <input type='text' className="form-control"
                                            placeholder='Enter Post Code'
                                            name="description" {...register('post_code')} />
                                    </div>
                                </div> */}
                               
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Parent's Email: *</label>
                                        <input type='text' className="form-control"
                                            placeholder='Enter Parent Email'
                                            name="p_email" {...register('p_email')} />
                                        {/* {errors.p_email && errors.p_email.type === "required" && <span className="validation-error">This is required</span>} */}
                                        {errors.p_email && errors.p_email.type === "pattern" && <span className="validation-error">Must be a valid email</span>}
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Confirm Parent's Email: *</label>
                                        <input type='text' className="form-control"
                                        placeholder='Enter Confirm Parent Email'
                                        name="description" {...register('pc_email', { required: true,validate: value =>
                                                    value === p_email.current || "This email do not match!" })} />
                                        {errors.pc_email && errors.pc_email.type === "required" && <span className="validation-error">This is required</span>}
                                        {errors.pc_email && errors.pc_email.type === "pattern" && <span className="validation-error">Must be a valid email</span>}
                                        {errors.pc_email && <p className="validation-error">{errors.pc_email.message}</p>}
                                    </div>
                                </div> */}
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Sibling</label>
                                        <input type='text' className="form-control"
                                            placeholder='Enter Sibling'
                                            name="description" {...register('sibling')} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Sibling Grade</label>
                                        <select className="form-control" name="grade" {...register('sibling_grade')}>
                                            <option selected>Choose Grade</option>
                                            <option value="1">onni</option>
                                            <option value="2">Year 1</option>
                                        </select>
                                    </div>
                                </div>
                                {/* <div className="col-md-12">
                                    <hr className='mt-3' />
                                    <div className="form-group">
                                        <label><b>Step3. Choose a user name and password</b></label>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="form-group">

                                        <label>User Name(Id): *</label>
                                        <input type="text" className="form-control" name="name"
                                            {...register('email', { required: true })}
                                        />
                                        {errors.email && errors.email.type === "required" && <span className="validation-error">This is required</span>}
                                    </div>
                                </div> */}
                                {/* <div className="col-md-4">
                                    <div className="form-group">
                                        <div className='ui button mt-4' data-bs-toggle="modal" data-bs-target="#staticBackdrop">Check Availability of this ID</div>
                                    </div>
                                </div> */}
                                {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Password: *</label>
                                        <input type="password" className="form-control" name="password"
                                            {...register("password", {
                                                required: true,
                                                pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/
                                            })}
                                        />
                                        {errors.password && errors.password.type === "required" && <span className="validation-error">This is required</span>}
                                        {errors.password && errors.password.type === "pattern" && <span className="validation-error">At least 6 chars long,digit,upper case,lower case</span>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Confirm Password: *</label>
                                        <input type="password" className="form-control" name="confirmPassword"
                                            {...register("confirmPassword", {
                                                required: true,
                                                validate: value =>
                                                    value === password.current || "The passwords do not match"
                                            })}
                                        />
                                        {errors.confirmPassword && errors.confirmPassword.type === "required" && <span className="validation-error">This is required</span>}
                                        {errors.confirmPassword && <p className="validation-error">{errors.confirmPassword.message}</p>}
                                    </div>
                                </div> */}
                                <br />
                            </div>
                            <div className="modal-footer">
                                <Button type='submit' className="btn btn-primary">Save</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditMember;



