import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataLoader from '../../../Utils/DataLoader';
import { grade_destroy, grades_all_data } from '../../../apis/Grade'
import EditGrade from './Edit'
import CustomTable from '../CustomTable';

export const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: false,
    },
    {
        name: 'Grade',
        selector: 'name',
        sortable: false,
    },
    {
        name: 'Status',
        selector: 'status',
        sortable: false,
    },

    {
        name: 'Action',
        selector: 'action',
        sortable: false,
    }
];





const AllGrade = () => {
    const [grade, setGrade] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [status, setStatus] = useState("")

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [duplicateItemId, setDuplicateItemId] = useState(null);
  
    useEffect(() => {

        async function get_data() {
            const result = await grades_all_data();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, name, status } = elem;
                console.log("---------=>",id,name,status);
                return {
                    no: `#${index + 1}`,
                    name: name == 0 ? `Year ${name}(kindy)` : `Year ${name}`,
                    //name:name,
                    status: status == 0 ? 'Inactive' : 'Active',
                    action:<div data-tag="allowRowEvents">
                    <button onClick={() => {
                        setDuplicateItemId(id); setIsModalOpen(true);
                        setStatus('grade')
                    }}
                        className="dlt-btn" > <i class="fa fa-pencil" aria-hidden="true"></i></button>
                        <button className="dlt-btn" onClick={() => destroy(id)}>     <i class="fa fa-trash" aria-hidden="true"></i></button></div>
                };
            });
            setGrade(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = window.confirm("Are you sure you want to delete?");
            if (result) {
                grade_destroy(id);
            }
            get_data();
        }
        get_data();
    }, [isModalOpen]);
    return (
        <>
                <div className="modal-content">
      
      <div className="tab-content" id="pills-tabContent">
          <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              <div className="right-nav" id="dash-event">
                  <div className='row'>
                      <div className='col-md-4'>
                          <h4 className='modal-title'>Grade List</h4>
                      </div>
                      <div className='col-md-8'>
                          <Link className='ui button float-end' to="/admin/general-setting/grade/add">Add Grades
                          </Link>
                      </div>
                  </div>
                  {
                      showResults ?
                          <CustomTable
                              columns={columns}
                              data={grade}
                          />
                          :
                          <DataLoader />
                  }
              </div>
          </div>
      </div>
  </div>
  <EditGrade duplicateItemId={duplicateItemId} isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen} status={status} setStatus={setStatus} />
        </>

       

    )
}
export default AllGrade;



