import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "semantic-ui-react";
import {
  enrolled_course_status_save,
  get_eligible_courses,
  get_enrolled_course_status,
} from "../../../branches/api/branches/Branch";
import {student_enroll_list} from "../../../apis/studentManagement/StudentManagement";
import { useNavigate, useParams } from "react-router-dom";
import DataLoader from "../../../Utils/DataLoader";
const EnrolmentStatus = ({onUpdate,subject}) => {
  console?.log(subject)
  const { id } = useParams();
  const { handleSubmit } = useForm();
  const [termData, setTermData] = useState([]);
  const [selectedTermsByCourse, setSelectedTermsByCourse] = useState({});
  const [termSelectedUnitsByCourse, setTermSelectedUnitsByCourse] = useState(
    {}
  );
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [eligibleCourses, setEligibleCourses] = useState([]);
  const [showResults, setShowResults] = useState(false);
  console.log("======================>", subject?.length);
  const navigate = useNavigate();

  async function get_enroll_data() {
   
    const result = await get_enrolled_course_status(id);
    const course_selected_data = result?.data || [];
    const result2 = await get_eligible_courses(id);
    setEligibleCourses(result2?.data || []);
    const termsByCourseId = {};
    course_selected_data.forEach((course) => {
      setShowResults(true)
      const courseId = course.course_id;
      if (!termsByCourseId[courseId]) {
        termsByCourseId[courseId] = {
          courseName: course.course_name,
          courseTypeName: course.course_type_name,
          terms: [],
        };
      }
      course.terms.forEach((term) => {
        termsByCourseId[courseId].terms.push({
          termName: term.term_name,
          subjects: term.subjects,
        });
      });
    });
    setTermData(termsByCourseId);
    const preSelectedTermsByCourse = {};
    const preSelectedUnitsByCourse = {};
    Object.keys(termsByCourseId).forEach((courseId) => {
      const courseTerms = termsByCourseId[courseId].terms;
      preSelectedTermsByCourse[courseId] = [];
      preSelectedUnitsByCourse[courseId] = [];

      courseTerms.forEach((term, termIndex) => {
        let hasUnitsWithStatus1 = false;
        term.subjects.forEach((subject) => {
          subject.units.forEach((unit) => {
            if (unit.status === 1) {
              hasUnitsWithStatus1 = true;
            }
          });
        });

        if (hasUnitsWithStatus1) {
          preSelectedTermsByCourse[courseId].push(term.termName);
        }

        term.subjects.forEach((subject, subjectIndex) => {
          preSelectedUnitsByCourse[courseId][termIndex] = [];
          subject.units.forEach((unit) => {
            if (unit.status === 1) {
              preSelectedUnitsByCourse[courseId][termIndex].push(unit.unit_id);
            }
          });
        });
      });
    });

    setSelectedTermsByCourse(preSelectedTermsByCourse);
    setTermSelectedUnitsByCourse(preSelectedUnitsByCourse);
  }

  useEffect(() => {
    if (subject && subject.length > 0) {
      get_enroll_data();
    }
  }, [subject]);

  // const handleCheckboxChange = (courseId, termName) => {
  //   setSelectedTermsByCourse((prevSelectedTerms) => {
  //     const updatedSelectedTerms = { ...prevSelectedTerms };
  //     if (!updatedSelectedTerms[courseId]) {
  //       updatedSelectedTerms[courseId] = [];
  //     }
  //     const termIndex = updatedSelectedTerms[courseId].indexOf(termName);
  //     if (termIndex === -1) {
  //       updatedSelectedTerms[courseId].push(termName);
  //     } else {
  //       updatedSelectedTerms[courseId].splice(termIndex, 1);
  //     }
  //     return updatedSelectedTerms;
  //   });
  // };

  const handleCheckboxChange = (courseId, termName, termIndex) => {
    setSelectedTermsByCourse((prevSelectedTerms) => {
      const updatedSelectedTerms = { ...prevSelectedTerms };
  
      if (!updatedSelectedTerms[courseId]) {
        updatedSelectedTerms[courseId] = [];
      }
  
      const termIndexInSelectedTerms = updatedSelectedTerms[courseId].indexOf(termName);
      
      // Check if term is already selected
      if (termIndexInSelectedTerms === -1) {
        // If not selected, add the term
        updatedSelectedTerms[courseId].push(termName);
  
        // Automatically check all units for this term
        setTermSelectedUnitsByCourse((prevSelectedUnits) => {
          const updatedUnits = { ...prevSelectedUnits };
          if (!updatedUnits[courseId]) {
            updatedUnits[courseId] = [];
          }
  
          if (!updatedUnits[courseId][termIndex]) {
            updatedUnits[courseId][termIndex] = [];
          }
  
          // Push all unit IDs for this term
          const allUnitsForTerm = [];
          termData[courseId].terms[termIndex].subjects.forEach((subject) => {
            subject.units.forEach((unit) => {
              allUnitsForTerm.push(unit.unit_id);
            });
          });
  
          updatedUnits[courseId][termIndex] = allUnitsForTerm;
          return updatedUnits;
        });
      } else {
        // If already selected, remove the term and its associated units
        updatedSelectedTerms[courseId].splice(termIndexInSelectedTerms, 1);
  
        // Uncheck all units for this term
        setTermSelectedUnitsByCourse((prevSelectedUnits) => {
          const updatedUnits = { ...prevSelectedUnits };
          if (updatedUnits[courseId] && updatedUnits[courseId][termIndex]) {
            updatedUnits[courseId][termIndex] = [];
          }
          return updatedUnits;
        });
      }
  
      return updatedSelectedTerms;
    });
  };
  
  const handleLectureInputChange = (
    e,
    courseId,
    termIndex,
    subjectIndex,
    unitId
  ) => {
    setTermSelectedUnitsByCourse((prevSelectedUnits) => {
      const updatedUnits = { ...prevSelectedUnits };
      // Initialize arrays if they do not exist
      if (!updatedUnits[courseId]) {
        updatedUnits[courseId] = [];
      }
      if (!updatedUnits[courseId][termIndex]) {
        updatedUnits[courseId][termIndex] = [];
      }
  
      // Check if the unit is already selected
      const isUnitSelected = updatedUnits[courseId][termIndex]?.includes(unitId);
  
      if (isUnitSelected) {
        // If unit is selected, uncheck it (remove from array)
        updatedUnits[courseId][termIndex] = updatedUnits[courseId][termIndex].filter(
          (id) => id !== unitId
        );
      } else {
        // If unit is not selected, check it (add to array)
        updatedUnits[courseId][termIndex].push(unitId);
      }
  
      // If no units are selected for a term, remove the term from the selected list
      const allUnitsUnchecked = updatedUnits[courseId][termIndex].length === 0;
      if (allUnitsUnchecked) {
        setSelectedTermsByCourse((prevSelectedTerms) => {
          const updatedTerms = { ...prevSelectedTerms };
          updatedTerms[courseId] = updatedTerms[courseId].filter(
            (term) => term !== termData[courseId].terms[termIndex].termName
          );
          return updatedTerms;
        });
      } else {
        // If any units are selected, ensure the term is selected
        setSelectedTermsByCourse((prevSelectedTerms) => {
          const updatedTerms = { ...prevSelectedTerms };
          if (!updatedTerms[courseId]) {
            updatedTerms[courseId] = [];
          }
          if (
            !updatedTerms[courseId].includes(
              termData[courseId].terms[termIndex].termName
            )
          ) {
            updatedTerms[courseId].push(termData[courseId].terms[termIndex].termName);
          }
          return updatedTerms;
        });
      }
  
      return updatedUnits;
    });
  };
  

  const onSubmit = async (data) => {
    setShowResults(false)
    const submissionData = [];

    Object.keys(termData).forEach((courseId) => {
      const courseTerms = termData[courseId].terms;

      courseTerms.forEach((term, termIndex) => {
        if (selectedTermsByCourse[courseId]?.includes(term.termName)) {
          term.subjects.forEach((subject, subjectIndex) => {
            subject.units.forEach((unit) => {
              if (
                termSelectedUnitsByCourse[courseId]?.[termIndex]?.includes(
                  unit.unit_id
                )
              ) {
                submissionData.push({
                  course_id: courseId,
                  termName: term.termName,
                  subject_id: subject.subject_id,
                  unit_id: unit.unit_id,
                });
              }
            });
          });
        }
      });
    });

    const payload = {
      student_id: id,
      courses: submissionData,
      newcourse: selectedCourse,
    };

    console.log("Submission Payload", payload);

    const result = await enrolled_course_status_save(payload);
    if (result?.status) {
      setShowResults(true)
      // navigate("/admin/students/members");
      get_enroll_data();
      const ids={id:id}
      const getData=async()=>{
        const data=await student_enroll_list(ids)
        console?.log(data)
         onUpdate(data?.data);
      }
      getData();
      // window.location.reload();
    }
  };
  console.log('status',id)
  return (
    <div>
      { 
      subject?.length >0?
        showResults?
        <section className="courses-sec">
        <div className="modal-header">
          <h4 className="modal-title">Enrolment Status</h4>
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="courses-content">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {Object.keys(termData).map((courseId, index) => (
                      <li
                        key={courseId}
                        className="nav-item"
                        role="presentation"
                      >
                        <button
                          className={`nav-link ${index === 0 ? "active" : ""}`}
                          id={`course-tab-${courseId}`}
                          data-bs-toggle="tab"
                          data-bs-target={`#course-${courseId}`}
                          type="button"
                          role="tab"
                          aria-controls={`course-${courseId}`}
                          aria-selected={index === 0}
                        >
                          Course ID: {courseId}
                        </button>
                      </li>
                    ))}
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Add Course
                      </a>
                      <ul className="dropdown-menu">
                        {eligibleCourses.map((course) => (
                          <>
                            <li key={course.id}>
                              <a
                                className="dropdown-item"
                                // href="#"
                                onClick={() => setSelectedCourse(course.id)}
                              >
                                {course.name}
                              </a>
                            </li>
                          </>
                        ))}
                        <li>
                          <a className="dropdown-item">None</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    {Object.keys(termData).map((courseId) => (
                      <div
                        key={courseId}
                        className={`tab-pane fade ${
                          courseId === Object.keys(termData)[0]
                            ? "show active"
                            : ""
                        }`}
                        id={`course-${courseId}`}
                        role="tabpanel"
                        aria-labelledby={`course-tab-${courseId}`}
                      >
                        <h4>Name: {termData[courseId].courseName}</h4>
                        <h4>
                          Course Type: {termData[courseId].courseTypeName}
                        </h4>

                        <div className="accordion" id="accordionExample">
                          {termData[courseId].terms.map((term, termIndex) => (
                            <div key={termIndex} className="accordion-item">
                              <div key={termIndex} className="terms-sec">
                                <h2
                                  className="accordion-header"
                                  id={`heading-${termIndex}`}
                                >
                                  <button
                                    className="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse-${termIndex}`}
                                    aria-expanded={
                                      termIndex === 0 ? true : false
                                    }
                                    aria-controls={`collapse-${termIndex}`}
                                  >
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="term"
                                        checked={
                                          selectedTermsByCourse[
                                            courseId
                                          ]?.includes(term.termName) || false
                                        }
                                        onChange={() =>
                                          handleCheckboxChange(
                                            courseId,
                                            term.termName,
                                            termIndex
                                          )
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexCheckChecked"
                                      >
                                        <b>Term: {term.termName}</b>
                                      </label>
                                    </div>{" "}
                                  </button>
                                </h2>
                                <div
                                  id={`collapse-${termIndex}`}
                                  className={`accordion-collapse collapse ${
                                    termIndex === 0 ? "show" : ""
                                  }`}
                                  aria-labelledby={`heading-${termIndex}`}
                                  data-bs-parent="#accordionExample"
                                >
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th scope="col">Subject</th>
                                        <th scope="col">Units</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {term.subjects.map(
                                        (subjectObj, subjectIndex) => (
                                          <tr key={subjectIndex}>
                                            <td>{subjectObj.subject_name}</td>
                                            <td>
                                              {subjectObj.units.map(
                                                (unit, unitIndex) => {
                                                  return(
                                                  <div
                                                    key={unitIndex}
                                                    className="form-check"
                                                  >
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      checked={
                                                        termSelectedUnitsByCourse[
                                                          courseId
                                                        ]?.[
                                                          termIndex
                                                        ]?.includes(
                                                          unit.unit_id
                                                        ) || false
                                                      }
                                                      name="units"
                                                      onChange={(e) =>
                                                        handleLectureInputChange(
                                                          e,
                                                          courseId,
                                                          termIndex,
                                                          subjectIndex,
                                                          unit.unit_id
                                                        )
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor="flexCheckDefault"
                                                    >
                                                      {unit.unit_name}{" "}
                                                      {unit.unit_id}
                                                    </label>
                                                  </div>
                                                )}
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Button
            type="submit"
            disabled={subject?.length > 0 ? false : true}
          >
            Save
          </Button>
        </Form>
      </section>
        :<DataLoader />
      :'No Enrolment Status Data'}
    
    </div>
  );
};

export default EnrolmentStatus;
