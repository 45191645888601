import React, { useEffect, useState } from 'react';
import { useForm,Controller } from "react-hook-form";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { category_update, category_edit, add_category } from '../../../apis/category';
import {subjectList} from '../teachers/Data';
import Select from 'react-select';
import { Button } from 'semantic-ui-react';
const AddCategory = () => {
    const [subjectlist, setSubjectList] = useState([]);
    const [selectedSubOptions, setSelectedSubOptions] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {
        register,
        control,
        handleSubmit,
        formState: { errors }
    } = useForm();
    const navigate = useNavigate();
    const handleSubChange = (selectedSubOptions) => {
        setSelectedSubOptions(selectedSubOptions);
    };

    useEffect(() => {
        async function get_all_data() {
            setSubjectList(await subjectList());
        }
        get_all_data();
    }, []);

    const onSubmit = async (data) => {
        if (isSubmitting) return; 
        setIsSubmitting(true);
        data.subjectJson=selectedSubOptions
        let result = await add_category(data);
        if (result.status) {
            return navigate('/admin/general-setting/category/all');
        }
        setIsSubmitting(false);

    }

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Add Course Type </h4>
                    <Link className='edit-btn' to={`/admin/general-setting/category/all`} onClick={(e) => {
    if (window.confirm("Are you sure you want to leave this page?")) {
    } else {
      e.preventDefault(); 
    }
  }}><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
                </div>
                <div className="modal-body">
                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Name *</label>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Course Type'
                                        {...register('category_name', { required: true })}
                                    />
                                {errors.category_name && errors.category_name.type === "required" && <span className="validation-error">This is required</span>}
                                </div>
                            </div>
                            {/* <div className="col-md-6">
                                <div className="form-group">
                                    <label>Price</label>
                                    <input type="text" className="form-control"
                                        placeholder='ENTER PRICE'
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        {...register('price', { required: true })}
                                    />
                                </div>
                                {errors.price && errors.price.type === "required" && <span className="validation-error">This is required</span>}
                            </div> */}
                              <div className="col-md-6">
                                <div className="form-group">
                                    <label>Subject</label>
                                    <Controller
                                        name="subject"
                                        control={control}
                                        defaultValue={[]}
                                        render={({ field }) => (
                                            <Select
                                                options={subjectlist}
                                                // className="form-control"
                                                placeholder='Choose Subject'
                                                isMulti
                                                {...field}
                                                value={selectedSubOptions}
                                                onChange={(otopn) => handleSubChange(otopn)}
                                            />                           
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn button" disabled={isSubmitting}>Save</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

const EditCategory = () => {
    const [subjectlist, setSubjectList] = useState([]);
    const [selectedSubOptions, setSelectedSubOptions] = useState([]);
    const {
        register,
        control,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();
    const id = useParams();
    const handleSubChange = (selectedSubOptions) => {
        setSelectedSubOptions(selectedSubOptions);
    };
    const onSubmit = async (data) => {
        if (isSubmitting) return; 
        setIsSubmitting(true);

        data.subjectJson=selectedSubOptions
        let result = await category_update(id, data);
        if (result.status) {
            return navigate('/admin/general-setting/category/all');
        }
        setIsSubmitting(false);
    }
    const fetchData = async () => {
        let res = await category_edit(id);
        setSubjectList(await subjectList());
        setValue('category_name', res?.data?.category_name);
        const subject = JSON.parse(res?.data?.subjectJson);
        setSelectedSubOptions(subject)
        
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Modify Course Type </h4>
                    <Link className='edit-btn' to={`/admin/general-setting/category/all`} onClick={(e) => {
    if (window.confirm("Are you sure you want to leave this page?")) {
    } else {
      e.preventDefault(); 
    }
  }}><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
                </div>
                <div className="modal-body">
                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Name *</label>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Course Type'
                                        {...register('category_name', { required: true })}
                                    />
                                {errors.category_name && errors.category_name.type === "required" && <span className="validation-error">This is required</span>}
                                </div>
                            </div>
                            {/* <div className="col-md-6">
                                <div className="form-group">
                                    <label>Price</label>
                                    <input type="text" className="form-control"
                                        placeholder='ENTER PRICE'
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        {...register('price', { required: true })}
                                    />
                                </div>
                                {errors.price && errors.price.type === "required" && <span className="validation-error">This is required</span>}
                            </div> */}
                                <div className="col-md-6">
                                <div className="form-group">
                                    <label>Subject</label>
                                    <Controller
                                        name="subject"
                                        control={control}
                                        defaultValue={[]}
                                        render={({ field }) => (
                                            <Select
                                                options={subjectlist}
                                                // className="form-control"
                                                placeholder='Choose Subject'
                                                isMulti
                                                {...field}
                                                value={selectedSubOptions}
                                                onChange={(otopn) => handleSubChange(otopn)}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn button" disabled={isSubmitting}>Save</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export { AddCategory, EditCategory };
