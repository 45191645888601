import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { question_edit, question_update } from '../../../../apis/testManagement/Question'
import { grade_all } from '../../../../apis/Grade';
import { get_category } from '../../../../apis/category';
import { get_terms } from '../../../../apis/Common.jsx';
import { subject_all } from '../../../../apis/ClassManagement/Subjects.jsx';
const EditQuestionaire = () => {
    const [gradelist, setGradeList] = useState([]);
    const [courseType, setCourseType] = useState([]);
    const [termsList, setTermList] = useState([]);
    const [questionData, setQuestionData] = useState({})
    const [question, setQuestion] = useState([]);
    const [subjects, setSubject] = useState([]);
    const [selectVal, setSelectVal] = useState({ time: '', course_type: '', question_name: '', grade: '', term: '', subject: '' });
    const [inputList, setInputList] = useState([{

        test_type: '',
        question: '',
        answer: '',
        image: '',
        passage: '',
        option1: '',
        option2: '',
        option3: '',
        option4: ''
    }
    ]);
    const id = useParams();
    const navigate = useNavigate();
    const onSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('time', selectVal.time || questionData?.time);
        formData.append('course_type_id', selectVal.course_type || questionData?.course_type_id);
        formData.append('grade_id', selectVal.grade || questionData?.grade_id);
        formData.append('term_id', selectVal.term || questionData?.term_id);
        formData.append('subject_id', selectVal.subject || questionData?.subject_id);
        formData.append('question_name', selectVal.question_name || questionData?.question_name);
        question && question?.forEach((item, index) => {
            for (const key in item) {
                formData.append(`inputList[${index}][${key}]`, item[key]);
            }
        });
        const result = await question_update(id.id, formData);
        if (result.status) {
            return navigate('/admin/test-management/questionaire-all');
        }
    }
    const handleQuestionChange = (index, fieldname, value) => {
        const updatedQuestions = [...question];
        updatedQuestions[index] = { ...updatedQuestions[index], [fieldname]: value };
        setQuestion(updatedQuestions);
        setInputList(updatedQuestions);
    };
    const handleImgChange = (e, index) => {
        const { name, files } = e.target;
        const list = [...question];
        list[index][name] = files[0];
        setQuestion(list);
    };
    async function get_qestion_data() {
        const result = await question_edit(id.id);
        setQuestionData(result?.data)
        setQuestion(JSON.parse(result?.data?.question_data))

    }
    const fetchData = async () => {
        const gradeList = await grade_all();
        const courseType = await get_category();
        const terms = await get_terms();
        const result = await subject_all();
        setGradeList(gradeList?.data);
        setCourseType(courseType?.data);
        setTermList(terms?.data);
        setSubject(result?.data);
    };
    useEffect(() => {
        fetchData();
        get_qestion_data();
    }, []);
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Edit Questionnaire</h4>
                    <Link className='edit-btn' to={`/admin/test-management/questionaire-all`} onClick={(e) => {
    if (window.confirm("Are you sure you want to leave this page?")) {
    } else {
      e.preventDefault(); 
    }
  }}><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={onSubmit} encType="multipart/form-data" >
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Course Type</label>
                                    <select className="form-select form-control"
                                        value={selectVal.course_type || questionData?.course_type_id}
                                        onChange={(e) => setSelectVal({ ...selectVal, course_type: e.target.value })}>
                                        <option value="">CHOOSE COURSE TYPE</option>
                                        {
                                            courseType && courseType?.map((elem) => {
                                                return (
                                                    <option value={elem?.id}>{elem?.category_name}</option>
                                                )
                                            })

                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Grade</label>
                                    <select className="form-select form-control"
                                        value={selectVal.grade || questionData?.grade_id}
                                        onChange={(e) => setSelectVal({ ...selectVal, grade: e.target.value })} >
                                        <option value="">Choose Grade</option>
                                        {
                                            gradelist?.length > 0 ?
                                                gradelist && gradelist?.map((elem) => {
                                                    return (
                                                        <option value={elem?.id}>{elem?.name}</option>
                                                    )
                                                })
                                                :
                                                <option>No result</option>
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Term</label>
                                    <select className="form-select form-control" name="term"
                                        value={selectVal.term || questionData?.term_id}
                                        onChange={(e) => setSelectVal({ ...selectVal, term: e.target.value })}>
                                        <option value="">Choose Term </option>
                                        {
                                            termsList && termsList?.map((elem) => {
                                                return (
                                                    <option value={elem?.id}>{elem?.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Time</label>
                                    <select className="form-select form-control" value={selectVal.time || questionData?.time} onChange={(e) => setSelectVal({ ...selectVal, time: e.target.value })}>
                                        <option value="">CHOOSE TIME</option>
                                        <option value="5">5 mint</option>
                                        <option value="10">10 mint</option>
                                        <option value="15">15 mint</option>
                                        <option value="20">20 mint</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4" >
                                <div className="form-group">
                                    <label>Questionnaire Name</label>
                                    <input type="text" className="form-control"
                                        name="question"
                                        placeholder='ENTER QUESTIONNAIRE'
                                        value={selectVal.question_name || questionData?.question_name}
                                        onChange={(e) => setSelectVal({ ...selectVal, question_name: e.target.value })} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Subject</label>
                                    <select className="form-control form-select"
                                        value={selectVal.subject || questionData?.subject_id}
                                        onChange={(e) => setSelectVal({ ...selectVal, subject: e.target.value })} required>
                                        <option value="">CHOOSE SUBJECT</option>
                                        {
                                            subjects && subjects?.map((elem) => {
                                                return <option value={elem?.id}>{elem?.subject}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            {
                                question && question?.map((ele, index, data) => {

                                    return (
                                        <>
                                            <div className="col-md-5" key={index}>
                                                <div className="form-group">
                                                    <label>Questionnaire Type</label>
                                                    <select className="form-select form-control" name="test_type" value={ele.test_type}
                                                        disabled >
                                                        <option value="">CHOOSE QUESTIONNAIRE TYPE</option>
                                                        <option value='objective'>Objective</option>
                                                        <option value='subjective'>Subjective</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {ele?.test_type === 'subjective' ?
                                                <>
                                                    <div className="col-md-5" >
                                                        <div className="form-group">
                                                            <label>Question</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="question"
                                                                placeholder="ENTER QUESTION"
                                                                value={ele.question}
                                                                onChange={(e) => handleQuestionChange(index, 'question', e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>Answer</label>
                                                            <input
                                                                type="text"
                                                                placeholder="ENTER ANSWER"
                                                                className="form-control"
                                                                name="answer"
                                                                value={ele.answer}
                                                                onChange={(e) => handleQuestionChange(index, 'answer', e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>document upload</label>
                                                            <input type="file" className="form-control" name="image"
                                                                // value={x.image}
                                                                onChange={(e) => handleImgChange(e, index)} />
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="col-md-5" >
                                                        <div className="form-group">
                                                            <label>Question</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="question"
                                                                placeholder="ENTER QUESTION"
                                                                value={ele.question}
                                                                onChange={(e) => handleQuestionChange(index, 'question', e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>Answer</label>
                                                            <input
                                                                type="text"
                                                                placeholder="ENTER ANSWER"
                                                                className="form-control"
                                                                name="answer"
                                                                value={ele.answer}
                                                                onChange={(e) => handleQuestionChange(index, 'answer', e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>document upload</label>
                                                            <input type="file" className="form-control" name="image"
                                                                // value={x.image}
                                                                onChange={(e) => handleImgChange(e, index)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>Image File</label>
                                                            <input type="file" className="form-control" name="image"
                                                                accept="image/png,image/jpeg,image/jpg"
                                                                // value={x.image}
                                                                onChange={(e) => handleImgChange(e, index)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className='row'>
                                                            <div className='col-md-2'>
                                                                <div className="form-group">
                                                                    <label>Option 1</label>
                                                                    <input type="text" className="form-control" name="option1"
                                                                        value={ele.option1}
                                                                        onChange={(e) => handleQuestionChange(index, 'option1', e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <div className="form-group">
                                                                    <label>Option 2</label>
                                                                    <input type="text" className="form-control" name="option2"
                                                                        value={ele.option2}
                                                                        onChange={(e) => handleQuestionChange(index, 'option2', e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <div className="form-group">
                                                                    <label>Option 3</label>
                                                                    <input type="text" className="form-control" name="option3"
                                                                        value={ele.option3}
                                                                        onChange={(e) => handleQuestionChange(index, 'option3', e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <div className="form-group">
                                                                    <label>Option 4</label>
                                                                    <input type="text" className="form-control" name="option4"
                                                                        value={ele.option4}
                                                                        onChange={(e) => handleQuestionChange(index, 'option4', e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <div className="form-group">
                                                                    <label>Option 5</label>
                                                                    <input type="text" className="form-control" name="option5"
                                                                        value={ele.option5}
                                                                        onChange={(e) => handleQuestionChange(index, 'option5', e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="row align-items-center">
                                                        <div className="col-md-10">
                                                            <div className="form-group text_anitem">
                                                                <label>Passage</label>
                                                                <textarea className="form-control" name="passage"
                                                                    placeholder='ENTER PASSAGE'
                                                                    value={ele.passage}
                                                                    onChange={(e) => handleQuestionChange(index, 'passage', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-md-2">
                                                    <div className='add_btn'>
                                                        {
                                                            inputList.length !== 1 && (
                                                                <button className='btn-warning' onClick={() => handleRemoveClick(i)}>Remove</button>
                                                            )
                                                        }
                                                        {
                                                            inputList.length - 1 === i && (
                                                                <button className='btn-success' onClick={() => handleAddClick()}>Add</button>
                                                            )
                                                        }
                                                    </div>
                                                </div> */}
                                                    </div>
                                                </>

                                            }

                                            <hr />
                                        </>
                                    )
                                })
                            }
                        </div>


                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Save</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default EditQuestionaire;
