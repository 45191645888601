import React, { useEffect, useState } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { teacher_edit, teacher_update } from '../../../apis/Teachers';
import { branchList , subjectList, gradeList} from './Data';
import {random_generate_string} from '../../../Utils/Utils'
import Select from 'react-select';

const EditTeacher = () => {
    const [branchlist, setBranchList] = useState([]);
    const [gradelist, setGradeList] = useState([]);
    const [subjectlist, setSubjectList] = useState([]);
    const [selectedSubOptions, setSelectedSubOptions] = useState([]);
    const [selectedGradOptions, setSelectedGradOptions] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [randomText, setRandomText] = useState('r40e012');
    const {
        register,
        control,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({ mode: "onChange" });

    const navigate = useNavigate();
    const id = useParams();
    const fetchData = async () => 
    {
        let res = await teacher_edit(id);
        setValue('teacher_id',res?.data?.teacher_id);
        setValue('teacher_name',res?.data?.teacher_name);
        setValue('mobile',res?.data?.mobile);
        setValue('email',res?.data?.email);
        setValue('password',res?.data?.password);
        setTimeout(() => {
            setValue('branch',res?.data?.branch);
        },1000);
        setValue('status',res?.data?.t_status?.toString());
        console.log('res?.data?.t_status', typeof res?.data?.t_status);
        const gradeArray = JSON.parse(res?.data?.grade_id);
        const subArray = JSON.parse(res?.data?.subject_id);
        const transformedOptions = gradeArray?.map(item => ({ value: item?.grade_id, label: item.label }));
        const subjectformedOptions = subArray?.map(item => ({ value: item?.subject_id, label: item.label }));
        setSelectedSubOptions(subjectformedOptions);
        setSelectedGradOptions(transformedOptions);
    }
    const text_relaod = () => {
        setRandomText(random_generate_string(8));
    }

    const onSubmit = async (data) => {
        if (isSubmitting) return; 
        setIsSubmitting(true);
        const formData = {branch:data.branch, teacher_id:data.teacher_id, teacher_name:data.teacher_name,mobile:data.mobile, status:data.status,grade:selectedGradOptions, subject:selectedSubOptions,password:data.password,email:data.email}
        let result = await teacher_update(id, formData);
        if (result.status) {
            return navigate('/admin/teachers-management/all');
        }
        setIsSubmitting(false);
    }
    const handleGradChange = (selectedGradOptions) => {
        setSelectedGradOptions(selectedGradOptions);
    };

    const handleSubChange = (selectedSubOptions) => {
        setSelectedSubOptions(selectedSubOptions);
    };

    useEffect(() => {
        async function get_all_data() {
            setBranchList(await branchList());
            setGradeList(await gradeList());
            setSubjectList(await subjectList());
        }
        get_all_data();
        fetchData();
    }, []);

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Teacher Detail</h4>
                <Link className='edit-btn' to={`/admin/teachers-management/all`} onClick={(e) => {
    if (window.confirm("Are you sure you want to leave this page?")) {
    } else {
      e.preventDefault(); 
    }
  }}><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Branch Name</label>
                                    <select className="form-select form-control" {...register("branch")} >
                                        <option value="">Choose Branch *</option>
                                        { branchlist?.length > 0 ?
                                            branchlist && branchlist?.map((elem) => (
                                            <option key={elem} value={elem?.id}>{elem?.branch_name}</option>
                                        ))
                                        : <option>No result</option>
                                    }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Teacher ID</label>
                                    <input type="text" className="form-control" {...register('teacher_id')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Teacher Name *</label>
                                    <input type="text" className="form-control"
                                      placeholder='Enter Teacher Name'
                                    {...register('teacher_name')}
                                     />
                                </div>
                            </div>
                            <div className="col-md-6">
                      <div className="form-group">
                        <label>mobile Number *</label>
                        <div className="input-group branch-prepend">
                            <div className="input-group-prepend">
                              <div className="input-group-text">+61</div>
                            </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="000 000 (00)"
                          {...register("mobile", {
                            required: "Mobile number  is required",
                            pattern: { value: /^\d{8}$/, message: "mobile number must be 8 digits" },
                            validate: (value) =>
                              /^\d+$/.test(value) || "Only numbers are allowed",
                          })}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          maxLength={8}
                        />
                        </div>
                      {errors.mobile && (
                      <span className="validation-error">
                        {errors.mobile.message}
                      </span>
                    )}
                      </div>
                    </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Grade</label>
                                    <Controller
                                        name="grade"
                                        control={control}
                                        defaultValue={[gradelist[0]]}
                                        render={({ field }) => (
                                            <Select
                                                options={gradelist}
                                                // className="form-control"
                                                placeholder='Choose Grade'
                                                isMulti
                                                {...field}
                                                value={selectedGradOptions}
                                                onChange={(otopn) => handleGradChange(otopn)}
                                            />
                                        )}
                                         />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Subject </label>
                                    <Controller
                                        name="subject"
                                        control={control}
                                        defaultValue={[]}
                                        render={({ field }) => (
                                            <Select
                                                options={subjectlist}
                                                // className="form-control"
                                                placeholder='Choose Subject'
                                                isMulti
                                                {...field}
                                                value={selectedSubOptions}
                                                onChange={(otopn) => handleSubChange(otopn)}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Status</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" {...register('status')} id="inlineRadio1" value="1" />
                                        <label className="form-check-label" for="inlineRadio1">Active</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" {...register('status')} id="inlineRadio2" value="0" />
                                        <label className="form-check-label" for="inlineRadio2">Inactive</label>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="text" className="form-control"
                                     placeholder='ENTER EMAIL'
                                    {...register('email')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="relod_cont">
                                <label  onClick={() => text_relaod()}>generate password</label>
                                    <input type="text" className="form-control"
                                    placeholder='ENTER PASSWORD'
                                    name="captcha" 
                                      {...register('password')}   value={randomText} />
                                </div>
                            </div> */}
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary" disabled={isSubmitting}>Save</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default EditTeacher;
