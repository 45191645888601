
import axios from 'axios';
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import { Link } from 'react-router-dom';
import { HTTPURL } from '../../constants/Matcher';




const CustomTableWithChild = ({ columns, data , childColumns}) => {
  
  const [searchData, setSearchData] = useState('');
  const [filterData, setFilterData] = useState([]);

  const onSearch = (value, sortByKey) => {
    setSearchData(value);
    const searchRes = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    const sortedData = searchRes.sort((a, b) => {
      const aValue = a[sortByKey]?.toLowerCase();
      const bValue = b[sortByKey]?.toLowerCase();
      if (aValue < bValue) return -1;
      if (aValue > bValue) return 1;
      return 0;
    });
    setFilterData(sortedData);
  };

  const ExpandedComponent = ({ data }) =>{
    return (
    <div className="p-4">
      {/* <h4 className="text-lg font-semibold mb-2">Terms</h4>
      <table className="w-full">
        <thead>
          <tr>
            <th className="text-left">ID</th>
            <th className="text-left">Name</th>
          </tr>
        </thead>
        <tbody>
          {data.terms.map((term) => (
            <tr key={term.id}>
              <td>{term.id}</td>
              <td>{term.name}</td>
            </tr>
          ))}
        </tbody>
      </table> */}
      <DataTable
        noHeader
        columns={childColumns}
        data={ data.terms}
        defaultSortField="id"
        defaultSortAsc={true}
      />
    </div>
  )};

  return (
    <>
      <div className='searc-bar'>
        <input
          type="search"
          className="form-control float-end"
          placeholder='Search here...'
          onChange={(e) => onSearch(e.target.value, 'name')}
        />
      </div>
      <DataTable
        noHeader
        columns={columns}
        data={searchData ? filterData : data}
        defaultSortField="id"
        defaultSortAsc={true}
        pagination
        paginationPerPage={30}
        sortMode="multiple"
        highlightOnHover
        expandableRows
        expandableRowsComponent={ExpandedComponent}
      />
    </>
  );
};

export default CustomTableWithChild;