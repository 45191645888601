import React, { useEffect, useState } from "react";
import { Button, Form } from "semantic-ui-react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { teacher_add } from "../../../apis/Teachers";
import { branchList, subjectList, gradeList } from "./Data";
import { insertId } from "../../../apis/Common";
import { random_generate_string } from "../../../Utils/Utils";
import Select from "react-select";

const AddTeacher = () => {
  const [branchlist, setBranchList] = useState([]);
  const [gradelist, setGradeList] = useState([]);
  const [subjectlist, setSubjectList] = useState([]);
  const [conPassEye, setConPassEye] = useState(false);
  const [selectedSubOptions, setSelectedSubOptions] = useState([]);
  const [selectedGradOptions, setSelectedGradOptions] = useState([]);
  const [randomText, setRandomText] = useState("");
  const [isVisible, setVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toggle = () => {
    setVisible(!isVisible);
  };

  const {
    register,
    control,
    setValue,
    handleSubmit,
    handleChange,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const text_relaod = () => {
    setRandomText(random_generate_string(8));
  };

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    if (isSubmitting) return; 
    setIsSubmitting(true);
    if (currentStep === 2) {
      const formData = {
        branch: data.branch,
        teacher_id: data.teacher_id,
        teacher_name: data.teacher_name,
        status: data.status,
        grade: selectedGradOptions,
        subject: selectedSubOptions,
        password: data.password,
        email: data.email,
        mobile: data.mobile,
      };
      let result = await teacher_add(formData);
      if (result.status) {
        return navigate("/admin/teachers-management/all");
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
    setIsSubmitting(false);
  };
  const handleGradChange = (selectedGradOptions) => {
    setSelectedGradOptions(selectedGradOptions);
  };

  const handleSubChange = (selectedSubOptions) => {
    setSelectedSubOptions(selectedSubOptions);
  };

  const fetchData = async () => {
    let res = await insertId();
    setValue("teacher_id", `TEACH${res?.data?.id ?? 1}`);
  };

  useEffect(() => {
    async function get_all_data() {
      setBranchList(await branchList());
      setGradeList(await gradeList());
      setSubjectList(await subjectList());
    }
    get_all_data();
    fetchData();
  }, []);

  return (
    <div className="right-nav" id="dash-event">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Add Teacher</h4>
          <Link className="edit-btn" to={`/admin/teachers-management/all`} onClick={(e) => {
    if (window.confirm("Are you sure you want to leave this page?")) {
    } else {
      e.preventDefault(); 
    }
  }}>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
          </Link>
        </div>
        <div className="modal-body">
          <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${currentStep === 1 ? "active " : ""}`}
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Teacher Info
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${currentStep === 2 ? "active " : ""}`}
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Teacher Admin Info
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              {currentStep === 1 && (
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Branch Name *</label>
                        <select
                          className="form-select form-control"
                          {...register("branch", { required: true })}
                        >
                          <option value="">Choose Branch</option>
                          {branchlist?.length > 0 ? (
                            branchlist &&
                            branchlist?.map((elem) => (
                              <option key={elem} value={elem?.id}>
                                {elem?.branch_name}
                              </option>
                            ))
                          ) : (
                            <option>No result</option>
                          )}
                        </select>
                      </div>
                      {errors.branch && errors.branch.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Teacher ID </label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("teacher_id")}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Teacher Name *</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Teacher Name"
                          {...register("teacher_name", { required: true })}
                        />
                      {errors.teacher_name &&
                        errors.teacher_name.type === "required" && (
                          <span className="validation-error">
                            This is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Grade</label>
                        <Controller
                          name="grade"
                          control={control}
                          defaultValue={[]}
                          render={({ field }) => (
                            <Select
                              options={gradelist}
                              // className="form-control"
                              placeholder="CHOOSE GRADE"
                              isMulti
                              {...field}
                              value={selectedGradOptions}
                              onChange={(otopn) => handleGradChange(otopn)}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Subject</label>
                        <Controller
                          name="subject"
                          control={control}
                          defaultValue={[]}
                          render={({ field }) => (
                            <Select
                              options={subjectlist}
                              // className="form-control"
                              placeholder="Choose Subject"
                              isMulti
                              {...field}
                              value={selectedSubOptions}
                              onChange={(otopn) => handleSubChange(otopn)}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Status</label>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            {...register("status")}
                            id="inlineRadio1"
                            value="1"
                            defaultChecked
                          />
                          <label
                            className="form-check-label"
                            for="inlineRadio1"
                          >
                            Active
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            {...register("status")}
                            id="inlineRadio2"
                            value="0"
                          />
                          <label
                            className="form-check-label"
                            for="inlineRadio2"
                          >
                            Inavtive
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {currentStep === 2 && (
                <div
                  className="tab-pane fade show active"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Email *</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Email"
                          {...register("email", { required: true })}
                        />
                      {errors.email && errors.email.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>mobile Number *</label>
                        <div className="input-group branch-prepend">
                            <div className="input-group-prepend">
                              <div className="input-group-text">+61</div>
                            </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="000 000 (00)"
                           autoComplete="off"
                          {...register("mobile", {
                            required: "Mobile number  is required",
                            pattern: { value: /^\d{8}$/, message: "mobile number must be 8 digits" },
                            validate: (value) =>
                              /^\d+$/.test(value) || "Only numbers are allowed",
                          })}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          maxLength={8}
                        />
                        </div>
                      {errors.mobile && (
                      <span className="validation-error">
                        {errors.mobile.message}
                      </span>
                    )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="relod_cont">
                        <label>Password *</label>
                        <div className="password-sec">
                          <input
                            type={!conPassEye ? "password" : "text"}
                            placeholder="Enter Password"
                            className="form-control"
                            name="captcha"
                              autoComplete="off"
                            // value={randomText.trim()}
                            {...register("password", { required: true })}
                          />
                          {errors.password &&
                            errors.password.type === "required" && (
                              <span className="validation-error">
                                This is required
                              </span>
                            )}
                          {/* <div onClick={() => text_relaod()} className="btn btn-primary generate-pasword">generate password</div> */}
                          <svg
                            onClick={() => setConPassEye(!conPassEye)}
                            className="eyes"
                            width="18"
                            height="14"
                            viewBox="0 0 18 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.99922 1.5C6.96172 1.5 5.28672 2.425 4.00234 3.61562C2.79922 4.73438 1.96797 6.0625 1.54297 7C1.96797 7.9375 2.79922 9.26562 3.99922 10.3844C5.28672 11.575 6.96172 12.5 8.99922 12.5C11.0367 12.5 12.7117 11.575 13.9961 10.3844C15.1992 9.26562 16.0305 7.9375 16.4555 7C16.0305 6.0625 15.1992 4.73438 13.9992 3.61562C12.7117 2.425 11.0367 1.5 8.99922 1.5ZM2.98047 2.51875C4.45234 1.15 6.47422 0 8.99922 0C11.5242 0 13.5461 1.15 15.018 2.51875C16.4805 3.87812 17.4586 5.5 17.9242 6.61562C18.0273 6.8625 18.0273 7.1375 17.9242 7.38437C17.4586 8.5 16.4805 10.125 15.018 11.4812C13.5461 12.85 11.5242 14 8.99922 14C6.47422 14 4.45234 12.85 2.98047 11.4812C1.51797 10.125 0.539844 8.5 0.0773437 7.38437C-0.0257813 7.1375 -0.0257813 6.8625 0.0773437 6.61562C0.539844 5.5 1.51797 3.875 2.98047 2.51875ZM8.99922 9.5C10.3805 9.5 11.4992 8.38125 11.4992 7C11.4992 5.61875 10.3805 4.5 8.99922 4.5C8.97734 4.5 8.95859 4.5 8.93672 4.5C8.97734 4.65938 8.99922 4.82812 8.99922 5C8.99922 6.10313 8.10234 7 6.99922 7C6.82734 7 6.65859 6.97813 6.49922 6.9375C6.49922 6.95937 6.49922 6.97813 6.49922 7C6.49922 8.38125 7.61797 9.5 8.99922 9.5ZM8.99922 3C10.0601 3 11.0775 3.42143 11.8276 4.17157C12.5778 4.92172 12.9992 5.93913 12.9992 7C12.9992 8.06087 12.5778 9.07828 11.8276 9.82843C11.0775 10.5786 10.0601 11 8.99922 11C7.93835 11 6.92094 10.5786 6.17079 9.82843C5.42065 9.07828 4.99922 8.06087 4.99922 7C4.99922 5.93913 5.42065 4.92172 6.17079 4.17157C6.92094 3.42143 7.93835 3 8.99922 3Z"
                              fill="#888888"
                            />
                          </svg>
                        </div>
                      </div>
                      {/* <span className="show-hide" onClick={toggle}>
                                        {isVisible ? <span>Hide</span> : <span>Show</span>}
                                        </span> */}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="modal-footer">
              {currentStep > 1 && (
                <Button
                  type="button"
                  className="btn button"
                  onClick={() => setCurrentStep(currentStep - 1)}
                >
                  Previous
                </Button>
              )}
              {currentStep < 2 && (
                <Button type="submit" className="btn button">
                  Next
                </Button>
              )}
              {currentStep === 2 && (
                <Button type="submit" className="btn button" disabled={isSubmitting}>
                  Save
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddTeacher;
