import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { Link, useNavigate } from 'react-router-dom';
import { addSubUser } from '../../../../apis/generalSetting/SubUsers';
const AddSubUsers = () => {
    const [payment, setPayment] = useState('0')
    const [branch, setBranch] = useState('0')
    const [classes, setClasses] = useState('0')
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm();

    const navigate = useNavigate();
    const handleChange = (e) => {
        if (e.target.checked) {
            setPayment('1')
        }
    };
    const handleChange1 = (e) => {
        if (e.target.checked) {
            setBranch('1')
        }
    };
    const handleChange2 = (e) => {
        if (e.target.checked) {
            setClasses('1')
        }
    };
    const onSubmit = async (data) => {
        data.payment = payment
        data.branch = branch
        data.classes = classes
        let result = await addSubUser(data);
        if (result.status) {
            return navigate('/admin/administrator-setting/sub-users/all');
        }
        reset()
    }
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className='row'>
                    <div className='col-md-12'>
                    <div className='sub-users'>
                        <h4 className="modal-title">Add Sub Users</h4>
                        <Link className='edit-btn' to={`/admin/administrator-setting/sub-users/all`} onClick={(e) => {
    if (window.confirm("Are you sure you want to leave this page?")) {
    } else {
      e.preventDefault(); 
    }
  }}><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
  </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='permission-sec-n'>
                        <h4 className="modal-title">Permissions</h4>
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label>First Name</label>
                                                    <input type="text"
                                                        placeholder='Enter User First Name'
                                                        className="form-control" {...register('first_name',{ required: true })}
                                                    />
                                                </div>
                                                {errors.first_name && errors.first_name.type === "required" && <span className="validation-error">This is required</span>}
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label>Last Name</label>
                                                    <input type="text"
                                                        placeholder='Enter User Last Name'
                                                        className="form-control" {...register('last_name',{ required: true })}
                                                    />
                                                </div>
                                                {errors.last_name && errors.last_name.type === "required" && <span className="validation-error">This is required</span>}
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input type="email"
                                                        placeholder='Enter User Email'
                                                        className="form-control" {...register('email',{ required: true })}
                                                    />
                                                </div>
                                                {errors.email && errors.email.type === "required" && <span className="validation-error">This is required</span>}
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label>Password</label>
                                                    <input type="password"
                                                        placeholder='Enter User Password'
                                                        className="form-control" {...register('password',{ required: true })}
                                                    />
                                                </div>
                                                {errors.password && errors.password.type === "required" && <span className="validation-error">This is required</span>}
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label>Mobile No.</label>
                                                    <input type="text"
                                                        placeholder='Enter User Mobile No.'
                                                        className="form-control" {...register('mobile',{ required: true })}
                                                    />
                                                </div>
                                                {errors.mobile && errors.mobile.type === "required" && <span className="validation-error">This is required</span>}
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>status</label>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" {...register('status', { required: true })} id="inlineRadio1" value={1} defaultChecked />
                                                        <label class="form-check-label" for="inlineRadio1">Active</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" {...register('status', { required: true })} id="inlineRadio2" value={0}/>
                                                        <label class="form-check-label" for="inlineRadio2">Inactive</label>
                                                    </div>
                                                </div>
                                                {errors.status && errors.status.type === "required" && <span className="validation-error">This is required</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 checkbox-n'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <input class="form-check-input"
                                                    type="checkbox"
                                                    value={payment}
                                                    onChange={handleChange}
                                                /> <span className="modal-title">Payment</span>
                                            </div>
                                            <div className='col-md-12'>
                                                <input class="form-check-input"
                                                    type="checkbox"
                                                    value={classes}
                                                    onChange={handleChange1}

                                                /> <span className="modal-title">Branch</span>
                                            </div>
                                            <div className='col-md-12'>
                                                <input class="form-check-input"
                                                    type="checkbox"
                                                    value={payment}
                                                    onChange={handleChange2}
                                                /> <span className="modal-title">Classes</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="modal-footer">
                                    <button type='submit' className="btn btn-primary">Save</button>
                                </div>
                            </form>
                        </div>
                        </div>
                    </div>
                </div>
                

            </div>
        </div>
    )
}
export default AddSubUsers;

