import React, { useEffect, useState } from 'react';
import { get_question_by_type } from '../../../../apis/testManagement/Question.jsx';
import { subject_all } from '../../../../apis/ClassManagement/Subjects.jsx';
import { get_text_books, get_text_books_subject_id } from '../../../../apis/TextBook.jsx';

const EditHomeWork = ({subjectIds, subjectId, setHomeWork, homeWork,  selectedSubjectId,singleTypeFormdata,gradeId }) => {
  const [forms, setForms] = useState([]);
  const [question, setQuestion] = useState([]);
  const [textBook, setTextBook] = useState([]);
  const [existingSubject, setExistingSubject] = useState({});

  useEffect(() => {
    if((selectedSubjectId) && singleTypeFormdata ){
        console.log("singleTypeFormdata", singleTypeFormdata[`subject_id_${selectedSubjectId}`])
            setForms(singleTypeFormdata[`subject_id_${selectedSubjectId}`]);
  
      }
   
  }, [selectedSubjectId]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          questionnaire_type: 'Course',
          grade_id: gradeId,
      };
        const questionList = await get_question_by_type(payload);
       // const textBookList = await get_text_books();
        const textBookList = await get_text_books_subject_id(subjectIds);
        setQuestion(questionList?.data || []);
        setTextBook(textBookList?.data || []);

        if (subjectId) {
          const subjectData = await subject_all(subjectId);
          setExistingSubject(subjectData?.data || {});
          const existingForms = subjectData?.data?.units?.map((unit) => ({
            unit_id: unit.unit_id,
            week: unit.week,
            q_id: unit.q_id,
            u_image: unit.u_image,
            u_audio: unit.u_audio,
            description: unit.description,
            u_document: unit.u_document,
            link: unit.link,
            text_book_id: unit.text_book_id
          }));
          setForms(existingForms);
          setHomeWork(existingForms); // Set existing forms to the parent component
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [subjectId, setHomeWork, subjectIds]);


  const handlehomeWorkInputChange = (e, i) => {
    const { name, value } = e.target;
    const updatedForms = [...forms];
    updatedForms[i][name] = value;
    setForms(updatedForms);
    setHomeWork(updatedForms);
  };

  const handlehomeWorkImgChange = (e, index) => {
    const { name, files } = e.target;
    const updatedForms = [...forms];
    updatedForms[index][name] = files[0];
    setForms(updatedForms);
    setHomeWork(updatedForms);
  };

  return (
    <div className="accordion" id="accordionExample">
      {forms?.map((x, i) => (
        <div key={i} className="accordion-item">
          <h2 className="accordion-header" id={`heading-${i}`}>
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse-${i}`}
              aria-expanded={i === 0 ? true : false}
              aria-controls={`collapse-${i}`}
            >
              Unit {i + 1}
            </button>
          </h2>
          <div
            id={`collapse-${i}`}
            className={`accordion-collapse collapse ${i === 0 ? 'show' : ''}`}
            aria-labelledby={`heading-${i}`}
            data-bs-parent="#accordionExample"
          >
            <div className="modal-body">
              <div className="row">
                <input type="hidden" name="unit_id" value={x.unit_id} />
                <div className="col-md-6" key={i}>
                  <div className="form-group">
                    <label>Upload Document {i + 1}</label>
                    <input
                      type="file"
                      className="form-control"
                      name="u_document"
                      onChange={(e) => handlehomeWorkImgChange(e, i)}
                      accept=".pdf"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Embedded Video Link</label>
                    <input
                      type="url"
                      className="form-control"
                      placeholder="Enter Link"
                      value={x.link}
                      name="link"
                      onChange={(e) => handlehomeWorkInputChange(e, i)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Upload Image</label>
                    <input
                      type="file"
                      className="form-control"
                      name="u_image"
                      onChange={(e) => handlehomeWorkImgChange(e, i)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Upload Audio</label>
                    <input
                      type="file"
                      className="form-control"
                      name="u_audio"
                      onChange={(e) => handlehomeWorkImgChange(e, i)}
                      accept="audio/*"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Questionaire</label>
                    <select
                      className="form-control form-select"
                      value={x.q_id}
                      name="q_id"
                      onChange={(e) => handlehomeWorkInputChange(e, i)}
                    >
                      <option value="">Choose Question</option>
                      {question &&
                        question.map((elem) => (
                          <option value={elem.id}>{elem.question_name}</option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Text Book</label>
                    <select
                      className="form-control form-select"
                      name="text_book_id"
                      value={x.text_book_id}
                      onChange={(e) => handlehomeWorkInputChange(e, i)}
                    >
                      <option value="">Choose text book</option>
                      {textBook &&
                        textBook.map((elem) => (
                          <option value={elem.id}>{elem.name}</option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Enter Description"
                      value={x.description}
                      name="description"
                      onChange={(e) => handlehomeWorkInputChange(e, i)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EditHomeWork;


