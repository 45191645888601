import React, { useState, useEffect } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { subject_add, subject_edit, subject_update } from '../../../../apis/ClassManagement/Subjects.jsx';
import { course_all } from '../../../../apis/Course.jsx';

const AddSubject = () => {
    const [courseList, setCourseList] = useState([]);
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        async function fetch_data() {
            const course = await course_all();
            setCourseList(course?.data);
        }
        fetch_data();
    }, []);

    const onSubmit = async (data) => {
        if (isSubmitting) return; 
        setIsSubmitting(true);

        let result = await subject_add(data);
        if (result.status) {
            return navigate('/admin/general-setting/subjects');
        }
        setIsSubmitting(false);
    }
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Add Subject</h4>
                    <Link className='edit-btn' to={`/admin/general-setting/subjects`} onClick={(e) => {
    if (window.confirm("Are you sure you want to leave this page?")) {
    } else {
      e.preventDefault(); 
    }
  }}><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            {/* <div className="col-md-6">
                                <div className="form-group">
                                    <label>Course</label>
                                    <select className='form-select form-control' {...register('course_id', { required: true })}>
                                        <option value="">Please Select</option>
                                        {
                                            courseList && courseList?.map((elem) => {
                                                return (
                                                    <option value={elem?.id}>{elem?.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                {errors.course_id && errors.course_id.type === "required" && <span className="validation-error">This is required</span>}
                            </div> */}
                            <div className="col-md-12">
                                <div className="form-group">
                                    <labell>Subject *</labell>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Subject'
                                        {...register('subject', { required: true })}
                                    />
                                </div>
                                {errors.subject && errors.subject.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary" disabled={isSubmitting}>Save</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

const EditSubject = () => {
    const [courseList, setCourseList] = useState([]);
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const id = useParams();

    useEffect(() => {
        async function fetch_data() {
            const course = await course_all();
            setCourseList(course?.data);
        }
        fetch_data();
    }, []);

    const onSubmit = async (data) => {
        if (isSubmitting) return; 
        setIsSubmitting(true);
        let result = await subject_update(id, data);
        if (result.status) {
            return navigate('/admin/general-setting/subjects');
        }
        setIsSubmitting(false);
    }
    const fetchData = async () => {
        let res = await subject_edit(id);
        setValue('subject', res?.data[0]?.subject);
        setValue('course_id', res?.data[0]?.course_id?.toString());
    }
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Modify Subject</h4>
                    <Link className='edit-btn' to={`/admin/general-setting/subjects`} onClick={(e) => {
    if (window.confirm("Are you sure you want to leave this page?")) {
    } else {
      e.preventDefault(); 
    }
  }}><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            {/* <div className="col-md-6">
                                <div className="form-group">
                                    <labell>Course</labell>
                                    <select className='form-select form-control' {...register('course_id', { required: true })}>
                                        <option value="">Please Select</option>
                                        {
                                            courseList && courseList?.map((elem) => {
                                                return (
                                                    <option value={elem?.id}>{elem?.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                {errors.course_id && errors.course_id.type === "required" && <span className="validation-error">This is required</span>}
                            </div> */}
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Subject *</label>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Subject'
                                        {...register('subject', { required: true })}
                                    />
                                </div>
                                {errors.subject && errors.subject.type === "required" && <span className="validation-error">This is required</span>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary" disabled={isSubmitting}>Save</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export { AddSubject, EditSubject };