import React from 'react';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { student_enroll_view,enroll_student_payment } from '../../../api/Student_Enroll';
import { useNavigate } from 'react-router-dom';
import { student_enroll_list } from '../../../../apis/studentManagement/StudentManagement';
import EnrolmentStatus from '../../../../componrnt/pages/studentManagement/EnrolmentStatus';
import { toast } from "react-toastify";
const Student_enroll_view = () => {
    const [enrollView, setEnrollView] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [enrollStatus, setEnrollStatus] = useState('');
    const [subject,setSubject]=useState([]);
    console.log('subjetsaasd',subject)
    const [term,setTerm]=useState([]);
    const id = useParams();
    const navigate=useNavigate();
    useEffect(() => {
        async function get_dataa() {
            const data = await student_enroll_list(id);
            console.log('finaldata',data)
            setEnrollView(data?.data?.results);
            // localStorage.setItem('course', JSON.stringify(data?.data?.results?.course || '-'));
            if(data?.data?.termWeekData){
                setSubject(JSON.parse(data?.data?.termWeekData?.subjectJson))
                setTerm(data?.data?.termWeekData?.term_id.split(','))
            }
        }
        get_dataa();
        async function get_data() {
            const data = await student_enroll_view(id);
            setEnrollView(data?.data?.results);
        }
        get_data();
    }, []);
    const branch_id = localStorage.getItem('b_id')

    const submitData = async () => {
        const formData = {
            'grade_id': enrollView?.grade_id,
            'user_id':enrollView?.user_id,
            'total_price':10,
            'branch_id': branch_id,
            'subject_id': enrollView?.subject_id,
            'payment_method': paymentMethod
        }
        if(enrollStatus==='1'){
            const res = await enroll_student_payment(formData);
            if (res?.status) {
                return navigate('/branch/free_student_list');
            }
        }else{
            toast.error('Enrolled status is required');
        }
       
    }
    return (
        <div>
            {/* <h4 className="modal-title">Student Enrolled</h4> */}
            <div className="right-nav" id="dash-event">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Student Detail</h4>
                        {enrollView?.student_type==='1'?
                        <Link className='edit-btn' to={`/branch/student_enroll-list`} onClick={(e) => {
                            if (window.confirm("Are you sure you want to leave this page?")) {
                            } else {
                              e.preventDefault(); 
                            }
                          }}><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>:<Link className='edit-btn' to={`/branch/free_student_list`}><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>}
                        
                    </div>
                    <div className="modal-body">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                                <div className="form-horizontal">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>First Name</label>
                                                <p>{enrollView?.first_name ? enrollView?.first_name : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Last Name</label>
                                                <p>{enrollView?.last_name ? enrollView?.last_name : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <p>{enrollView?.email ? enrollView?.email : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Mobile</label>
                                                <p>{enrollView?.mobile && enrollView?.mobile !== 'null' ? enrollView?.mobile : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Date of birth</label>
                                                <p>{enrollView?.date_of_birth ? enrollView?.date_of_birth : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Subject</label>
                                                <p>{enrollView?.subject ? enrollView?.subject : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Grade</label>
                                                <p>{enrollView?.grade ? enrollView?.grade : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>School</label>
                                                <p>{enrollView?.school ? enrollView?.school : '-'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {enrollView?.student_type == '0' ? <div className='row'>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor='enrolled'>Enrolled Status: </label>
                                    <div className='highlight_inner'>
                                        <div className=" checkbox">
                                            <label>
                                                <input type="checkbox" name='student_type' id="enrolled" value="1"
                                                onChange={(e)=>setEnrollStatus(e.target.value)}
                                               required /> Yes </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor='enrolled'>Payment Method </label>
                                    <div className='highlight_inner'>
                                        <div className=" checkbox">
                                            <label>
                                                <input type="radio" name='payment_method' id="enrolled" value="cash"
                                                    onChange={(e) => setPaymentMethod(e.target.value)}
                                                /> Cash </label>
                                        </div>
                                        <div className=" checkbox">
                                            <label>
                                                <input type="radio" name='payment_method' id="enrolled" value="online"
                                                    onChange={(e) => setPaymentMethod(e.target.value)}
                                                /> Online </label>
                                        </div>
                                        <div className=" checkbox">
                                            <label>
                                                <input type="radio" name='payment_method' id="enrolled" value="payLater"
                                                    onChange={(e) => setPaymentMethod(e.target.value)}
                                                /> Pay Later </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary" onClick={() => submitData()}>
                                Save
                            </button>
                        </div> : ''}
                        <EnrolmentStatus subject={subject} term={term}/>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Student_enroll_view;
