import React from "react";
import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  student_enroll_list,
  enroll_student_payment,
} from "../../../apis/studentManagement/StudentManagement";
import { branch_all } from "../../../apis/Branches";
import EnrolmentStatus from "./EnrolmentStatus";
import { toast } from "react-toastify";
import { formatDate } from "../../../Utils/Utils";
import DataLoader from "../../../Utils/DataLoader";
const Student_enroll_view = () => {
  const [enrollView, setEnrollView] = useState([]);
  const [branch, setBranch] = useState([]);
  const [subject, setSubject] = useState([]);
  const [term, setTerm] = useState([]);
  const [dataCheck, setDataCheck] = useState(false);
  const [fetchEnrollment, setFetchEnrollment] = useState(true);
  const [showResults, setShowResults] = useState(false);
  const id = useParams();
  const navigate = useNavigate();
  console.log("view", id);
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const submitData = async (data) => {
    setShowResults(false);
    data.grade_id = enrollView?.grade_id;
    data.user_id = enrollView?.user_id;
    data.total_price = 10;
    data.subject_id = enrollView?.subject_id;
    console.log("data", data);
    const res = await enroll_student_payment(data);
    if (res?.status) {
      setShowResults(true);
      // get_eligible_courses(id?.id)
      // get_enrolled_course_status(id?.id)
      get_data();
      setFetchEnrollment(true);
      // return navigate('/admin/students/members');
    }
  };

  async function get_data() {
    console.log("function call");

    const data = await student_enroll_list(id);
    const branch = await branch_all();
    setEnrollView(data?.data);
    setValue(data?.data?.branch);
    if (data?.data) {
      setShowResults(true);
    }
    // localStorage.setItem('course', JSON.stringify(data?.data?.results?.course || '-'));
    if (data?.data?.termWeekData) {
      setSubject(JSON.parse(data?.data?.termWeekData?.subjectJson));
      setTerm(data?.data?.termWeekData?.term_id.split(","));
    }
    setBranch(branch?.data);
  }
  useEffect(() => {
    get_data();
  }, [fetchEnrollment]);

  // check of student data
  useEffect(() => {
    if (
      !enrollView?.first_name ||
      !enrollView?.last_name ||
      !enrollView?.date_of_birth ||
      !enrollView?.address ||
      !enrollView?.school ||
      !enrollView?.grade ||
      !enrollView?.city ||
      !enrollView?.state ||
      !enrollView?.p_first_name ||
      !enrollView?.p_last_name ||
      !enrollView?.mother_mobilenumber ||
      !enrollView?.p_email ||
      !enrollView?.course
    ) {
      setDataCheck(false);
    } else {
      setDataCheck(true);
    }
  }, [enrollView]);
  const updataData = (newData) => {
    setEnrollView(newData);
  };

  return (
    <div>
      {showResults ? (
        <div className="right-nav" id="dash-event">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Student Detail</h4>
              {/* <Link className='edit-btn' to={`/admin/students/member-edit/${id?.id}`}>Modify</Link> */}
              <Link
                className="edit-btn"
                to={`/admin/students/members`}
                onClick={(e) => {
                  if (
                    window.confirm("Are you sure you want to leave this page?")
                  ) {
                  } else {
                    e.preventDefault();
                  }
                }}
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </Link>
            </div>
            <div className="modal-body">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="form-horizontal">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>First Name</label>
                          <p>
                            {enrollView?.first_name
                              ? enrollView?.first_name
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Last Name</label>
                          <p>
                            {enrollView?.last_name
                              ? enrollView?.last_name
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Email</label>
                          <p className="text-lowercase">
                            {enrollView?.email ? enrollView?.email : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Date of birth</label>
                          <p>
                            {enrollView?.date_of_birth
                              ? formatDate(enrollView?.date_of_birth)
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Branch</label>
                          <p>
                            {enrollView?.branch_name
                              ? enrollView?.branch_name
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Grade</label>
                          <p>
                            Year {enrollView?.grade ? enrollView?.grade : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Course </label>
                          <p>{enrollView?.course ? enrollView?.course : "-"}</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Term</label>
                          {enrollView?.term && (
                            <p>
                              {" "}
                              {enrollView?.term
                                ?.split(",")
                                .map((term, index, array) => (
                                  <span
                                    key={index}
                                    style={
                                      index === array.length - 1
                                        ? { fontWeight: "bold", color: "red" }
                                        : {}
                                    }
                                  >
                                    {term}
                                    {index < array.length - 1 ? ", " : ""}
                                  </span>
                                ))}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Course type</label>
                          <p>
                            {enrollView?.course_type
                              ? enrollView?.course_type
                              : "-"}
                          </p>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label>School</label>
                          <p>{enrollView?.school ? enrollView?.school : "-"}</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Student Type</label>
                          <p>
                            {enrollView?.student_type == 1
                              ? "Enrolled"
                              : "Non-Enrolled"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Contact Details</h4>
            </div>
            <div className="modal-body">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="form-horizontal">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Address</label>
                          <p>
                            {enrollView?.address ? enrollView?.address : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>City</label>
                          <p>{enrollView?.city ? enrollView?.city : "-"}</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>State</label>
                          <p>{enrollView?.state ? enrollView?.state : "-"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Parent's Personal Details</h4>
            </div>
            <div className="modal-body">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="form-horizontal">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Parent's First Name</label>
                          <p>
                            {enrollView?.p_first_name
                              ? enrollView?.p_first_name
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Parent's Last Name</label>
                          <p>
                            {enrollView?.p_last_name
                              ? enrollView?.p_last_name
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Parent's Email</label>
                          <p>
                            {enrollView?.p_email ? enrollView?.p_email : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Parent's Mobile</label>
                          <p>
                            {enrollView?.mother_mobilenumber &&
                            enrollView?.mother_mobilenumber !== "null"
                              ? enrollView?.mother_mobilenumber
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {enrollView?.student_type == "0" ? (
            <form onSubmit={handleSubmit(submitData)}>
              <div className="row enrolled">
                <hr />
                <div className="modal-header">
                  <h4 className="modal-title">Shift to Enrolled</h4>
                </div>
                <div className="col-md-3">
                  <div className="form-group ">
                    <label>Branch</label>
                    <select
                      className="form-select form-control"
                      {...register("branch_id", { required: true })}
                    >
                      <option value="">Choose a branch</option>
                      {branch &&
                        branch?.map((elem) => {
                          return (
                            <option
                              key={enrollView?.branch}
                              value={elem?.id}
                              selected={
                                elem.id == enrollView?.branch
                                  ? "selected"
                                  : null
                              }
                            >
                              {elem.branch_name}
                            </option>
                          );
                        })}
                    </select>
                    {errors.branch_id &&
                      errors.branch_id.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="enrolled">Enrolled Status: </label>
                    <div className="highlight_inner">
                      <div className=" checkbox">
                        <label>
                          <input
                            type="checkbox"
                            name="student_type"
                            {...register("student_type", { required: true })}
                            id="enrolled"
                            value="1"
                            required
                          />{" "}
                          Yes{" "}
                        </label>
                      </div>
                    </div>
                    {errors.student_type &&
                      errors.student_type.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="enrolled">Payment Method </label>
                    <div className="highlight_inner">
                      <div className=" checkbox">
                        <label>
                          <input
                            type="radio"
                            name="payment_method"
                            id="enrolled"
                            {...register("payment_method", { required: true })}
                            value="cash"
                          />{" "}
                          Cash{" "}
                        </label>
                      </div>
                      <div className=" checkbox">
                        <label>
                          <input
                            type="radio"
                            name="payment_method"
                            id="enrolled"
                            value="online"
                            {...register("payment_method", { required: true })}
                          />{" "}
                          Online{" "}
                        </label>
                      </div>
                      <div className=" checkbox">
                        <label>
                          <input
                            type="radio"
                            name="payment_method"
                            id="enrolled"
                            value="payLater"
                            {...register("payment_method", { required: true })}
                          />{" "}
                          Pay Later{" "}
                        </label>
                      </div>
                    </div>
                    {errors.payment_method &&
                      errors.payment_method.type === "required" && (
                        <span className="validation-error">
                          This is required
                        </span>
                      )}
                  </div>
                </div>
                <div className="col-md-3">
                  {dataCheck ? (
                    <button type="submit" className="btn button">
                      Save
                    </button>
                  ) : (
                    <div
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Please fill in all mandatory student data."
                    >
                      <button
                        type="button"
                        class="btn button disabled"
                        disabled={true}
                      >
                        Save
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </form>
          ) : (
            ""
          )}

          {fetchEnrollment && (
            <EnrolmentStatus
              subject={subject}
              term={term}
              onUpdate={updataData}
            />
          )}
        </div>
      ) : (
        <DataLoader />
      )}
    </div>
  );
};

export default Student_enroll_view;
