import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate, Link } from 'react-router-dom';
import { add_Category } from '../../../../apis/Catergory';
import { Button } from 'semantic-ui-react';
const Add_category = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const onSubmit = async (data) => {
        if (isSubmitting) return; 
        setIsSubmitting(true);
            let result = await add_Category(data);
                if (result.status) {     
            return navigate('/admin/general-setting/Categories/all');
        }
        setIsSubmitting(false);
    }
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <div className='col-md-6'>
                        <h4 className="modal-title">Add Category</h4>
                    </div>
                    <div className='col-md-6 text-end'>
                        <div className='modify-right'>
                            <Link className='edit-btn' to={`/admin/general-setting/Categories/all`} onClick={(e) => {
    if (window.confirm("Are you sure you want to leave this page?")) {
    } else {
      e.preventDefault(); 
    }
  }}><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>

                        </div>
                    </div>
                </div>
                <br />
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Category Name *</label>
                                    <input type="text" className="form-control"
                                        placeholder='Enter Category Name'
                                        name="name"  {...register('name', { required: true })}
                                    />
                                {errors.name && errors.name.type === "required" && <span className="validation-error">This is required</span>}
                                </div>
                            </div>
                            <div className="col-md-6">
    <div className="form-group">
        <label>Status</label>
        <div className="form-check form-check-inline">
            <input
                className="form-check-input"
                type="radio"
                {...register('status', { required: true })}
                id="inlineRadio1"
                value="1"
                defaultChecked
            />
            <label className="form-check-label" htmlFor="inlineRadio1">Active</label>
        </div>
        <div className="form-check form-check-inline">
            <input
                className="form-check-input"
                type="radio"
                {...register('status', { required: true })}
                id="inlineRadio2"
                value="0"
            />
            <label className="form-check-label" htmlFor="inlineRadio2">Inactive</label>
        </div>
    </div>
    {errors.status && errors.status.type === "required" && <span className="validation-error">This is required</span>}
</div>

                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn button" disabled={isSubmitting}>Save</Button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    )
}
export { Add_category };

