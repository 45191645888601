import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate, Link } from 'react-router-dom';
import { term_add } from '../../../apis/Common';
import { Button } from 'semantic-ui-react';
const AddTerms = () => {
    const [checkValidation, setCheckValidation] = useState(true);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
   

    const navigate = useNavigate();
    const onSubmit = async (data) => {
        if (isSubmitting) return; 
        setIsSubmitting(true);
        // if(!checkValidation){
        //     console.log('not valid');
        // }else{
        let result = await term_add(data);
        if (result.status) {
            return navigate('/admin/general-setting/term/all');
        }
        setIsSubmitting(false);
    }
    // }
    // const regexPattern = /^term\s?(1[0-2]|[1-9])$/;
    // const checkregex =(name)=>{
    //     if (regexPattern.test(name)) {
    //         console.log("Valid string");
    //         setCheckValidation(true);
    //     } else {
    //         setCheckValidation(false);
    //     }
    // }
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <div className='col-md-6'>
                        <h4 className="modal-title">Add Terms</h4>
                    </div>
                    <div className='col-md-6 text-end'>
                        <div className='modify-right'>
                            <Link className='edit-btn' to={`/admin/general-setting/term/all`} onClick={(e) => {
    if (window.confirm("Are you sure you want to leave this page?")) {
    } else {
      e.preventDefault(); 
    }
  }}><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="modal-body">
                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Term Name *</label>
                                    {/* <select className="form-control" name="name" {...register('name',{ required: true })}>
                                        <option value="">Choose Term</option>
                                        <option value="1">Term 1</option>
                                        <option value="2">Term 2</option>
                                        <option value="3">Term 3</option>
                                        <option value="4">Term 4</option>
                                    </select> */}
                                      <input type="text" className="form-control"
                                        placeholder='Enter Term Name'
                                        name="name"  {...register('name', { required: true })}
                                    />
                                </div>
                                {errors.name && errors.name.type === "required" && <span className="validation-error">This is required</span>}
                                {/* {checkValidation ? '' : <span className="validation-error">Term name should be term and number between 0 to 12</span>} */}
                            </div>
                            <div className="col-md-6">
    <div className="form-group">
        <label>Status</label>
        <div className="form-check form-check-inline">
            <input
                className="form-check-input"
                type="radio"
                {...register('status', { required: true })}
                id="inlineRadio1"
                value="1"
                defaultChecked
            />
            <label className="form-check-label" htmlFor="inlineRadio1">Active</label>
        </div>
        <div className="form-check form-check-inline">
            <input
                className="form-check-input"
                type="radio"
                {...register('status', { required: true })}
                id="inlineRadio2"
                value="0"
            />
            <label className="form-check-label" htmlFor="inlineRadio2">Inactive</label>
        </div>
    </div>
    {errors.status && errors.status.type === "required" && <span className="validation-error">This is required</span>}
</div>

                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn button" disabled={isSubmitting}>Save</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export { AddTerms };

