import { toast } from "react-toastify";
import { HTTPURL } from "../../../constants/Matcher";

// add branch
export async function branch_add(data) {
    try {
        const response = await fetch(HTTPURL + 'api/branches/branch-add', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all branches
export async function branch_all() {
    try {
        const response = await fetch(HTTPURL + 'api/branches/branches-all');
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// branch edit 
export async function branch_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/branch-edit?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// branch update 
export async function branch_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/branches/branch-update/?id=${id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// branch delete
export async function branch_destroy(id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/branch-distroy?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function branch_add_question_time(data) {
    try {
        const response = await fetch(HTTPURL + 'api/branches/branch-add-question-time', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function enrolled_course_status_save(data) {
    try {
        const response = await fetch(HTTPURL + 'api/branches/enrolled-course-status-save', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


export async function get_enrolled_course_status(id,data) {
    try {
        const response = await fetch(HTTPURL + `api/branches/get-enrolled-course-status?id=${id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


export async function get_eligible_courses(id) {
    try {
        const response = await fetch(HTTPURL + `api/branches/enrollment-eligible-courses?id=${id}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



