import React, { useState } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { Link, useNavigate } from 'react-router-dom';
import { events_add } from '../../../apis/Events';

const AddEvent = () => {
    const [show, setShow] = useState(false)
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();
    const showField = (e) => {
        const selectedValue = e.target.value;
        selectedValue == 'interview' ? setShow(true) : setShow(false)
        setValue("event_type", selectedValue, { shouldValidate: true });
    }
    const onSubmit = async (data) => {
        if (isSubmitting) return; 
        setIsSubmitting(true);
        let result = await events_add(data);
        console.log('result', result);
        if (result.status) {
            return navigate('/admin/administrator-setting/events');
        }
        setIsSubmitting(false);
    }
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 1);
    const TodayDate = maxDate.toISOString().split('T')[0];

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">New Event</h4>
                    <Link className='edit-btn' to={`/admin/administrator-setting/events`} onClick={(e) => {
    if (window.confirm("Are you sure you want to leave this page?")) {
    } else {
      e.preventDefault(); 
    }
  }}><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Event Type *</label>
                                    <select className="form-select" name="event"  {...register("event_type", { required: true })} onChange={(e) => showField(e)}>
                                        <option value="">Choose Event</option>
                                        <option value="seminar">Seminar</option>
                                        <option value="interview" >Interview</option>
                                    </select>
                                {errors.event_type && errors.event_type.type === "required" && <span className="validation-error">This is required</span>}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Title *</label>
                                    <input type="text" className="form-control" name="title"
                                        placeholder='Enter Title'
                                        {...register('title', { required: true })}
                                    />
                                {errors.title && errors.title.type === "required" && <span className="validation-error">This is required</span>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Date *</label>
                                    <input type="date" className="form-control" name="date"
                                        min={TodayDate} {...register('date', { required: true })}
                                    />
                                {errors.date && errors.date.type === "required" && <span className="validation-error">This is required</span>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Time Start *</label>
                                    <input type="time" className="form-control" name="time_start"
                                        {...register('time_start', { required: true })}
                                    />
                                {errors.time_start && errors.time_start.type === "required" && <span className="validation-error">This is required</span>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Time End *</label>
                                    <input type="time" className="form-control" name="time_end"
                                        {...register('time_end', { required: true })}
                                    />
                                {errors.time_end && errors.time_end.type === "required" && <span className="validation-error">This is required</span>}
                                </div>
                            </div>
                            {
                                show ?
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Interview Time Limit</label>
                                            <select className="form-control" name="interview_time_limit" {...register('interview_time_limit')}>
                                                <option value="">N/A</option>
                                                <option value="15">15 minutes</option>
                                                <option value="20">20 minutes</option>
                                            </select>
                                        </div>
                                    </div>
                                    : ''
                            }
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <hr className='mt-3' />
                                <div className="form-group">
                                    <label>Venue Name *</label>
                                    <input type="text" className="form-control" name="venue_name"
                                        placeholder='Enter Venue Name'
                                        {...register('venue_name', { required: true })}
                                    />
                                {errors.venue_name && errors.venue_name.type === "required" && <span className="validation-error">This is required</span>}
                                </div>
                            </div>
                            {/* <div className="col-md-12">
                                <div className="highlight_event">
                                    <div className="form-group">
                                        <label>Offline/Online Event </label>
                                        <div className='highlight_inner'>
                                            <div className="checkbox">
                                                <label> <input type="checkbox" id="event_offline" name="offline_event" value="offine"
                                                    {...register('offline_event')}
                                                /> Offline </label>
                                            </div>
                                            <div className=" checkbox">
                                                <label> <input type="checkbox" id="event_online" name="online_event" value="online"
                                                    {...register('online_event')}
                                                /> Online </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                                            <div className="col-md-12">
                                            <div className="highlight_event">
                                                <div className="form-group">
                                                <label>Offline/Online Event </label>
                                                <div className='highlight_inner'>
                                                    <div className="radio">
                                                    <label> 
                                                        <input type="radio" id="event_offline" name="event_type" value="offline" 
                                                        {...register('offline_online_event', { required: true })}
                                                        /> Offline 
                                                    </label>
                                                    </div>
                                                    <div className="radio">
                                                    <label> 
                                                        <input type="radio" id="event_online" name="event_type" value="online" 
                                                        {...register('offline_online_event', { required: true })}
                                                        /> Online 
                                                    </label>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                                 {errors.offline_online_event && errors.offline_online_event.type === "required" && <span className="validation-error">This is required</span>}
                                            </div>
                            <div className="col-md-9">
                                <div className="form-group">
                                    <label>(Offline) Address</label>
                                    <input type="text" className="form-control" name="offine_address"
                                        placeholder='Enter Offline Address'
                                        {...register('offine_address')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">

                                    <label>(Offline) Seats</label>
                                    <input type="number" className="form-control"
                                        placeholder='Enter Offline Seats'
                                        name="offine_seats" {...register('offine_seats')} />
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="form-group">

                                    <label>(Online) URL</label>
                                    <input type="text"
                                        placeholder='Enter Url'
                                        className="form-control" name="online_url"  {...register('online_url')} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">

                                    <label>(Online) Seats</label>
                                    <input type="number"
                                        placeholder='Enter Online Seats'
                                        className="form-control" name="online_seats"  {...register('online_seats')} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">

                                    <label>Description</label>
                                    <textarea className="form-control"
                                        placeholder='Enter Description'
                                        name="description" rows="3" {...register('description')}></textarea>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <hr className='mt-3' />
                                <div className="form-group">
                                    <label><b>Booking Date</b></label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Start *</label>
                                    <input type="date" className="form-control" name="booking_date_start"
                                        {...register('booking_date_start',{required:true})} min={TodayDate}
                                    />
                                     {errors.booking_date_start && errors.booking_date_start.type === "required" && <span className="validation-error">This is required</span>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>End *</label>
                                    <input type="date" className="form-control" name="booking_date_end"
                                        {...register('booking_date_end',{required:true})} min={TodayDate}
                                    />
                                     {errors.booking_date_end && errors.booking_date_end.type === "required" && <span className="validation-error">This is required</span>}
                                </div>
                            </div>
                            <br />
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label><b>Break (if applicable)</b></label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">

                                    <label>Start</label>
                                    <input type="time" className="form-control" name="break_start"
                                        {...register('break_start')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">

                                    <label>End</label>
                                    <input type="time" className="form-control" name="break_end"
                                        {...register('break_end')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary" disabled={isSubmitting}>Save Event</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default AddEvent;
