import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { grade_all } from '../../../apis/Grade';
import { course_edit, course_update, update_course_consulting, update_course_etutoring, update_course_evaluation, update_course_homework, update_course_lecture, update_course_self_study } from '../../../apis/Course';
import { get_category } from '../../../apis/category';
import { get_terms } from '../../../apis/Common.jsx';
import { get_question } from '../../../apis/testManagement/Question.jsx';
import { subject_all, subject_by_coursetype } from '../../../apis/ClassManagement/Subjects.jsx';
import EditAssessment from './Assessment/Edit.jsx';
import EditLecture from './Lecture/Edit.jsx';
import EditHomework from './HomeWork/Edit.jsx';
import EditSelfStudy from './SelfStudy/Edit.jsx';
import EditConsulting from './Consulting/Edit.jsx';
import EditEvaluation from './Evaluation/Edit.jsx';
import EditeTutoring from './E_Tutoring/Edit.jsx';
import axios from 'axios';
import { HTTPURL } from '../../../constants/Matcher.jsx';
import { Button } from 'semantic-ui-react';
const EditCourse = () => {
    const [coursesubjects, setCoursesubjects] = useState([]);
    const [subjects, setSubject] = useState([]);
    const [gradelist, setGradeList] = useState([]);
    const [courseType, setCourseType] = useState([]);
    const [question, setQuestion] = useState([]);
    const [termsList, setTermList] = useState([]);
    const [currentStep, setCurrentStep] = useState(1);
    const [learnigStep, setLearningStep] = useState(0);
    const [images, setImages] = useState([]);
    const [file, setFile] = useState(null);
    const [selectedSubjects, setSelectedSubject] = useState([[coursesubjects[0]]]);
    const [assessmentData, setAssessmentData] = useState([]);
    const [lectureData, setLectureData] = useState([]);
    const [homeWorkData, setHomeWorkData] = useState([]);
    const [selfStudyData, setSelfStudyData] = useState([]);
    const [eTuoringData, setEtutoringData] = useState([]);
    const [ConsultingData, setConsultingData] = useState([]);
    const [evaluationData, setEvaluationData] = useState([]);
    const [date, setDate] = useState('');
    const [homeWorkDate, setHomeWorkDate] = useState('');
    const [imgName, setImgName] = useState('')
    const [singleTypeFormdata , setSingleTypeFormdata] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
      const location = useLocation();

    const { course_id ,id ,course_type_id,grade_id } = useParams();
    // console.log("******params data",course_id ,id ,totalUnits,course_type_id);


    const Subject_id = localStorage.getItem('courseSubjectID');
    const [selectedSubjectId , setSelectedSubjectId] = useState(Subject_id)

 const fetchLectureData = async () => {
   // let edit_course_id = localStorage.getItem("edit_course_id")
   if(id && course_id){
  try {
        
        
         const response = await axios.get(
           `${HTTPURL}api/edit-course-lecture?course_id=${course_id}&type=${learnigStep?learnigStep:1}&term_id=${id}`,
           
         );
        // console.log("response.data.data[`term_id_${id}`]",response.data.data[`term_id_${id}`])
         setSingleTypeFormdata(response.data.data[`term_id_${id}`])
         // Set the data from response
        // setData(response.data);
        // setLoading(false); // Set loading to false once data is fetched
       } catch (err) {
        // setError(err);
        // setLoading(false); // Stop loading in case of error as well
       }
   }
      
     };
 
 useEffect(()=>{
   fetchLectureData()
 },[learnigStep , id])

 useEffect(()=>{
   const currentStepActive  = location.state?.currentStepActive;
         if(currentStepActive){
            //  const currentActiveLearningStep  = location.state?.currentActiveLearningStep;
          setCurrentStep(currentStepActive);
         setLearningStep(1)

         }
 },[])
    const [inputList, setInputList] = useState([
        {
            u_document: '',
            link: '',
            u_image: '',
            u_audio: '',
            q_id: '',
            subject_id: ''
        }
    ]);
    const [lecture, setLecture] = useState([
        {
            unit: '',
            week: '',
            q_id: '',
            u_image: '',
            subject_id: Subject_id,
            u_audio: '',
            description: '',
            u_document: '',
            link: ''
        }
    ]);
    const [homeWork, setHomeWork] = useState([
        {
            unit: '',
            week: '',
            q_id: '',
            u_image: '',
            subject_id:Subject_id,
            u_audio: '',
            description: '',
            u_document: '',
            link: ''
        }
    ]);
    const [selfStudy, setSelfStudy] = useState([
        {
            q_id: '',
            subject_id:Subject_id,
            u_document: '',
            link: '',
            u_image: '',
            u_audio: ''
        }
    ])
    const [eTutroing, setEtutoring] = useState([
        {
            q_id: '',
            subject_id:Subject_id,
            u_document: '',
            link: '',
            u_image: '',
            u_audio: ''
        }
    ])
    const [consulting, setConsulting] = useState([
        {
            q_id: '',
            subject_id:Subject_id,
            u_document: '',
            link: '',
            u_image: '',
            u_audio: ''
        }
    ])
    const [evaluation, setEvaluation] = useState([
        {
            q_id: '',
            subject_id:Subject_id,
            u_document: '',
            link: '',
            u_image: '',
            u_audio: ''
        }
    ])

    useEffect(() => {
        setHomeWorkData(prevHomeWork =>
            prevHomeWork.map(hw => ({
                ...hw,
                subject_id: Subject_id
            }))
        );
        setLectureData(prev =>
            prev.map(le => ({
                ...le,
                subject_id: Subject_id
            }))
        )
        setSelfStudyData(prev =>
            prev.map(le => ({
                ...le,
                subject_id: Subject_id
            }))
        )
        setConsultingData(prev =>
            prev.map(le => ({
                ...le,
                subject_id: Subject_id
            }))
        )
        setEvaluationData(prev =>
            prev.map(le => ({
                ...le,
                subject_id: Subject_id
            }))
        )
        setEtutoringData(prev =>
            prev.map(le => ({
                ...le,
                subject_id: Subject_id
            }))
        )
    }, [Subject_id]);
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
        setValue,
        reset
    } = useForm();
    const navigate = useNavigate();
    // localStorage.setItem('totalUnits', totalUnits);

    const handleFileChange = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            if (!images.includes(e.target.files[i])) {
                setImages(pre => [...pre, e.target.files[i]])
            }

        }
    }
    const onSubmit = async (data) => {
        if (isSubmitting) return; 
        setIsSubmitting(true);
        console.log('data', data);
        const form = new FormData();
        form.append("image", file || '');
        form.append("grade_id", getValues('grade_id'))
        //form.append("term_id", getValues('term_id'))
        form.append("course_type_id", getValues('course_type_id'))
        form.append("name", getValues('name'))
        form.append("status", getValues('status'))
        form.append("price", getValues('price'))
        form.append("imgName", imgName || '');
        {
            assessmentData?.length > 0 ?
                assessmentData && assessmentData?.forEach((item, index) => {
                    for (const key in item) {
                        form.append(`assessment[${index}][${key}]`, item[key]);
                    }
                }) :
                inputList && inputList?.forEach((item, index) => {
                    for (const key in item) {
                        form.append(`assessment[${index}][${key}]`, item[key]);
                    }
                });
        }
        // {
        //     lectureData && lectureData.length > 0 ?
        //         lectureData && lectureData?.forEach((item, index) => {
        //             for (const key in item) {
        //                 form.append(`lecture[${index}][${key}]`, item[key]);
        //             }
        //         })
        //         :
        //         lecture?.forEach((item, index) => {
        //             if (index === 0) {
        //                 form.append("lecture[0][start_date]", date);
        //             }
        //             for (const key in item) {
        //                 form.append(`lecture[${index}][${key}]`, item[key]);
        //             }
        //         });
        // }
        // {
        //     homeWorkData && homeWorkData.length > 0 ?
        //         homeWorkData && homeWorkData?.forEach((item, index) => {
        //             for (const key in item) {
        //                 form.append(`homework[${index}][${key}]`, item[key]);
        //             }
        //         })
        //         :
        //         homeWork?.forEach((item, index) => {
        //             if (index === 0) {
        //                 form.append("homework[0][start_date]", homeWorkDate);
        //             }
        //             for (const key in item) {
        //                 form.append(`homework[${index}][${key}]`, item[key]);
        //             }
        //         });
        // }
        // {
        //     selfStudyData && selfStudyData.length > 0 ?
        //         selfStudyData && selfStudyData?.forEach((item, index) => {
        //             for (const key in item) {
        //                 form.append(`selfDir[${index}][${key}]`, item[key]);
        //             }
        //         })
        //         :
        //         selfStudy?.forEach((item, index) => {
        //             for (const key in item) {
        //                 form.append(`selfDir[${index}][${key}]`, item[key]);
        //             }
        //         });
        // }
        // {
        //     eTuoringData && eTuoringData.length > 0 ?
        //         eTuoringData && eTuoringData?.forEach((item, index) => {
        //             for (const key in item) {
        //                 form.append(`etutoring[${index}][${key}]`, item[key]);
        //             }
        //         })
        //         :
        //         eTutroing?.forEach((item, index) => {
        //             for (const key in item) {
        //                 form.append(`etutoring[${index}][${key}]`, item[key]);
        //             }
        //         });
        // }
        // {
        //     ConsultingData && ConsultingData.length > 0 ?
        //         ConsultingData && ConsultingData?.forEach((item, index) => {
        //             for (const key in item) {
        //                 form.append(`consulting[${index}][${key}]`, item[key]);
        //             }
        //         })
        //         :
        //         consulting?.forEach((item, index) => {
        //             for (const key in item) {
        //                 form.append(`consulting[${index}][${key}]`, item[key]);
        //             }
        //         });
        // }
        // {
        //     evaluationData && evaluationData.length > 0 ?
        //         evaluationData && evaluationData?.forEach((item, index) => {
        //             for (const key in item) {
        //                 form.append(`evaluation[${index}][${key}]`, item[key]);
        //             }
        //         })
        //         :
        //         evaluation?.forEach((item, index) => {
        //             for (const key in item) {
        //                 form.append(`evaluation[${index}][${key}]`, item[key]);
        //             }
        //         });
        // } course/all
        if (currentStep === 1) {
            const result = await course_update(id, form);
            if (result.status) {
                const course_id = result.data;
                localStorage.setItem('course_id', course_id);
                navigate("/admin/course-managment/course/all");
            }
        } else {
            // Move to the next step
            if(!location?.state?.isNotVisibleSecondStep){
             setCurrentStep(2);
            setLearningStep(1);
            }
           
        }
        setIsSubmitting(false);
    };

    const onSubmit2 = async () => {
        const form = new FormData();
        if (isSubmitting) return; 
        setIsSubmitting(true);
       // const course_id = localStorage.getItem('course_id');
        form.append("course_id", course_id);
    
        {
            lectureData && lectureData.length > 0 ?
                lectureData && lectureData?.forEach((item, index) => {
                    for (const key in item) {
                        form.append(`lecture[${index}][${key}]`, item[key]);
                    }
                })
                :
                lecture?.forEach((item, index) => {
                    if (index === 0) {
                        form.append("lecture[0][start_date]", date);
                        form.append("lecture[0][subject_id]", selectedSubjectId);
                    }
                    for (const key in item) {
                        form.append(`lecture[${index}][${key}]`, item[key]);
                    }
                });
        }
    
    
        let result = await update_course_lecture(course_id,id, form);
        if (result.status) {
           // setLearningStep(2); // Update the step
        } else {
           // setLearningStep(2); // Handle failure case
        }
        setIsSubmitting(false);
    };

    const onSubmit3 = async () => {
        const form = new FormData();
        if (isSubmitting) return; 
        setIsSubmitting(true);
      //  const course_id = localStorage.getItem('course_id'); 
        form.append("course_id", course_id);

        {
            selfStudyData && selfStudyData.length > 0 ?
                selfStudyData && selfStudyData?.forEach((item, index) => {
                    for (const key in item) {
                        form.append(`selfDir[${index}][${key}]`, item[key]);
                    }
                })
                :
                selfStudy?.forEach((item, index) => {
                    if (index === 0) {
                        form.append("sub[0][subject_id]", selectedSubjectId);
                    }
                    for (const key in item) {
                        form.append(`selfDir[${index}][${key}]`, item[key]);
                    }
                });
        }
    
        let result = await update_course_self_study(course_id,id, form);
        if (result.status) {
        //    setLearningStep(3); // Update the step
        } else {
          //  setLearningStep(3); // Handle failure case
        }
        setIsSubmitting(false);
    };


    const onSubmit4 = async () => {
        const form = new FormData();
        if (isSubmitting) return; 
        setIsSubmitting(true);
      //  const course_id = localStorage.getItem('course_id'); 
        form.append("course_id", course_id);
         {
            homeWorkData && homeWorkData.length > 0 ?
                homeWorkData && homeWorkData?.forEach((item, index) => {
                    for (const key in item) {
                        form.append(`homework[${index}][${key}]`, item[key]);
                    }
                })
                :
                homeWork?.forEach((item, index) => {
                    if (index === 0) {
                        form.append("homework[0][start_date]", homeWorkDate);
                        form.append("homework[0][subject_id]", selectedSubjectId);
                    }
                    for (const key in item) {
                        form.append(`homework[${index}][${key}]`, item[key]);
                    }
                });
        }
    
        let result = await update_course_homework(course_id,id, form);
        if (result.status) {
          //  setLearningStep(4); // Update the step
        } else {
          //  setLearningStep(4); // Handle failure case
        }
        setIsSubmitting(false);
    };


    const onSubmit5 = async () => {
        const form = new FormData();
        if (isSubmitting) return; 
        setIsSubmitting(true);
       // const course_id = localStorage.getItem('course_id'); 
        form.append("course_id", course_id);
           {
            eTuoringData && eTuoringData.length > 0 ?
                eTuoringData && eTuoringData?.forEach((item, index) => {
                    for (const key in item) {
                        form.append(`etutoring[${index}][${key}]`, item[key]);
                    }
                })
                :
                eTutroing?.forEach((item, index) => {
                    if (index === 0) {
                        form.append("sub[0][subject_id]", selectedSubjectId);
                    }
                    for (const key in item) {
                        form.append(`etutoring[${index}][${key}]`, item[key]);
                    }
                });
        }
    
        let result = await update_course_etutoring(course_id,id, form);
        if (result.status) {
          //  setLearningStep(5); // Update the step
        } else {
           // setLearningStep(5); // Handle failure case
        }
        setIsSubmitting(false);
    };


    const onSubmit6 = async () => {
        const form = new FormData();
        if (isSubmitting) return; 
        setIsSubmitting(true);
       // const course_id = localStorage.getItem('course_id'); 
        form.append("course_id", course_id);
        {
            ConsultingData && ConsultingData.length > 0 ?
                ConsultingData && ConsultingData?.forEach((item, index) => {
                    for (const key in item) {
                        form.append(`consulting[${index}][${key}]`, item[key]);
                    }
                })
                :
                consulting?.forEach((item, index) => {
                    if (index === 0) {
                        form.append("sub[0][subject_id]", selectedSubjectId);
                    }
                    for (const key in item) {
                        form.append(`consulting[${index}][${key}]`, item[key]);
                    }
                });
        }
    
        let result = await update_course_consulting(course_id,id, form);
        if (result.status) {
           // setLearningStep(6); // Update the step
        } else {
           // setLearningStep(6); // Handle failure case
        }
        setIsSubmitting(false);
    };

    const onSubmit7 = async () => {
        const form = new FormData();
        if (isSubmitting) return; 
        setIsSubmitting(true);
       // const course_id = localStorage.getItem('course_id'); 
        form.append("course_id", course_id);
        {
            evaluationData && evaluationData.length > 0 ?
                evaluationData && evaluationData?.forEach((item, index) => {
                    for (const key in item) {
                        form.append(`evaluation[${index}][${key}]`, item[key]);
                    }
                })
                :
                evaluation?.forEach((item, index) => {
                    if (index === 0) {
                        form.append("sub[0][subject_id]", selectedSubjectId);
                    }
                    for (const key in item) {
                        form.append(`evaluation[${index}][${key}]`, item[key]);
                    }
                });
        }
    
        let result = await update_course_evaluation(course_id,id, form);
            if (result.status) {
                localStorage.removeItem('course_id'); 
                return navigate('/admin/course-managment/course/all');
            } else {
               // setCurrentStep(currentStep + 1); // Move to the next step
            }
            setIsSubmitting(false);
        }

    //course get data api
    useEffect(() => {
        const fetchData = async () => {
            const gradeList = await grade_all();
            const courseType = await get_category();
            const terms = await get_terms();
            const questionList = await get_question();
            const result = await subject_all();
            setGradeList(gradeList?.data);
            setCourseType(courseType?.data);
            setTermList(terms?.data);
            setQuestion(questionList?.data);
            setSubject(result?.data);
        };
        const get_data = async () => {
            const result = await course_edit(id);
            if (result?.data?.length > 0) {
                const { name, status, grade_id, course_type_id, image,totalUnits, price, term_id } = result?.data[0];
                localStorage.setItem('totalUnits', totalUnits);
                setValue('name', name);
                setValue('status', status);
                setValue('price', price);
                setTimeout(() => {
                    setValue('grade_id', grade_id.toString());
                    setValue('course_type_id', course_type_id.toString());
                    setValue('term_id', term_id?.toString());
                }, 2000)
                setValue('image', image)
                setImgName(image)
                setAssessmentData(JSON?.parse(result?.data[0]?.assessment_test || '[]'))
                setLectureData(JSON?.parse(result?.data[0]?.lecture || '[]'))
                setHomeWorkData(JSON.parse(result?.data[0]?.homework || '[]'))
                setSelfStudyData(JSON.parse(result?.data[0]?.self_directed_study || '[]'))
                setEtutoringData(JSON.parse(result?.data[0]?.e_tutoring || '[]'))
                setConsultingData(JSON.parse(result?.data[0]?.consulting || '[]'))
                setEvaluationData(JSON.parse(result?.data[0]?.evaluation || '[]'))
            }
        }
        fetchData();
        get_data();
    }, []);
   // const course_type=localStorage.getItem("course_type")
    useEffect(() => {
         if(course_type_id?.length > 0){
          const getData = async () => {
            const result2 = await subject_by_coursetype(course_type_id);

            setCoursesubjects(result2?.data);
              if(result2?.data.length > 0){
                console.log('result2?.data[0]?.value', result2?.data[0]?.value)
              setSelectedSubjectId(result2?.data[0]?.value)
            }
           // console.log("!!!!!!22",result2?.data);
           // localStorage.removeItem("course_type");
          };
          getData();
        }
      }, []);
    // console.log('daf', lectureData[0]?.start_date);
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <div className='col-md-6'>
                        <h4 className="modal-title">Edit Course</h4>
                    </div>
                    <div className='col-md-6 text-end'>
                        <div className='modify-right'>
                            <Link className='edit-btn' to={`/admin/course-managment/course/all`} onClick={(e) => {
    if (window.confirm("Are you sure you want to leave this page?")) {
    } else {
      e.preventDefault(); 
    }
  }}><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
                        </div>
                    </div>
                </div>
                <br />
               
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                       { !location?.state?.isNotVisibleFirstStep ?<li className="nav-item" role="presentation">
                            <button onClick={() => setCurrentStep(1)} className={`nav-link ${currentStep === 1 ? 'active ' : ''}`} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Basic Info</button>
                        </li>:""}
                       { !location?.state?.isNotVisibleSecondStep ?<li className="nav-item" role="presentation">
                            <button onClick={() => (setCurrentStep(2), setLearningStep(1))} className={`nav-link ${currentStep === 2 ? 'active ' : ''}`} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" style={{ cursor: "auto" }}>Steps of learning</button>
                        </li>:""}
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        {currentStep === 1 && (
                             <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className='row'>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Grade *</label>
                                            <select className="form-select form-control" {...register('grade_id', { required: true })}>
                                                <option value="">Choose Grade </option>
                                                {gradelist?.length > 0 ? (
                                                    gradelist.map((elem) => {
                                                        return (
                                                            <option key={elem?.id} value={elem?.id}>
                                                                {elem.name === 0 ? `Year ${elem.name} (kindy)` : `Year ${elem.name}`}
                                                            </option>
                                                        );
                                                    })
                                                ) : (
                                                    <option>No result</option>
                                                )}

                                            </select>
                                        {errors.grade_id && errors.grade_id.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Term</label>
                                            <select className="form-select form-control" name="term" {...register('term_id', { required: true })}>
                                                <option value="">Choose Term </option>
                                                {
                                                    termsList && termsList?.map((elem) => {
                                                        return (
                                                            <option value={elem?.id}>{elem?.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {errors.term_id && errors.term_id.type === "required" && <span className="validation-error">This is required</span>}
                                    </div> */}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Course Type *</label>
                                            <select className="form-select form-control" {...register('course_type_id', { required: true })}>
                                                <option value="">Choose Course Type </option>
                                                {
                                                    courseType && courseType?.map((elem) => {
                                                        return (
                                                            <option value={elem?.id}>{elem?.category_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        {errors.course_type_id && errors.course_type_id.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>course Name *</label>
                                            <input type="text" className="form-control"
                                                placeholder='Enter Course Name'
                                                {...register('name', { required: true })}
                                            />
                                        {errors.name && errors.name.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Unit Price *</label>
                                            <input type="text" className="form-control"
                                                placeholder='Enter Course Price'
                                                onKeyPress={(event) => {
                                                    if (!/[\d\.]/.test(event.key)) {
                                                      event.preventDefault();
                                                    }
                                                  }}
                                                {...register('price', { required: true })}
                                            />
                                        {errors.price
                                            && errors.price.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>course Image *</label>
                                            <input type="file" className="form-control"
                                                placeholder='ENTER file'
                                                {...register('image', { required: true })}
                                                onChange={(e) => setFile(e.target.files[0])}
                                            />
                                        {file ? '' :errors.image && errors.image.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>status</label>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" {...register('status', { required: true })} id="inlineRadio1" value="1" />
                                                <label class="form-check-label" for="inlineRadio1">Active</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" {...register('status', { required: true })} id="inlineRadio2" value="0" />
                                                <label class="form-check-label" for="inlineRadio2">Inactive</label>
                                            </div>
                                        {errors.status && errors.status.type === "required" && <span className="validation-error">This is required</span>}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {currentStep === 1 && (
                            <div className="modal-footer">
                                <button type="submit" className="btn button" disabled={isSubmitting}>
                                {!location?.state?.isNotVisibleSecondStep ?"Save & Next":"Save"}
                                </button>
                           
                            </div>
                            )}
                            </form>
                        )}
                        {currentStep === 2 && (
                            <>
                                <div className="tab-pane fade show active" id="pills-home1" role="tabpane" aria-labelledby="pills-home-tab1">
                                    <div className="modal-body">
                                        <ul className="nav nav-pills mb-3" id="pills-tab1" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                {/* <button onClick={() => setLearningStep(1)} className={`nav-link ${learnigStep === 1 ? 'active ' : ''}`} id="pills-assesment-test-tab" data-bs-toggle="pill" data-bs-target="#pills-assesment-test" type="button" role="tab" aria-controls="pills-assesment-test" aria-selected="true">Assessment Test</button> */}
                                            </li>
                                            <li className="nav-item" role="presentation" >
                                            <button className={`nav-link ${learnigStep === 1 ? "active " : ""}`} type="button" style={{ cursor: "auto" }}>Lectures</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${learnigStep === 2 ? "active " : "" }`} type="button" style={{ cursor: "auto" }}>Self Directed Study</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className={`nav-link ${learnigStep === 3 ? 'active ' : ''}`} type="button" role="tab" aria-controls="pills-homework" aria-selected="false" style={{ cursor: "auto" }}>Homework / Topic Test</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className={`nav-link ${learnigStep === 4 ? 'active ' : ''}`} type="button" role="tab" aria-controls="pills-e-tutoring" aria-selected="false" style={{ cursor: "auto" }}>E-Tutoring</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className={`nav-link ${learnigStep === 5 ? 'active ' : ''}`} type="button" role="tab" aria-controls="pills-consulting" aria-selected="false" style={{ cursor: "auto" }}>Consulting</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className={`nav-link ${learnigStep === 6 ? 'active ' : ''}`} type="button" role="tab" aria-controls="pills-evaluation" aria-selected="false" style={{ cursor: "auto" }}>Evaluation</button>
                                            </li>
                                            <li>
                                            <div className="modal-footer"> 
                                            {learnigStep >= 2 && (
                                                                <button
                                                                    className="btn btn-secondary"
                                                                    onClick={() => setLearningStep(learnigStep - 1)}
                                                                >
                                                                    Previous
                                                                </button>
                                                                )}
                                                                {learnigStep >= 0 && learnigStep < 6&& (
                                                                <Button
                                                                    className="btn button"
                                                                    onClick={() => setLearningStep(learnigStep + 1)}
                                                                >
                                                                    Next
                                                                    </Button>
                                                                )}
                                                                
                                                            </div>

                                            </li>
                                        </ul>
                                        <div className="tab-content1" id="pills-tabContent1">
                                            {/* {learnigStep === 1 && (
                                                // <EditAssessment setInputList={setInputList} inputList={inputList} setAssessmentData={setAssessmentData} assessmentData={assessmentData} />
                                            )} */}
                                            {learnigStep === 1 && (
                                                <>
                                                    <div className="modal-header">
                                                        <h4 className="modal-title">Step 1 - Lectures</h4>
                                                    </div>
                                                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit2)} encType="multipart/form-data">
                                                    <div className="col-md-6">
                            <div className="form-group">
                              <label>Subject</label>
                              <ul className="nav nav-tabs">
                                {coursesubjects &&
                                  coursesubjects.map((elem) => {
                                    return (
                                      <li key={elem.value} className={`nav-item ${selectedSubjectId === elem.value ? "active" : ""}`}>
                                        <a
                                          className={`nav-link ${
                                            selectedSubjectId === elem.value
                                              ? "active"
                                              : ""
                                          }`}
                                          value={elem?.label}
                                          id="pills-evaluation-tab"
                                          data-bs-toggle="pill"
                                          data-bs-target="#pills-evaluation"
                                          type="button"
                                          role="tab"
                                          aria-controls="pills-evaluation"
                                          aria-selected="false"
                                          onClick={(e) =>{
                                            
                                           // setSelectedSubject(elem?.value)
                                            setSelectedSubjectId(elem?.value)}
                                          }
                                        >
                                          {elem.label}
                                        </a>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>
                          {singleTypeFormdata && selectedSubjectId && singleTypeFormdata[`subject_id_${selectedSubjectId}`] && selectedSubjectId && singleTypeFormdata[`subject_id_${selectedSubjectId}`].length > 0 
                                ? <div className="col-md-6" >
                                    <div className="form-group">
                                        <label>Start Date </label>
                                        <input type="date" className="form-control"
                                        name='start_date'
                                        pattern="dd-mm-yyyy"
                                        value={singleTypeFormdata[`subject_id_${selectedSubjectId}`][0]?.start_date}
                                        onChange={(e) => setDate(e.target.value)}
                                        required
                                        disabled={singleTypeFormdata[`subject_id_${selectedSubjectId}`][0]?.start_date ? true : false} />
                                    </div>
                                    </div> 
                                : <div className="col-md-6" >
                                    <div className="form-group">
                                        <label>Start Date </label>
                                        <input type="date" className="form-control"
                                        name='start_date'
                                        pattern="dd-mm-yyyy"
                                        onChange={(e) => setDate(e.target.value)}
                                        required />
                                    </div>
                                    </div>
                                }
                                                    {/* <div className="col-md-6" >
                                                        <div className="form-group">
                                                            <label>Start Date </label>
                                                            <input type="date" className="form-control"
                                                                name='start_date'
                                                                value={lectureData[0]?.start_date}
                                                                onChange={(e) => setDate(e.target.value)}
                                                                disabled />
                                                        </div>
                                                    </div> */}
                                                    <EditLecture 
                                                    subjectIds={selectedSubjectId}
                                                    setLecture={setLecture}
                                                     lecture={lecture} 
                                                    setLectureData={setLectureData}
                                                     lectureData={lectureData}
                                                     selectedSubjectId={selectedSubjectId} 
                                                     setSelectedSubjectId={setSelectedSubjectId}
                                                     singleTypeFormdata={singleTypeFormdata}
                                                     gradeId={grade_id}
                                                     />
                                                    <div className="modal-footer">
                                                            {learnigStep === 1 && (
                                                                <Button type="submit" className="btn button" disabled={isSubmitting}>
                                                                Save
                                                                </Button>
                                                                )}
                                                                
                                                            </div>
                                                            <div className="modal-footer"> 
                                                                {learnigStep === 1 && (
                                                                <Button
                                                                    className="btn button"
                                                                    onClick={() => setLearningStep(learnigStep + 1)}
                                                                >
                                                                    Next
                                                                    </Button>
                                                                )}
                                                                 <button
                                                            className="btn btn-secondary"
                                                            onClick={() => {
                                                                navigate("/admin/course-managment/course/all");
                                                                localStorage.removeItem("course_id");
                                                                localStorage.removeItem("term_id");
                                                            }}
                                                            >
                                                            Submit
                                                            </button>
                                                            </div>
                                                    </form>
                                                </>
                                            )}
                                            {learnigStep === 2 && (
                                                <>
                                                    <div className="modal-header">
                                                        <h4 className="modal-title">Step 2 - Self Diverted Study</h4>
                                                    </div>
                                                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit3)} encType="multipart/form-data">
                                                    <div className="col-md-6">
                            <div className="form-group">
                              <label>Subject</label>
                              <ul className="nav nav-tabs">
                                {coursesubjects &&
                                  coursesubjects.map((elem) => {
                                    return (
                                      <li key={elem.value} className={`nav-item ${selectedSubjectId === elem.value ? "active" : ""}`}>
                                        <a
                                          className={`nav-link ${
                                            selectedSubjectId === elem.value
                                              ? "active"
                                              : ""
                                          }`}
                                          value={elem?.label}
                                          id="pills-evaluation-tab"
                                          data-bs-toggle="pill"
                                          data-bs-target="#pills-evaluation"
                                          type="button"
                                          role="tab"
                                          aria-controls="pills-evaluation"
                                          aria-selected="false"
                                          onClick={(e) =>{
                                          setSelectedSubjectId(elem?.value)
                                            setSelectedSubject(elem?.value)}
                                          }
                                        >
                                          {elem.label}
                                        </a>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>
                                                  <EditSelfStudy setSelfStudy={setSelfStudy} selfStudy={selfStudy} setSelfStudyData={setSelfStudyData} selfStudyData={selfStudyData} 
                                                     selectedSubjectId={selectedSubjectId} 
                                                     setSelectedSubjectId={setSelectedSubjectId}
                                                     singleTypeFormdata={singleTypeFormdata}
                                                     gradeId={grade_id}/>
                                                  <div className="modal-footer">
                                                            {learnigStep === 2 && (
                                                                <button type="submit" className="btn button" disabled={isSubmitting}>
                                                                Save
                                                                </button>
                                                                )}
                                                                
                                                            </div>
                                                            <div className="modal-footer">
                                                                {learnigStep === 2 && (
                                                                <button
                                                                    className="btn btn-secondary"
                                                                    onClick={() => setLearningStep(learnigStep - 1)}
                                                                >
                                                                    Previous
                                                                </button>
                                                                )}
                                                                {learnigStep === 2 && (
                                                                <button
                                                                    className="btn btn-secondary"
                                                                    onClick={() => setLearningStep(learnigStep + 1)}
                                                                >
                                                                    Next
                                                                    </button>
                                                                )}
                                                                 <button
                                                            className="btn btn-secondary"
                                                            onClick={() => {
                                                                navigate("/admin/course-managment/course/all");
                                                                localStorage.removeItem("course_id");
                                                                localStorage.removeItem("term_id");
                                                            }}
                                                            >
                                                            Submit
                                                            </button>
                                                            </div>
                                                    </form>
                                                </>
                                            )}
                                            {learnigStep === 3 && (
                                                <>
                                                    <div className="modal-header">
                                                        <h4 className="modal-title">Step 3 - homework</h4>
                                                    </div>
                                                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit4)} encType="multipart/form-data">
                                                    <div className="col-md-6">
                            <div className="form-group">
                              <label>Subject</label>
                              <ul className="nav nav-tabs">
                                {coursesubjects &&
                                  coursesubjects.map((elem) => {
                                    return (
                                      <li key={elem.value} className={`nav-item ${selectedSubjectId === elem.value ? "active" : ""}`}>
                                        <a
                                          className={`nav-link ${
                                            selectedSubjectId === elem.value
                                              ? "active"
                                              : ""
                                          }`}
                                          value={elem?.label}
                                          id="pills-evaluation-tab"
                                          data-bs-toggle="pill"
                                          data-bs-target="#pills-evaluation"
                                          type="button"
                                          role="tab"
                                          aria-controls="pills-evaluation"
                                          aria-selected="false"
                                          onClick={(e) =>{
                                            setSelectedSubject(elem?.value)
                                            setSelectedSubjectId(elem?.value)}
                                          }
                                        >
                                          {elem.label}
                                        </a>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>
                                                    {homeWorkData.length > 0 ?
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>Start Date </label>
                                                                <input type="date" className="form-control"
                                                                    name='start_date'
                                                                    pattern="dd-mm-yyyy"
                                                                    value={homeWorkData[0]?.start_date}
                                                                    onChange={(e) => setHomeWorkDate(e.target.value)}
                                                                    disabled />
                                                            </div>
                                                        </div> :
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>Start Date </label>
                                                                <input type="date" className="form-control"
                                                                    name='start_date'
                                                                    pattern="dd-mm-yyyy"
                                                                    //   value={homeWorkData[0]?.start_date}
                                                                    onChange={(e) => setHomeWorkDate(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>}
                                                    <EditHomework subjectIds={selectedSubjectId} setHomeWork={setHomeWork} homeWork={homeWork} setHomeWorkData={setHomeWorkData} homeWorkData={homeWorkData}  selectedSubjectId={selectedSubjectId} 
                                                     setSelectedSubjectId={setSelectedSubjectId}
                                                     singleTypeFormdata={singleTypeFormdata}
                                                     gradeId={grade_id} />
                                                    <div className="modal-footer">
                                                            {learnigStep === 3 && (
                                                                <button type="submit" className="btn button" disabled={isSubmitting}>
                                                                Save
                                                                </button>
                                                                )}
                                                                
                                                            </div>
                                                    <div className="modal-footer">
                                                            {learnigStep === 3 && (
                                                            <button
                                                                className="btn btn-secondary"
                                                                onClick={() => setLearningStep(learnigStep - 1)}
                                                            >
                                                                Previous
                                                            </button>
                                                            )}
                                                            {learnigStep === 3 && (
                                                            <button
                                                                className="btn btn-secondary"
                                                                onClick={() => setLearningStep(learnigStep + 1)}
                                                            >
                                                                Next
                                                                </button>
                                                            )}
                                                             <button
                                                            className="btn btn-secondary"
                                                            onClick={() => {
                                                                navigate("/admin/course-managment/course/all");
                                                                localStorage.removeItem("course_id");
                                                                localStorage.removeItem("term_id");
                                                            }}
                                                            >
                                                            Submit
                                                            </button>
                                                        </div>
                                                    </form>
                                                </>
                                            )}
                                            {learnigStep === 4 && (
                                                <>
                                                    <div className="modal-header">
                                                        <h4 className="modal-title">Step 4 - E-Tutoring</h4>
                                                    </div>
                                                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit5)} encType="multipart/form-data">
                                                    <div className="col-md-6">
                                                            <div className="form-group">
                                                            <label>Subject</label>
                                                            <ul className="nav nav-tabs">
                                                                {coursesubjects &&
                                                                coursesubjects.map((elem) => {
                                                                    return (
                                                                    <li key={elem.value} className={`nav-item ${selectedSubjectId === elem.value ? "active" : ""}`}>
                                                                        <a
                                                                        className={`nav-link ${
                                                                            selectedSubjectId === elem.value
                                                                            ? "active"
                                                                            : ""
                                                                        }`}
                                                                        value={elem?.label}
                                                                        id="pills-evaluation-tab"
                                                                        data-bs-toggle="pill"
                                                                        data-bs-target="#pills-evaluation"
                                                                        type="button"
                                                                        role="tab"
                                                                        aria-controls="pills-evaluation"
                                                                        aria-selected="false"
                                                                        onClick={(e) =>{
                                                                            setSelectedSubject(elem?.value)
                                                                            setSelectedSubjectId(elem?.value)}
                                                                        }
                                                                        >
                                                                        {elem.label}
                                                                        </a>
                                                                    </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                            </div>
                                                        </div>
                                                  <EditeTutoring setEtutoring={setEtutoring} eTutroing={eTutroing} setEtutoringData={setEtutoringData} eTuoringData={eTuoringData}  selectedSubjectId={selectedSubjectId} 
                                                     setSelectedSubjectId={setSelectedSubjectId}
                                                     singleTypeFormdata={singleTypeFormdata}
                                                     gradeId={grade_id}/>
                                                  <div className="modal-footer">
                                                            {learnigStep === 4 && (
                                                                <button type="submit" className="btn button" disabled={isSubmitting}>
                                                                Save
                                                                </button>
                                                                )}
                                                                
                                                            </div>
                                                  <div className="modal-footer">
                                                            {learnigStep === 4 && (
                                                            <button
                                                                className="btn btn-secondary"
                                                                onClick={() => setLearningStep(learnigStep - 1)}
                                                            >
                                                                Previous
                                                            </button>
                                                            )}
                                                            {learnigStep === 4 && (
                                                            <button
                                                                className="btn btn-secondary"
                                                                onClick={() => setLearningStep(learnigStep + 1)}
                                                            >
                                                                Next
                                                                </button>
                                                            )}
                                                             <button
                                                            className="btn btn-secondary"
                                                            onClick={() => {
                                                                navigate("/admin/course-managment/course/all");
                                                                localStorage.removeItem("course_id");
                                                                localStorage.removeItem("term_id");
                                                            }}
                                                            >
                                                            Submit
                                                            </button>
                                                        </div>
                                                    </form>
                                                </>
                                            )}
                                            {learnigStep === 5 && (
                                                <>
                                                    <div className="modal-header">
                                                        <h4 className="modal-title">Step 5 - Consulting</h4>
                                                    </div>
                                                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit6)} encType="multipart/form-data">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                        <label>Subject</label>
                                                        <ul className="nav nav-tabs">
                                                            {coursesubjects &&
                                                            coursesubjects.map((elem) => {
                                                                return (
                                                                <li key={elem.value} className={`nav-item ${selectedSubjectId === elem.value ? "active" : ""}`}>
                                                                    <a
                                                                    className={`nav-link ${
                                                                        selectedSubjectId === elem.value
                                                                        ? "active"
                                                                        : ""
                                                                    }`}
                                                                    value={elem?.label}
                                                                    id="pills-evaluation-tab"
                                                                    data-bs-toggle="pill"
                                                                    data-bs-target="#pills-evaluation"
                                                                    type="button"
                                                                    role="tab"
                                                                    aria-controls="pills-evaluation"
                                                                    aria-selected="false"
                                                                    onClick={(e) =>{
                                                                        setSelectedSubject(elem?.value)
                                                                        setSelectedSubjectId(elem?.value)}
                                                                    }
                                                                    >
                                                                    {elem.label}
                                                                    </a>
                                                                </li>
                                                                );
                                                            })}
                                                        </ul>
                                                        </div>
                                                    </div>
                                                 <EditConsulting  setConsulting={setConsulting} consulting={consulting} setConsultingData={setConsultingData} ConsultingData={ConsultingData}  selectedSubjectId={selectedSubjectId} 
                                                     setSelectedSubjectId={setSelectedSubjectId}
                                                     singleTypeFormdata={singleTypeFormdata}
                                                     gradeId={grade_id}/>
                                                 <div className="modal-footer">
                                                        {learnigStep === 5 && (
                                                            <button type="submit" className="btn button" disabled={isSubmitting}>
                                                            Save
                                                            </button>
                                                            )}
                                                            
                                                        </div>
                                                        <div className="modal-footer">
                                                            {learnigStep === 5 && (
                                                            <button
                                                                className="btn btn-secondary"
                                                                onClick={() => setLearningStep(learnigStep - 1)}
                                                            >
                                                                Previous
                                                            </button>
                                                            )}
                                                            {learnigStep === 5 && (
                                                            <button
                                                                className="btn btn-secondary"
                                                                onClick={() => setLearningStep(learnigStep + 1)}
                                                            >
                                                                Next
                                                                </button>
                                                            )}
                                                             <button
                                                            className="btn btn-secondary"
                                                            onClick={() => {
                                                                navigate("/admin/course-managment/course/all");
                                                                localStorage.removeItem("course_id");
                                                                localStorage.removeItem("term_id");
                                                            }}
                                                            >
                                                            Submit
                                                            </button>
                                                        </div>
                                                    </form>
                                                </>
                                            )}  
                                            {learnigStep === 6 && (
                                                <>
                                                    <div className="modal-header">
                                                        <h4 className="modal-title">Step 6 - Evaluation</h4>
                                                    </div>
                                                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit7)} encType="multipart/form-data">
                                                    <div className="col-md-6">
                                                                <div className="form-group">
                                                                <label>Subject</label>
                                                                <ul className="nav nav-tabs">
                                                                    {coursesubjects &&
                                                                    coursesubjects.map((elem) => {
                                                                        return (
                                                                        <li key={elem.value} className={`nav-item ${selectedSubjectId === elem.value ? "active" : ""}`}>
                                                                            <a
                                                                            className={`nav-link ${
                                                                                selectedSubjectId === elem.value
                                                                                ? "active"
                                                                                : ""
                                                                            }`}
                                                                            value={elem?.label}
                                                                            id="pills-evaluation-tab"
                                                                            data-bs-toggle="pill"
                                                                            data-bs-target="#pills-evaluation"
                                                                            type="button"
                                                                            role="tab"
                                                                            aria-controls="pills-evaluation"
                                                                            aria-selected="false"
                                                                            onClick={(e) =>{
                                                                                setSelectedSubject(elem?.value)
                                                                                setSelectedSubjectId(elem?.value)}
                                                                            
                                                                            }
                                                                            >
                                                                            {elem.label}
                                                                            </a>
                                                                        </li>
                                                                        );
                                                                    })}
                                                                </ul>
                                                                </div>
                                                            </div>
                                                 <EditEvaluation setEvaluation={setEvaluation} evaluation={evaluation} setEvaluationData={setEvaluationData} evaluationData={evaluationData}  selectedSubjectId={selectedSubjectId} 
                                                     setSelectedSubjectId={setSelectedSubjectId}
                                                     singleTypeFormdata={singleTypeFormdata}
                                                     gradeId={grade_id}/>
                                                 <div className="modal-footer">
                                                                {learnigStep === 6 && (
                                                                    <button type="submit" className="btn button" disabled={isSubmitting}>
                                                                    Save
                                                                    </button>
                                                                    )}
                                                                    
                                                                </div>
                                                 <div className="modal-footer">
                                                        {learnigStep === 6 && (
                                                        <button
                                                            className="btn btn-secondary"
                                                            onClick={() => setLearningStep(learnigStep - 1)}
                                                        >
                                                            Previous
                                                        </button>
                                                        )}
                                                    {learnigStep === 6 && (
                                                        <>
                                                            <button
                                                            className="btn btn-secondary"
                                                            onClick={() => {
                                                                navigate("/admin/course-managment/course/all");
                                                                localStorage.removeItem("course_id");
                                                                localStorage.removeItem("term_id");
                                                            }}
                                                            >
                                                            Submit
                                                            </button>
                                                        </>
                                                        )}
                                                    </div>
                                                </form>
                                                </>
                                            )}
                                        </div>
                                        {/* <div className="modal-footer">
                                        {currentStep > 1 && (
                                            <button className="btn btn-secondary" onClick={() => setCurrentStep(currentStep - 1)}>
                                                Previous
                                            </button>
                                        )}
                                        {currentStep < 4 && (
                                            <button className="btn button">
                                                Next
                                            </button>
                                        )}
                                        {currentStep === 4 && (
                                            <button type="submit" className="btn button">
                                                Save
                                            </button>
                                        )}
                                    </div> */}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {/* <div className="modal-footer">
                        {currentStep > 1 && (
                            <button className="btn btn-secondary" onClick={() => setCurrentStep(currentStep - 1)}>
                                Previous
                            </button>
                        )}
                        {currentStep < 2 && (
                            <button className="btn button">
                                Next
                            </button>
                        )}
                        {currentStep === 2 && (
                            <button type="submit" className="btn button">
                                Save
                            </button>
                        )}
                    </div>
                </form> */}
            </div>
        </div>

    )
}
export default EditCourse;

